import classNames from 'classnames';
import React from 'react'

interface Props {
  value: any;
  onSelect: (value: any) => void;
  isSelected: boolean;
}

export const WordpressArticlePreview: React.FC<Props> = (props) => {
  const { value, isSelected, onSelect } = props;

  return (
    <div
      className={classNames('flex h-[120px] py-2 px-2 space-x-4 border rounded-sm cursor-pointer', isSelected ? 'border-blue-500' : 'border-gray-500')}
      onClick={() => onSelect(value)}
    >
      <img src={value.embedded.wpFeaturedmedia[0].sourceUrl} alt="preview-article" className='h-full w-full' />
      <div>
        <span className='text-white font-bold text-sm' dangerouslySetInnerHTML={{ __html: value.title.rendered }}></span>
        <div className='text-white text-sm h-[40px] overflow-hidden overflow-ellipsis' dangerouslySetInnerHTML={{ __html: value.excerpt.rendered }}></div>
      </div>

    </div >
  )
};
