import React from 'react'

interface Props {
  onChange: (data: any) => void;
  value: string;
}

export const BrokerLevels = [
  { value: 'Débutant', label: 'Débutant' },
  { value: 'Intermédiaire', label: 'Intermédiaire' },
  { value: 'Expert', label: 'Expert' },
]

export const BrokerLevelSelector: React.FC<Props> = (props) => {
  const { onChange, value } = props;

  return (
    <select
      id="broker-level"
      name="broker-level"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
    >
      {BrokerLevels.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
};
