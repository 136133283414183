import React from 'react'
import { Tabs } from '../../components/Tabs';
import { Outlet } from 'react-router-dom';

const tabs = [{
  name: 'Trading',
  route: '/admin/alertes/trading',
}, {
  name: 'Crypto',
  route: '/admin/alertes/crypto',
}, {
  name: 'Historique',
  route: '/admin/alertes/historique',
}];

export const Alerts: React.FC = (props) => {
  return (
    <>
      <Tabs tabs={tabs} />
      <Outlet />
    </>
  )
};
