import React from 'react'
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getAlerts } from '../../../services/alerts';
import { Table } from '../../../components/Table';
import { Pagination } from '../../../components/Pagination';
import { ListAlertsFilters } from './ListAlertsFilters';
import { AlertStatuses } from '../../../components/AlertStatusSelector';


const headers = [
  { title: '#', key: 'id' },
  { title: 'Date', key: 'date', type: 'date' },
  { title: 'Mis à jours', key: 'updatedAtOverride', type: 'date' },
  { title: 'Produit concerné', key: 'productType' },
  { title: 'Produit', key: 'product' },
  { title: 'VIP', key: 'isVip', type: 'boolean' },
  { title: 'Statut', key: 'status', transform: (v: any) => AlertStatuses.find((s) => s.value === v)?.label },
]

export const ListAlerts: React.FC = () => {
  const { alertType } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<{ page: number, pageSize: number, type: string | undefined, isActive: boolean }>({
    page: 1,
    pageSize: 10,
    type: '',
    isActive: true,
  })

  React.useEffect(() => {
    if (!alertType) {
      return;
    }

    if (['trading', 'crypto'].includes(alertType)) {
      setFilters((v) => ({ ...v, type: alertType, isActive: true }))
    } else {
      setFilters((v) => ({ ...v, type: '', isActive: false }))
    }
  }, [alertType])


  const alertsQuery = useQuery({
    queryKey: ['users', filters],
    queryFn: () => getAlerts(filters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  if (alertsQuery.isLoading) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <div>
      <Table
        headers={headers}
        data={alertsQuery.data.items || []}
        loading={alertsQuery.isLoading}
        filters={
          <ListAlertsFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item) => navigate(`/admin/alertes/modifier/${item.id}`)}
      />
      <Pagination
        count={alertsQuery.data.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={alertsQuery.data.totalPages}
      />
    </div>
  )
};
