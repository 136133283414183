import React from 'react'
import { useInfiniteQuery } from 'react-query';
import { getChannelMessages } from '../../services/canal';
import { ChannelMessage } from './ChannelMessage/ChannelMessage';
import { CanalFilters } from './CanalFilters';
import { ChannelMessageModal } from './ChannelMessage/ChannelMessageModal/ChannelMessageModal';
import { ChannelCreateMessageModal } from './ChannelMessage/ChannelCreateMessageModal/ChannelCreateMessageModal';


export const Canal: React.FC = () => {
  const messagesContainer = React.useRef<HTMLDivElement>(null);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [selectedMessage, setSelectedMessage] = React.useState<any>(null);
  const [createModal, setCreateModal] = React.useState(false);
  const [filters, setFilters] = React.useState<any>({
    page: 1,
    limit: 10
  })
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const { data, fetchNextPage, isFetching, isFetchingNextPage, refetch } = useInfiniteQuery({
    queryKey: ['channelMessages', filters],
    queryFn: ({ pageParam }) => getChannelMessages({ ...filters, page: pageParam }),
    getNextPageParam: (lastPage, pages) => lastPage.page < lastPage.total_pages ? lastPage.page + 1 : undefined,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (data?.pages.length === 1) {
      messagesEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: "start",
      });
      setTimeout(() => {
        setHasScrolled(true);
      }, 1000);
    }
  }, [data?.pages.length]);

  const handleScroll = React.useCallback((e: any) => {
    if (!messagesContainer?.current?.scrollTop) {
      return;
    }
    if (messagesContainer?.current?.scrollTop < 300 && !isFetchingNextPage && !isFetching && hasScrolled) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, isFetching, hasScrolled, messagesContainer, fetchNextPage]);

  const handleOnRefresh = React.useCallback(() => {
    refetch();
  }, [refetch])

  const handleFiltersChange = React.useCallback((filters: any) => {
    setFilters((v: any) => ({ ...v, ...filters }));
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: "start",
      });
    }, 1000);

  }, []);

  return (
    <>
      <div className='py-4'>
        <CanalFilters onFiltersChange={handleFiltersChange} onCreateClick={() => setCreateModal(true)} />
      </div>
      <div className='p-6 border border-gray-800 w-full h-[650px] overflow-y-auto overscroll-none space-y-4' ref={messagesContainer} onScroll={handleScroll}>
        {isFetching && <div className='text-white'>Loading...</div>}
        {data?.pages.flatMap((page) => page.items).reverse().map((message) => (
          <ChannelMessage key={message.id} value={message} onDelete={handleOnRefresh} onPin={handleOnRefresh} onEdit={(message) => setSelectedMessage(message)} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      {createModal && <ChannelCreateMessageModal onClose={() => setCreateModal(false)} />}
      <ChannelMessageModal value={selectedMessage}
        onClose={(needsRefresh) => {
          setSelectedMessage(null);
          if (needsRefresh) {
            handleOnRefresh();
          }
        }}
      />
    </>
  )
};
