import React, { Fragment } from 'react'
import { useQuery } from 'react-query';
import { getMessages } from '../../../../services/messages';
import { getAlerts } from '../../../../services/alerts';
import { getCampusArticles, getNewsArticleCategories, getNewsArticles } from '../../../../services/articles';
import { getBrokers } from '../../../../services/brokers';
import { Listbox } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  actionType: string;
  typeOption?: string;
  onChange: (data: any) => void;
  entityValueResolver: (data: any) => any;
  entityLabelResolver: (data: any) => string;
  value: any;
}

export const EntitySelector: React.FC<Props> = (props) => {
  const { actionType, typeOption, onChange, value, entityLabelResolver, entityValueResolver } = props;
  const [search, setSearch] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  const entityQuery = useQuery({
    queryKey: ['entity', actionType, typeOption, search],
    queryFn: () => {
      if (actionType === 'messages') {
        return getMessages({ q: search, type: typeOption || 'campus', isActive: true })
      }
      if (actionType === 'alerts') {
        return getAlerts({ q: search })
      }
      if (actionType === 'campus') {
        return getCampusArticles({ q: search })
      }
      if (actionType === 'news') {
        if (typeOption === 'category') {
          return getNewsArticleCategories({ q: search })
        }
        return getNewsArticles({ q: search })
      }
      if (actionType === 'brokers') {
        return getBrokers({ q: search, isActive: true })
      }
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    setInputValue('');
    onChange(null);
  }, [actionType, typeOption]);


  return (
    <div className="w-full relative">
      <Listbox value={value} onChange={(v: any) => {
        onChange(v);
        setInputValue(entityLabelResolver(entityQuery.data?.items?.find((e: any) => entityValueResolver(e) === v)));
      }}
      >
        <Listbox.Button className="relative w-full">
          <div className="mt-2 flex items-center justify-center">
            <input
              id="selectedValue"
              value={inputValue}
              readOnly
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
            {value && (
              <div className="h-[36px] flex items-center">
                <XMarkIcon className="h-5 w-5 text-white ml-[-25px]" aria-hidden="true"
                  onClick={(e) => {
                    e.preventDefault();
                    setInputValue('');
                    onChange(null);
                  }}
                />
              </div>
            )}
          </div>
        </Listbox.Button>
        <Listbox.Options
          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 pb-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50"
        >
          <input
            id="search"
            value={search}
            placeholder='Rechercher'
            onChange={(e) => setSearch(e.target.value)}
            className="block mt-2 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
          {entityQuery.data?.items?.map((entity: any) => (
            /* Use the `active` state to conditionally style the active option. */
            /* Use the `selected` state to conditionally style the selected option. */
            <Listbox.Option key={entityValueResolver(entity)} value={entityValueResolver(entity)} as={Fragment}>
              <li className="py-2 px-2 text-white cursor-pointer hover:text-indigo-500 flex justify-between items-center max-w-full">
                {entityLabelResolver(entity)}
              </li>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
};
