import _ from "lodash";
import { axiosWithToken } from "./utils";


export const getAlerts = async (filters: any) => {
  const response = await axiosWithToken.get('/a/alerts', { params: filters });
  return response.data;
}

export const getAlert = async (id: string) => {
  const response = await axiosWithToken.get(`/a/alerts/${id}`);
  return response.data;
}

export const updateAlert = async (id: string, data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['logo_file'].includes(key)) {
      if (data[key] === null) {
        continue
      }
      formData.append(key, data[key]);
      delete data[key];
      continue;
    }

    if (_.isObject(data[key])) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }
  const response = await axiosWithToken.put(`/a/alerts/${id}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response.data;
}

export const createAlert = async (data: any) => {
  const response = await axiosWithToken.post('/a/alerts/', data);
  return response.data;
}

export const deleteAlert = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/alerts/${id}`);
  return response.data;
}


export const getProductTypes = async (q: string) => {
  const response = await axiosWithToken.get('/a/product-types', { params: { q } });
  return response.data;
}


export const getProducts = async (q: string) => {
  const response = await axiosWithToken.get('/a/products', { params: { q } });
  return response.data;
}


export const getExistingProductLogos = async (q: string) => {
  const response = await axiosWithToken.get('/a/existing-product-logos', { params: { q } });
  return response.data;
}