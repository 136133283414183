import 'react-quill/dist/quill.snow.css';
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getParameters, updateParameters } from '../../services/parameters';
import { TradingViewTickerCarousel } from './TradingViewTickerCarousel';
import { useSnackbar } from '../../hooks/useSnackbar';
import { CustomReactQuill } from '../../components/CustomReactQuill';
import { ImageUpload } from '../../components/ImageUpload';

interface Props { }

export const Parameters: React.FC<Props> = (props) => {
  const [noteValue, setNoteValue] = useState('');
  const [smsOptionsValue, setSmsOptionsValue] = useState({
    enabled: false,
    text: ''
  });
  const displaySnackbar = useSnackbar();
  const [tickers, setTickers] = useState<{ symbol: string, name: string }[]>([]);
  const queryClient = useQueryClient();
  const [telegramBanner, setTelegramBanner] = useState({ enabled: false, text: '', url: '', image: null, image_file: null });

  const parametersQuery = useQuery({
    queryKey: 'parameters',
    queryFn: () => getParameters(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setNoteValue(data.note.value);
      setSmsOptionsValue(data.smsOptions.value)
      setTickers(data.tickersCarousel?.value || []);
      setTelegramBanner(data.telegramBanner?.value || {});
    }
  });

  const updateParametersMutation = useMutation<any>({
    mutationKey: ['updateParameters'],
    mutationFn: (data) => updateParameters(data),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Parametres mis à jours',
      })
      queryClient.invalidateQueries('parameters');
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Erreur lors de la mise à jours des parametres',
        severity: 'error'
      })
    }
  });

  if (parametersQuery.isLoading) {
    return <div>Loading...</div>;
  }



  const onSaveClick = () => {
    const data = {
      note: {
        value: noteValue
      },
      smsOptions: {
        value: smsOptionsValue
      },
      tickersCarousel: {
        value: tickers
      },
      telegramBanner: {
        value: telegramBanner
      }
    };
    updateParametersMutation.mutate(data as any);
  }

  return (
    <>
      <div className='flex justify-end sticky top-10 pt-2'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={onSaveClick}
            className="inline-flex  z-50 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
      <div className="space-y-6">
        <div className="border-b border-white/10 pb-6">
          <h2 className="text-base font-semibold leading-7 text-white">Note</h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="col-span-full">
              <CustomReactQuill value={noteValue} onChange={setNoteValue} />
            </div>
          </div>
        </div>
        <div className="border-b border-white/10 pb-6">
          <h2 className="text-base font-semibold leading-7 text-white">SMS lors de l'inscriptions</h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <div className="mt-2">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="candidates"
                      name="candidates"
                      type="checkbox"
                      checked={smsOptionsValue.enabled}
                      onChange={(e) => setSmsOptionsValue({ ...smsOptionsValue, enabled: e.target.checked })}
                      className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="candidates" className="font-medium text-white">
                      Activer les SMS à l'inscriptions
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
                Contenu du SMS
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  value={smsOptionsValue.text}
                  onChange={(e) => setSmsOptionsValue({ ...smsOptionsValue, text: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-full">
          <div className="border-b border-white/10 pb-6">
            <h2 className="text-base font-semibold leading-7 text-white">Carousel Trading View</h2>
            <div className="mt-4">
              <h4 className="text-white">
                Ouvrer la{' '}
                <a
                  href="https://www.tradingview.com/widget-docs/widgets/tickers/ticker/"
                  target='_blank'
                  rel="noreferrer"
                  className='text-indigo-500 hover:text-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 cursor-pointer'
                >
                  page ticker de TradingView
                </a>
                {' '}pour trouver les valeurs
              </h4>
              <div className="mt-2 col-span-full">
                <TradingViewTickerCarousel tickers={tickers} onChange={setTickers} />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-white/10 pb-6">
          <h2 className="text-base font-semibold leading-7 text-white">Bannière Telegram</h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <div className="mt-2">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="telgram_banner_enabled"
                      name="telgram_banner_enabled"
                      type="checkbox"
                      checked={telegramBanner.enabled}
                      onChange={(e) => setTelegramBanner({ ...telegramBanner, enabled: e.target.checked })}
                      className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="candidates" className="font-medium text-white">
                      Visible
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL
              </label>
              <div className="mt-2">
                <input
                  name="telgram_url"
                  id="telgram_url"
                  value={telegramBanner?.url || ''}
                  onChange={(e) => setTelegramBanner({ ...telegramBanner, url: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-3">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
                Contenu de la bannière
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  value={telegramBanner.text}
                  onChange={(e) => setTelegramBanner({ ...telegramBanner, text: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
              </div>
            </div>
            <div className="col-span-3">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
                Image
              </label>
              <div className="mt-2">
                <ImageUpload value={telegramBanner.image} onChange={(v) => setTelegramBanner({ ...telegramBanner, image_file: v })} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};
