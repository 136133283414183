import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createNewsArticleCategory, deleteNewsArticleCategory, getNewsArticleCategory, updateNewsArticleCategory } from '../../../../services/articles';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { CustomDialog } from '../../../../components/CustomDialog';


export const NewsArticleCategoryDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [category, setCategory] = React.useState<any>(null);
  const navigate = useNavigate();
  const [deleteCategoryModal, setDeleteCategoryModal] = React.useState(false);
  const displaySnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const newsArticleCategoryDetailsQuery = useQuery({
    queryKey: ['newsArticleCategory', id],
    queryFn: () => getNewsArticleCategory(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id && id !== 'new',
    onSuccess: (data) => {
      setCategory(data);
    }
  });

  React.useEffect(() => {
    if (id === 'new') {
      setCategory({
        id: 'new',
        image: null,
        title: '',
        video: '',
        content: ''
      })
    }
  }, [id]);

  const updateArticleMutation = useMutation<any>({
    mutationKey: ['updateNewsArticleCategory'],
    mutationFn: () => id !== 'new' ? updateNewsArticleCategory(category.id, category) : createNewsArticleCategory(category),
    onSuccess: (data) => {
      newsArticleCategoryDetailsQuery.refetch();
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: id === 'new' ? 'Catégorie crée' : 'Catégorie mis à jours',
      })
      if (id === 'new') {
        navigate(`/admin/articles/categories/${data.id}`)
      }
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteArticleMutation = useMutation<any>({
    mutationKey: ['deleteNewsArticleCategory'],
    mutationFn: () => deleteNewsArticleCategory(category.id),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Catégorie supprimée',
      });
      queryClient.invalidateQueries('newsArticleCategories');
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  if (!category) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateArticleMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {id !== 'new' ? 'Save' : 'Créer catégorie'}
          </button>
          {id !== 'new' && (<button
            type="button"
            onClick={() => setDeleteCategoryModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>)}
        </div>
      </div>
      <CustomDialog
        title="Etes vous sur de supprimer cette alerte ?"
        open={deleteCategoryModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteArticleMutation.mutate();
            navigate('/admin/articles/categories');
          }
          setDeleteCategoryModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Géneral</h2>
          <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre de la catégorie
              </label>
              <div className="mt-2">
                <input
                  name="button_title"
                  id="button_title"
                  value={category?.title || ''}
                  onChange={(e) => setCategory({ ...category, title: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label htmlFor="color" className="block text-sm font-medium leading-6 text-white">
                Couleur
              </label>
              <div className="mt-2">
                <input type="color" name="color" id="color" value={category?.color || ''} onChange={(e) => setCategory({ ...category, color: e.target.value })} className="block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 h-9" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
