import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react'
import { NotificationRedirections, RedirectionInAppDefinition } from './NotificationRedirections';

interface Props {
  onChange: (notifications: any) => void;
  displayOnlyVip?: boolean;
  notification: any;
}

export const NotificationInput: React.FC<Props> = (props) => {
  const { onChange, notification, displayOnlyVip } = props;

  return (
    <div className="mt-4 grid grid-cols-12 gap-x-6 gap-y-4">
      <div className="col-span-9">
        <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
          Titre
        </label>
        <div className="mt-2">
          <input
            type="title"
            name="title"
            id="title"
            value={notification?.title}
            onChange={(e) => onChange({ ...notification, title: e.target.value })}
            autoComplete="email"
            className="block w-full pl-3 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {displayOnlyVip && (
        <div className='col-span-2'>
          <div className="relative flex gap-x-3 items-end pb-2 h-full">
            <div className="flex h-6 items-center">
              <input
                id="is_vip"
                name="is_vip"
                type="checkbox"
                checked={notification?.is_vip}
                onChange={(e) => onChange({ ...notification, is_vip: e.target.checked })}
                className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="is_vip" className="font-medium text-white">
                Seulement VIP
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-full">
        <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
          Note
        </label>
        <div className="mt-2">
          <textarea
            id="note"
            name="note"
            rows={3}
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            value={notification?.content || ''}
            onChange={(e) => onChange({ ...notification, content: e.target.value })}
          />
        </div>
      </div>
      <div className="col-span-full">
        <div className='flex justify-between'>
          <h2 className="text-base font-semibold leading-7 text-white">Redirection In App</h2>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={!_.isNil(notification?.redirection)}
              onChange={(enabled) => {
                if (!enabled) {
                  onChange({ ...notification, redirection: null })
                } else {
                  onChange({
                    ...notification, redirection: {
                      action: RedirectionInAppDefinition[0].action,
                      type: RedirectionInAppDefinition[0]?.typeOptions?.[0]?.value,
                    }
                  })
                }
              }}
              className={classNames(
                !_.isNil(notification?.redirection) ? 'bg-indigo-600' : 'bg-gray-700',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  !_.isNil(notification?.redirection) ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </Switch.Group>
        </div>
        {!_.isNil(notification?.redirection) && (
          <NotificationRedirections
            onChange={(redirection) => onChange({ ...notification, redirection })}
            redirectionPayload={notification.redirection}
          />
        )}
      </div>
    </div >
  )
};
