import React from 'react'
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getBrokers } from '../../../services/brokers';
import { Table } from '../../../components/Table';
import { Pagination } from '../../../components/Pagination';
import { AllBrokersFilters } from './AllBrokersFilters';
import { RankInputComponent } from './RankInputComponent';

const headers = [
  { title: '#', key: 'id' },
  { title: 'Nom', key: 'name' },
  { title: 'Logo', key: 'logo', type: 'image', width: 18 },
  { title: 'Level', key: 'level' },
  { title: 'Stars', key: 'stars' },
  { title: 'Classement', key: 'rank', customComponent: RankInputComponent },
]


export const AllBrokers: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState({
    page: 1,
    pageSize: 10,
  })
  const brokersQuery = useQuery({
    queryKey: ['brokers', filters],
    queryFn: () => getBrokers(filters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  if (brokersQuery.isLoading) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <div>
      <Table
        headers={headers}
        data={brokersQuery.data.items || []}
        loading={brokersQuery.isLoading}
        filters={
          <AllBrokersFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item) => navigate(`/admin/brokers/${item.id}`)}
      />
      <Pagination
        count={brokersQuery.data.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={brokersQuery.data.totalPages}
      />
    </div>
  )
};
