import React, { useContext, createContext, useState } from 'react';
import { Snackbar, Grow } from '@mui/material';
import { Alert } from '../components/Alert';

const SnackbarServiceContext = createContext<any>(Promise.reject);

export const useSnackbar = () => useContext(SnackbarServiceContext);

const DEFAULT_OPTIONS = {
  showIcon: false,
  severity: 'success',
  text: null,
  html: null,
  autoHideDuration: 5000,
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  TransitionComponent: Grow,
};

export const SnackbarServiceProvider = ({ children }: { children: any }) => {
  const awaitingPromiseRef = React.useRef<any>();

  const [snackbarState, setSnackbarState] = useState<any>(DEFAULT_OPTIONS);

  const openSnackbar = (options: any) => {
    setSnackbarState({ ...DEFAULT_OPTIONS, ...options, open: true });
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    }).catch(() => {})
  };

  const handleClose = () => {
    if (snackbarState && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setSnackbarState(null);
  };

  const { showIcon, severity, html, text, useAlert, ...snackbarProps } = snackbarState || {
    open: false,
  };
  return (
    <>
      <SnackbarServiceContext.Provider children={children} value={openSnackbar} />
      <Snackbar onClose={handleClose} {...snackbarProps}>
        <div>
          <Alert onClose={handleClose} type={severity} message={text} />
        </div>
      </Snackbar>
    </>
  );
};
