import { Listbox } from '@headlessui/react';
import React from 'react'
import { useQuery } from 'react-query';
import { getExistingProductLogos } from '../../../services/alerts';

interface Props {
  product: any;
  onChange: (value: any) => void;
}

export const ExistingLogoSelector: React.FC<Props> = (props) => {
  const { product, onChange } = props;
  const [search, setSearch] = React.useState('')


  const existingLogosQuery = useQuery({
    queryKey: ['existingLogos', search],
    queryFn: () => getExistingProductLogos(search),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })


  return (
    <div className="w-full relative">
      <Listbox onChange={onChange} >
        <Listbox.Button className="relative w-full">
          <div className="mt-2 flex items-center justify-center">
            <input
              id="selectedValue"
              placeholder='Utiliser un logo existant'
              readOnly
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </Listbox.Button>
        <Listbox.Options
          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 pb-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50"
        >
          <input
            id="search"
            value={search}
            placeholder='Rechercher'
            onChange={(e) => setSearch(e.target.value)}
            className="block mt-2 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
          {existingLogosQuery.data?.map((v: any) => (
            /* Use the `active` state to conditionally style the active option. */
            /* Use the `selected` state to conditionally style the selected option. */
            <Listbox.Option key={`${v.logo}${v.product}`} value={v.logo}>
              <li className="py-2 px-2 text-white cursor-pointer hover:text-indigo-500 flex items-center space-x-4">
                <img src={v.logo} alt={v.product} className="h-10 w-10" />
                <span>{v.product}</span>
              </li>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
};
