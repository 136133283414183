import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { setCookiesTokens, signIn } from '../../services/auth';
import { useMutation } from 'react-query';
import { LoadingButton } from '../../components/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';


export const SignIn: React.FC = () => {
  const [error, setError] = useState(false)
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const {
    register,
    handleSubmit,
  } = useForm()

  const loginMutation = useMutation(async ({email, password}: {email: string, password: string}) => {
    setError(false);
    const data = await signIn(email, password);
    if (data.error) {
      setError(true);
      return data;
    }
    setUser(data.user);
    setCookiesTokens(data.accessToken, data.refreshToken);
    navigate('/admin/dashboard')
    return data;
  });

  if (user) {
    navigate('/admin/dashboard');
  }




  const handleOnSubmit = (data: any) => loginMutation.mutate(data);

  return (
    <div className="flex h-screen min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-gray-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://investx.fr/wp-content/uploads/2022/09/cropped-logo-clair-1.png.webp"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(handleOnSubmit)}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                {...register('email', { required: true })}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                {...register('password', { required: true })}
              />
            </div>
          </div>

          <div className='flex flex-col gap-2'>
            {error && (
              <p className="text-red-500 text-sm font-semibold">
                Invalid credentials
              </p>
            )}
            <LoadingButton
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              loading={loginMutation.isLoading}
            >
              Sign in
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  )
};
