import React from 'react'


interface Props {
  onFiltersChange: (filters: any) => void;
  onCreateClick: () => void;
}

export const CanalFilters: React.FC<Props> = (props) => {
  const { onFiltersChange, onCreateClick } = props;
  const [visibility, setVisibility] = React.useState('');
  const [filters, setFilters] = React.useState<any>({
    q: '',
    isVip: '',
    multi: '',
    pinned: ''
  });

  React.useEffect(() => {
    if (visibility === 'multi') {
      setFilters({ ...filters, multi: true, isVip: '' })
    } else if (visibility === 'vip') {
      setFilters({ ...filters, multi: null, isVip: true })
    } else if (visibility === 'nonVip') {
      setFilters({ ...filters, multi: null, isVip: false })
    } else {
      setFilters({ ...filters, multi: '', isVip: '' })
    }
  }, [visibility])

  React.useEffect(() => {
    onFiltersChange(filters)
  }, [onFiltersChange, filters])

  return (
    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-2">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Pinned
        </label>
        <select
          id="pinned"
          name="pinned"
          value={filters.pinned}
          onChange={(e) => setFilters({ ...filters, pinned: e.target.value })}
          className="block w-full mt-2 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        >
          <option value={''}>-----</option>
          <option value={'true'}>Oui</option>
          <option value={'false'}>Non</option>
        </select>
      </div>
      <div className="col-span-2">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Type
        </label>
        <select
          id="country"
          name="country"
          autoComplete="country-name"
          value={visibility}
          onChange={(e) => {
            setVisibility(e.target.value);
          }}
          className="block w-full mt-2 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        >
          <option value={''}>-----</option>
          <option value={'vip'}>Vip</option>
          <option value={'nonVip'}>Non Vip</option>
          <option value={'multi'}>Tout le monde</option>
        </select>
      </div>
      <div className="col-span-2 flex justify-end items-end">
        <button
          onClick={() => { onCreateClick(); }}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-emerald-500"
        >
          Créer une message
        </button>
      </div>
    </div>
  )
};
