import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

interface Props {
  value: any;
  onDelete?: (data: any) => void;
  onChange: (data: any) => void;
}

export const UserSubscription: React.FC<Props> = (props) => {
  const { value, onDelete, onChange } = props;
  const [subscription, setSubscription] = React.useState<any>(value ? {
    start_date: dayjs(value.start_date), end_date: value.end_date ? dayjs(value.end_date) : null
  } : { start_date: null, end_date: null });

  React.useEffect(() => {
    onChange(subscription);
  }, [subscription]);

  return (
    <div className="mt-4 grid grid-cols-5 gap-x-6 gap-y-8">
      <div className="col-span-2">
        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
          Début
        </label>
        <div className="mt-2">
          <DatePicker
            disableFuture
            value={subscription.start_date}
            onChange={(date) => setSubscription({ ...subscription, start_date: date })}
            slotProps={{
              field: {
                className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
              },
            }}
            sx={{
              root: {
                width: '100%',
              },
              svg: {
                color: 'white',
              },
              input: {
                color: 'white',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                lineHeight: '1.5rem',
                paddingLeft: '0.5rem',
                fontSize: '0.875rem',
                background: 'transparent',
                width: '100%',
              }
            }}
            className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
          />
        </div>
      </div>

      <div className="col-span-2">
        <label htmlFor="number" className="block text-sm font-medium leading-6 text-white">
          Fin
        </label>
        <div className="mt-2">
          <DatePicker
            onError={(error) => console.log(error, subscription)}
            value={subscription.end_date}
            onChange={(date) => setSubscription({ ...subscription, end_date: date })}
            slotProps={{
              field: {
                className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
              },
            }}
            sx={{
              root: {
                width: '100%',
              },
              svg: {
                color: 'white',
              },
              input: {
                color: 'white',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                lineHeight: '1.5rem',
                paddingLeft: '0.5rem',
                fontSize: '0.875rem',
                background: 'transparent',
                width: '100%',
              }
            }}
            className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
          />
        </div>
      </div>
      {onDelete && (
        <div className="col-span-1 items-end flex pb-2">
          <TrashIcon className="h-6 w-6 text-red-500 cursor-pointer" onClick={onDelete} />
        </div>
      )}
    </div>
  )
};
