import { Dialog, Transition } from '@headlessui/react';
import React from 'react'
import { ChannelMessageForm } from '../ChannelMessageModal/ChannelMessageForm';
import { ChannelPollForm } from '../ChannelMessageModal/ChannelPollForm';
import { createChannelMessage } from '../../../../services/canal';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  onClose: () => void;
}

export const ChannelCreateMessageModal: React.FC<Props> = (props) => {
  const { onClose } = props;
  const [messageType, setMessageType] = React.useState('regular');
  const queryClient = useQueryClient();
  const displaySnackbar = useSnackbar();
  const [message, setMessage] = React.useState<any>({
    id: 'new',
    poll: null,
    vocal: null,
    images: [],
    images_to_update: [],
    video: '',
    date: null,
    content: '',
    is_vip: false,
    pinned: false,
    multi: true,
    button_link: null,
    button_text: null,
    button_open_in_app: false
  });

  const changeMessageType = (type: string) => {
    if (type === 'regular') {
      setMessage({
        id: 'new',
        poll: null,
        vocal: null,
        images: [],
        images_to_update: [],
        video: '',
        date: null,
        content: '',
        is_vip: false,
        pinned: false,
        multi: true,
        button_link: null,
        button_text: null,
        button_open_in_app: false
      });
    }
    if (type === 'poll') {
      setMessage({
        id: 'new',
        poll: {
          title: '',
          options: [{ name: '' }],
        },
        vocal: null,
        images: [],
        images_to_update: [],
        video: '',
        date: null,
        content: '',
        is_active: true,
        is_vip: false,
        pinned: false,
        multi: true,
        button_link: null,
        button_text: null,
        button_open_in_app: false
      });
    }
    setMessageType(type);
  }

  const createMessageMutation = useMutation({
    mutationFn: () => {
      const { images, ...newMessage } = message;
      newMessage.images_to_update = images;
      return createChannelMessage(newMessage)
    },
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Message créé',
      });
      queryClient.invalidateQueries('channelMessages');
      onClose();
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      });
    }
  });

  return (
    <Transition appear show={true} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  Publication d'un nouveau message
                </Dialog.Title>
                <div className="bg-gray-900 py-2">
                  <div className="mx-auto">
                    <div className="">
                      <nav className="flex border-b border-white/10 py-4">
                        <ul className="flex min-w-full flex-none gap-x-6 px-2 text-sm font-semibold leading-6 text-gray-400 cursor-pointer"
                        >
                          <li onClick={() => changeMessageType('regular')}>
                            <span className={messageType === 'regular' ? 'text-indigo-400' : ''}>
                              Message
                            </span>
                          </li>
                          <li onClick={() => changeMessageType('poll')}>
                            <span className={messageType === 'poll' ? 'text-indigo-400' : ''}>
                              Sondage
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                {messageType === 'regular' && (
                  <ChannelMessageForm
                    message={message}
                    onChange={(m) => {
                      setMessage(m)
                    }}
                  />
                )}
                {messageType === 'poll' && (
                  <ChannelPollForm
                    message={message}
                    onChange={(m) => {
                      setMessage(m)
                    }}
                  />
                )}
                <div className="mt-6 flex space-x-4 justify-end">
                  <button type="button" className="text-sm font-semibold leading-6 text-white" onClick={() => onClose()}>
                    Annuler
                  </button>
                  <button
                    onClick={() => createMessageMutation.mutate()}
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Créer
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};
