import React from 'react'

interface Props {
  onChange: (data: any) => void;
  value: string;
}

const AlertTypes = [
  { value: 'BUY', label: 'J\'achete' },
  { value: 'SELL', label: 'Je vends' },
]


export const AlertActionSelector: React.FC<Props> = (props) => {
  const { onChange, value } = props;

  return (
    <select
      id="deposit-status"
      name="deposit-status"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
    >
      <option value=''>-----</option>
      {AlertTypes.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
};
