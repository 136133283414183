import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { DirectionSelector } from './DirectionSelector';
import { DisplaySelector } from './DisplaySelector';
import { SectionArticleSelector } from './SectionArticleSelector';

interface Props {
  section: any;
  onChange: (section: any) => void;
  onDelete: () => void;
}

export const Section: React.FC<Props> = (props) => {
  const { section, onChange, onDelete } = props;
  return (
    <div className='grid grid-cols-12 relative border rounded-sm border-white/10 py-4 px-2 space-x-4 gap-y-2'>
      <div className='absolute top-2 right-2'>
        <XMarkIcon className='h-4 w-4 text-white cursor-pointer' onClick={onDelete} />
      </div>
      <div className="col-span-3">
        <label className="block text-sm font-medium leading-6 text-white">
          Titre
        </label>
        <div className="mt-2">
          <input
            type="title"
            name="title"
            value={section.title}
            onChange={(e) => onChange({ ...section, title: e.target.value })}
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-3">
        <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
          Orientation
        </label>
        <div className="mt-2">
          <DirectionSelector value={section.orientation} onChange={(v) => onChange({ ...section, orientation: v })} />
        </div>
      </div>
      <div className="col-span-3">
        <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
          Affichage
        </label>
        <div className="mt-2">
          <DisplaySelector value={section.orientation} onChange={(v) => onChange({ ...section, display: v })} />
        </div>
      </div>
      <div className="col-span-3">
        <div className="flex items-center space-x-2">
          <label htmlFor="color" className="block text-sm font-medium leading-6 text-white">
            Couleur
          </label>
          {/* {section.color !== 'no-color' && (
            <XMarkIcon className='h-4 w-4 text-white cursor-pointer' onClick={() => onChange({ ...section, color: 'no-color' })} />
          )} */}
        </div>
        <div className="mt-2">
          {/* {section.color !== 'no-color' && ( */}
          <input
            type="color"
            className="block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 h-9" name="color" id="color"
            value={section?.color || ''}
            onChange={(v) => onChange({ ...section, color: v.target.value })}
          />
          {/* )} */}
          {/* {section.color === 'no-color' && (
            <button
              className="inline-flex items-center px-2.5 py-2 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
              onClick={() => onChange({ ...section, color: '#000000' })}
            >
              Ajouter une couleur
            </button>
          )} */}
        </div>
      </div>
      <div className="col-span-full">
        <label className="block text-sm font-medium leading-6 text-white">
          Selection des articles
        </label>
        <SectionArticleSelector
          section={section}
          onChange={(v) => onChange({ ...section, ...v })}
        />
      </div>
    </div >
  )
};
