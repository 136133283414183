import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react'

interface Props {
  onNext: () => void;
  onPrevious: () => void;
  page: number;
  totalPages: number;
  count: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

const getPageToDislay = (currentPage: number, totalPages: number) => {
  let pages = [];

  // Include the first three pages
  for (let i = 1; i <= Math.min(3, totalPages); i++) {
    pages.push(i);
  }

  // Include the previous page, if it's not already in the list
  if (currentPage - 1 > 3 && currentPage - 1 <= totalPages) {
    pages.push(currentPage - 1);
  }

  // Include the current page, if it's not already in the list
  if (!pages.includes(currentPage)) {
    pages.push(currentPage);
  }

  // Include the next page, if it's not already in the list and within range
  if (currentPage + 1 < totalPages - 2 && currentPage + 1 >= 1) {
    if (!pages.includes(currentPage + 1) && currentPage + 1 <= totalPages) {
      pages.push(currentPage + 1);
    }
  }

  // Include the last three pages
  for (let i = totalPages - 2; i <= totalPages && currentPage < totalPages; i++) {
    if (i > 0 && !pages.includes(i)) {
      pages.push(i);
    }
  }

  return pages;
}


export const Pagination: React.FC<Props> = (props) => {
  const { onNext, onPrevious, page, totalPages, count, onPageChange } = props;
  const pagesToDisplay = getPageToDislay(page, totalPages);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-gray-900 px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={onPrevious}
          disabled={page === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <div
          onClick={onNext}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </div>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-white">
            <span className="font-medium">{count}</span> résultats
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={onPrevious}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            {pagesToDisplay.map((p, idx) => (
              <React.Fragment key={p}>
                <button
                  aria-current="page"
                  onClick={() => onPageChange(p)}
                  className={classNames(
                    "relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                    p === page ? 'bg-indigo-600' : 'text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:outline-offset-0'
                  )}
                >
                  {p}
                </button>
                {idx !== pagesToDisplay.length - 1 && pagesToDisplay[idx + 1] !== p + 1 && (
                  <span className="relative inline-flex items-center px-4 py-2 text-sm ring-1 ring-inset ring-gray-300 font-semibold text-white">
                    ...
                  </span>
                )}
              </React.Fragment>
            ))}
            <button
              disabled={page === totalPages}
              onClick={onNext}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-white ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
};
