import React from 'react'
import { CustomDialog } from '../../../components/CustomDialog';
import _ from 'lodash';
import { useMutation } from 'react-query';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { changeUserPassword } from '../../../services/users';

interface Props {
  onClose: () => void;
  user: any;
}

export const ChangePasswordModal: React.FC<Props> = (props) => {
  const { onClose, user } = props;
  const displaySnackbar = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const changePasswordMutation = useMutation<any>({
    mutationKey: ['changePassword'],
    mutationFn: () => changeUserPassword(user.id, { password }),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Mot de passe changé',
      })
      onClose();
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  return (
    <CustomDialog
      title={`Changer le mot de passe de ${user?.email}`}
      open={true}
      onClose={async (reason) => {
        if (reason === 'confirm') {
          setIsLoading(true);
          await changePasswordMutation.mutate();
        }
        onClose();
      }}
      confirmButtonIsLoading={isLoading}
      confirmButtonDisabled={password !== confirmPassword || _.isEmpty(password)}
      content={
        <div>
          <div className="mt-4">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
              Nouveau mot de passe
            </label>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                autoComplete="current-password"
                required
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
              Confirmer le mot de passe
            </label>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                autoComplete="current-password"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      }
    />
  )
};
