import React from 'react'
import { CustomDialog } from '../../../components/CustomDialog';
import { UserSubscription } from './UserSubscriptions/UserSubscription';
import _ from 'lodash';
import dayjs from 'dayjs';

interface Props {
  onClose: (value: any) => void;
  user: any;
}

export const MakeVipModal: React.FC<Props> = (props) => {
  const { onClose, user } = props;
  const [subscription, setSubscription] = React.useState<any>({
    start_date: dayjs(),
    end_date: null,
  });

  return (
    <CustomDialog
      title={`Rendre ${user?.email} VIP`}
      open={true}
      onClose={async (reason) => {
        if (reason === 'confirm') {
          onClose(subscription)
        }
      }}
      confirmButtonDisabled={_.isNil(subscription.start_date)}
      content={
        <div>
          <UserSubscription
            value={subscription}
            onChange={(data) => {
              setSubscription(data);
            }}
          />
        </div>
      }
    />)
};
