import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react'

interface Props {
  value: any[];
  onChange: (data: any[]) => void
}

export const ChannelOptionInputs: React.FC<Props> = (props) => {
  const { onChange, value } = props;
  const options = value || [];

  return (
    <div className='grid grid-cols-12 gap-x-2 gap-y-2'>
      {options.map((option, index) => (
        <div className='col-span-3 grid grid-cols-5'>
          <input
            name="option"
            value={option.name}
            onChange={(e) => {
              const newValue = [...options];
              newValue[index].name = e.target.value;
              onChange(newValue);
            }}
            className="block col-span-4 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
          />
          <div className='col-span-1 flex items-center justify-center'>
            {options.length > 1 && (
              <TrashIcon
                className="h-6 w-6 text-red-500 cursor-pointer"
                onClick={() => {
                  const newValue = [...options];
                  newValue.splice(index, 1);
                  onChange(newValue);
                }}
              />
            )}
          </div>
        </div>
      ))}
      <div className='col-span-3 flex items-center justify-start ml-2'>
        <button
          type="button"
          onClick={() => onChange([...options, { name: '' }])}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter
        </button>
      </div>
    </div>
  )
};
