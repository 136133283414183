import { Listbox } from '@headlessui/react';
import React, { Fragment } from 'react'
import { useQuery } from 'react-query';
import { getNewsArticles } from '../services/articles';
import classNames from 'classnames';

interface Props {
  selectedIds: any[]
  onChange: (article: any) => void
}

export const NewsArticlesSelector: React.FC<Props> = (props) => {
  const { selectedIds, onChange } = props;
  const [search, setSearch] = React.useState<any>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const articlesQuery = useQuery({
    queryKey: ['newsArticles', search],
    queryFn: () => getNewsArticles({ q: search }),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })


  return (
    <div className="w-full relative">
      <Listbox onChange={onChange} >
        <Listbox.Button className="relative w-full">
          <div className="mt-2 flex items-center justify-center">
            <input
              ref={inputRef}
              onClick={() => setTimeout(() => inputRef.current?.focus(), 200)}
              id="search"
              value={search || ''}
              placeholder='Rechercher'
              onChange={(e) => setSearch(e.target.value)}
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </Listbox.Button>
        <Listbox.Options
          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 pb-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50"
        >
          {articlesQuery.data?.items?.map((article: any) => (
            /* Use the `active` state to conditionally style the active option. */
            /* Use the `selected` state to conditionally style the selected option. */
            <Listbox.Option key={article.id} value={article} as={Fragment} disabled={selectedIds.includes(article.id)}>
              <li
                className={classNames(
                  "py-2 px-2 text-white cursor-pointer hover:text-indigo-500 flex justify-between items-center max-w-full disabled:text-gray-400",
                  selectedIds.includes(article.id) ? '!text-gray-400' : ''
                )}>
                [{article.id}] - {article.title} - {article.category.title}
              </li>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
};
