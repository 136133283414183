import React from 'react'
import { Tabs } from '../../components/Tabs';
import { Outlet } from 'react-router-dom';

const tabs = [{
  name: 'Tous les utilisateurs',
  route: '/admin/utilisateurs/tous-les-utilisateurs',
}, {
  name: 'En attente de validation',
  route: '/admin/utilisateurs/en-attente-de-validation',
}, {
  name: 'Account Managers',
  route: '/admin/utilisateurs/account-managers',
}, {
  name: 'Notifications',
  route: '/admin/utilisateurs/notifications',
}];

export const Users: React.FC = () => {
  return (
    <>
      <Tabs tabs={tabs} />
      <Outlet />
    </>
  )
};
