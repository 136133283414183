import React from 'react'
import { NotificationInput } from './NotificationInput/NotificationInput';
import classNames from 'classnames';
import { AllUsersFilters } from '../AllUsers/AllUsersFilters';
import { useMutation, useQuery } from 'react-query';
import { getUsers, sendNotifications } from '../../../services/users';
import { Table } from '../../../components/Table';
import { Pagination } from '../../../components/Pagination';
import { LoadingButton } from '../../../components/LoadingButton';
import { useSnackbar } from '../../../hooks/useSnackbar';

interface Props { }

export const Notifications: React.FC<Props> = (props) => {
  const [notification, setNotification] = React.useState({
    title: '',
    content: '',
  });
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([])
  const [count, setCount] = React.useState(0)
  const [notificationType, setNotificationType] = React.useState('criteria')
  const [filters, setFilters] = React.useState({
    page: 1,
    pageSize: 10,
    registrationTokenOnly: true,
  });
  const [selectedUsersPage, setSelectedUsersPage] = React.useState(1);
  const displaySnackbar = useSnackbar();

  const sendNotificationMutation = useMutation({
    mutationFn: () => {
      const payload: any = {
        notification
      };
      if (notificationType === 'criteria') {
        payload['criteria'] = filters;
      }
      if (notificationType === 'users') {
        payload['ids'] = selectedUsers.map((u) => u.id);
      }
      return sendNotifications(payload)
    },
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Notifications envoyées',
      });
      setSelectedUsers([]);
      setNotification({ title: '', content: '' });
    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Erreur lors de l\'envoie de la notification',
        severity: 'error'
      });
    }
  });

  const usersQuery = useQuery({
    queryKey: ['users', filters],
    queryFn: ({ signal }) => getUsers(filters, signal),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (notificationType === 'criteria') {
        setCount(data.count)
      }
    }
  })

  const headers = React.useMemo(() => {
    if (notificationType === 'criteria') {
      return [
        { title: '#', key: 'id' },
        { title: 'Date d\'inscription', key: 'created_at', type: 'date', dateFormat: 'DD/MM/YYYY' },
        { title: 'Prénom', key: 'first_name' },
        { title: 'Nom', key: 'last_name' },
        { title: 'Email', key: 'email' },
        { title: 'Username', key: 'username' },
        { title: 'VIP', key: 'is_vip', type: 'boolean' },
      ];
    }
    return [
      { title: '#', key: 'id' },
      { title: 'Prénom', key: 'first_name' },
      { title: 'Nom', key: 'last_name' },
      { title: 'Email', key: 'email' },
      { title: 'VIP', key: 'is_vip', type: 'boolean' },
    ];
  }, [notificationType])

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  const handleNotificationTypeChange = (type: string) => {
    if (type === 'criteria') {
      setFilters({ pageSize: 10, page: 1, registrationTokenOnly: true });
      setSelectedUsers([]);
    }
    if (type === 'users') {
      setCount(selectedUsers.length)
    }
    setNotificationType(type)
  }

  React.useEffect(() => {
    if (notificationType === 'users') {
      setCount(selectedUsers.length)
    }
  }, [selectedUsers, notificationType])

  return (
    <div>
      <div className='mt-4'>
        <div className="space-y-8">
          <div className="">
            <div className="flex items-center space-x-4">
              <h2 className="text-base font-semibold leading-7 text-white">Contenu de la notification</h2>
            </div>
          </div>
        </div>
        <NotificationInput onChange={(v) => setNotification(v)} notification={notification} />
        <div className='w-full flex mt-4 justify-end'>
          <LoadingButton
            loading={sendNotificationMutation.isLoading}
            disabled={!notification?.content || !notification?.title || count === 0}
            onClick={(e) => sendNotificationMutation.mutate()}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500 disabled:bg-indigo-300"
          >
            Envoyer la notification à {count} utilisateurs
          </LoadingButton>
        </div>

      </div>
      <div className='mt-4'>
        <div className="hidden sm:block">
          <nav className="flex py-4">
            <ul
              className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-400"
            >
              <li
                onClick={() => handleNotificationTypeChange('criteria')}
                className={classNames(notificationType === 'criteria' ? 'text-indigo-400' : '', 'cursor-pointer')}
              >
                Par criteres
              </li>
              <li
                onClick={() => handleNotificationTypeChange('users')}
                className={classNames(notificationType === 'users' ? 'text-indigo-400' : '', 'cursor-pointer')}
              >
                Par utilisateurs
              </li>
            </ul>
          </nav>
          <AllUsersFilters onFiltersChange={handleOnFiltersChange} />
          <div className="grid grid-cols-2 gap-x-6 gap-y-8">
            <div className={notificationType === 'users' ? 'col-span-1' : 'col-span-full'}>
              <Table
                headers={headers}
                data={usersQuery.data?.items || []}
                loading={usersQuery.isLoading}
                classNameTableContainer='lg:px-4'
                classNameRow={(user: any) => {
                  if (notificationType === 'users') {
                    return selectedUsers.includes(user) ? 'bg-gray-700' : 'hover:bg-gray-700 cursor-pointer'
                  }
                  return undefined
                }}
                onRowClick={notificationType === 'users' ? (user: any) => {
                  if (selectedUsers.includes(user as never)) {
                    setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
                  } else {
                    setSelectedUsers([...selectedUsers, user])
                  }
                } : undefined}
              />
              <Pagination
                count={usersQuery.data?.count}
                page={filters.page}
                onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
                onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
                pageSize={filters.pageSize}
                onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
                totalPages={usersQuery.data?.total_pages}
              />
            </div>
            {notificationType === 'users' && (
              <div className="col-span-1">
                <Table
                  headers={headers}
                  data={selectedUsers.slice((selectedUsersPage - 1) * 10, (selectedUsersPage - 1) * 10 + 10) || []}
                  classNameTableContainer='lg:px-4'
                  classNameRow="cursor-pointer"
                  onRowClick={(user: any) => {
                    setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
                  }}
                />
                <Pagination
                  count={selectedUsers.length}
                  page={selectedUsersPage}
                  onNext={() => setSelectedUsersPage((v) => v + 1)}
                  onPrevious={() => setSelectedUsersPage((v) => v - 1)}
                  pageSize={10}
                  onPageChange={(page: number) => setSelectedUsersPage(page)}
                  totalPages={Math.ceil(selectedUsers.length / 10)}
                />
              </div>
            )}
            <div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
