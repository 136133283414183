import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react'
import { useMutation } from 'react-query';
import { getWordpressArticles } from '../../../../../services/articles';
import { WordpressArticlePreview } from './WordpressArticlePreview';
import { LoadingButton } from '../../../../../components/LoadingButton';

interface Props {
  onClose: (selectedArticle: any | null) => void;
}

export const WordpressImportModal: React.FC<Props> = (props) => {
  const { onClose } = props;
  const [url, setUrl] = React.useState('');
  const [wordpressArticles, setWordpressArticles] = React.useState<any[]>([]);
  const [selectedArticle, setSelectedArticle] = React.useState<any | null>(null);

  const getWordpressArticlesMutation = useMutation<any>({
    mutationKey: ['getWordpressArticles'],
    mutationFn: () => getWordpressArticles(url),
    onSuccess: (data) => {
      setWordpressArticles(data);
    }
  });

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose(null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  Importer un article depuis wordpress
                </Dialog.Title>
                <div className="mt-2">
                  <div className='grid grid-cols-6 gap-x-6'>

                    <div className="col-span-5">
                      <label htmlFor="q" className="block text-sm font-medium leading-6 text-white">
                        URL de l'article
                      </label>
                      <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                        <input
                          type="text"
                          name="url"
                          id="url"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-1 flex items-end justify-center">
                      <LoadingButton
                        loading={getWordpressArticlesMutation.isLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedArticle(null);
                          setWordpressArticles([]);
                          getWordpressArticlesMutation.mutate();
                        }}
                        disabled={getWordpressArticlesMutation.isLoading || !url}
                        className="inline-flex items-center px-2.5 py-2.5 border border-transparent text-xs font-medium rounded text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-violet-500 disabled:bg-gray-500 disabled:cursor-not-allowed disabled:hover:bg-gray-500 disabled:text-gray-300 disabled:shadow-none"
                      >
                        Chercher
                      </LoadingButton>
                    </div>
                  </div>
                  <div className='mt-2'>
                    {wordpressArticles.map((article) => (
                      <WordpressArticlePreview key={article.id} value={article} onSelect={() => setSelectedArticle(article)} isSelected={selectedArticle ? selectedArticle.id === article.id : false} />
                    ))}
                  </div>

                </div>

                <div className="mt-6 flex space-x-4 justify-end">
                  <button type="button" className="text-sm font-semibold leading-6 text-white" onClick={() => onClose(null)}>
                    Annuler
                  </button>
                  <button
                    onClick={(e) => { onClose(selectedArticle) }}
                    disabled={selectedArticle === null}
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:bg-gray-500 disabled:cursor-not-allowed disabled:hover:bg-gray-500 disabled:text-gray-300 disabled:shadow-none"
                  >
                    Importer article
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};
