import React from 'react'
import { useQuery } from 'react-query';
import { getBrokers } from '../services/brokers';

interface Props {
  onChange: (data: any) => void;
  value: string;
}

export const BrokerSelector: React.FC<Props> = (props) => {
  const { onChange, value } = props;
  const brokersQuery = useQuery({
    queryKey: ['brokers'],
    queryFn: () => getBrokers({ limit: 200 }),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return (
    <select
      id="broker"
      name="broker"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="broker-name"
      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
    >
      <option value={''}>-----</option>
      {brokersQuery.data?.items?.map((broker: any) => (
        <option key={broker.id} value={broker.id}>{broker.name}</option>
      )
      )}
    </select>
  )
};
