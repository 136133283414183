import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react'
import { ArticleIdSelector } from './ArticleIdSelector';
import { ArticleFilterSelector } from './ArticleFilterSelector';

interface Props {
  section: any;
  onChange: (value: any) => void;
}

export const SectionArticleSelector: React.FC<Props> = (props) => {
  const { section, onChange } = props;

  const handleModeOnChange = (value: any) => {
    onChange({ ...section, mode: value, filters: {}, article_ids: [] })
  }

  const handleFiltersOnChange = React.useCallback((value: any) => {
    onChange({ ...section, filters: value })
  }, [section, onChange])

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-full">
        <RadioGroup value={section.mode} onChange={handleModeOnChange} className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-6">
          <RadioGroup.Option
            value={'filter'}
            className={({ checked }: { checked: boolean }) =>
              classNames(
                checked
                  ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                  : 'border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10',
                'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700  h-8 cursor-pointer'
              )
            }
          >
            Filtres
          </RadioGroup.Option>
          <RadioGroup.Option
            value={'ids'}
            className={({ checked }: { checked: boolean }) =>
              classNames(
                checked
                  ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                  : 'border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10',
                'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 h-8 cursor-pointer'
              )
            }
          >
            Individuel
          </RadioGroup.Option>
        </RadioGroup>
      </div>
      <div className="mt-2 col-span-full">
        {section.mode === 'filter' && (
          <ArticleFilterSelector filters={section.filters} onChange={handleFiltersOnChange} />
        )}
        {section.mode === 'ids' && (
          <ArticleIdSelector selectedIds={section.article_ids} onChange={(v) => onChange({ ...section, article_ids: v })} />
        )}
      </div>
    </div>
  )
};
