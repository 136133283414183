import { flattenObject } from "../helpers/utils";
import { axiosWithToken } from "./utils";


export const getMessages = async (filters: any) => {
  const response = await axiosWithToken.get('/a/messages', { params: filters });
  return response.data;
}

export const getMessage = async (id: string) => {
  const response = await axiosWithToken.get(`/a/messages/${id}/`);
  return response.data;
}

export const updateMessage = async (id: string, data: any) => {
  const formData = new FormData();
  const flattenData = flattenObject(data);
  for (const key in flattenData) {
    if (key === 'image_file' && flattenData[key] === null) {
      continue;
    }
    formData.append(key, flattenData[key]);
  }
  const response = await axiosWithToken.put(`/a/messages/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const createMessage = async (data: any) => {
  const formData = new FormData();
  const flattenData = flattenObject(data);
  for (const key in flattenData) {
    if (key === 'image_file' && flattenData[key] === null) {
      continue;
    }
    formData.append(key, flattenData[key]);
  }

  const response = await axiosWithToken.post('/a/messages/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const deleteMessage = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/messages/${id}/`);
  return response.data;
}