import React from 'react'
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteUser, getUser, updateUser } from '../../../services/users';
import { UserPhone } from './UserPhone';
import { BrokerAccounts } from './BrokerAccounts/BrokerAccounts';
import { UserSubscriptions } from './UserSubscriptions/UserSubscriptions';
import { useSnackbar } from '../../../hooks/useSnackbar';
import dayjs from 'dayjs';
import { CustomDialog } from '../../../components/CustomDialog';
import { ChangePasswordModal } from './ChangePasswordModal';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { MakeVipModal } from './MakeVipModal';
import { AccountManagerSelector } from '../../../components/AccountManagerSelector';

export const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = React.useState<any>(null);
  const navigate = useNavigate();
  const [deleteUserModal, setDeleteUserModal] = React.useState<any>(false);
  const [changePasswordModal, setChangePasswordModal] = React.useState<any>(false);
  const [makeVipModal, setMakeVipModal] = React.useState<any>(false);
  const displaySnackbar = useSnackbar();
  const userDetailsQuery = useQuery({
    queryKey: ['user', id],
    queryFn: () => getUser(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id,
    onSuccess: (data) => {
      setUser(data);
    },
    onError: (error) => {
      navigate('/admin/utilisateurs')
    }
  });

  const updateUserMutation = useMutation<any>({
    mutationKey: ['updateUser'],
    mutationFn: () => updateUser(user.id, user),
    onSuccess: () => {
      userDetailsQuery.refetch();
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Utilisateur mis à jours',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteUserMutation = useMutation<any>({
    mutationKey: ['deleteUser'],
    mutationFn: () => deleteUser(user.id),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Utilisateur supprimé',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });


  if (!user) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateUserMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            Save
          </button>
          <button
            type="button"
            disabled={user?.is_vip}
            onClick={() => setMakeVipModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-emerald-500 disabled:bg-gray-500 disabled:cursor-not-allowed disabled:hover:bg-gray-500 disabled:text-gray-300 disabled:shadow-none"
          >
            Rendre VIP
          </button>
          <button
            type="button"
            onClick={() => setChangePasswordModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-amber-500"
          >
            Changer le mot de passe
          </button>
          <button
            type="button"
            onClick={() => setDeleteUserModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>
        </div>
      </div>
      <CustomDialog
        title={`Etes vous sur de supprimer ${user?.email}`}
        open={deleteUserModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteUserMutation.mutate(user.id);
            navigate('/admin/utilisateurs')
          }
          setDeleteUserModal(false);
        }}
      />
      {makeVipModal && (
        <MakeVipModal
          onClose={(sub: any) => {
            user.subscriptions.push(sub);
            updateUserMutation.mutate();
            setMakeVipModal(false);
          }}
          user={user}
        />
      )}
      {changePasswordModal && (
        <ChangePasswordModal onClose={() => setChangePasswordModal(false)} user={user} />
      )}
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <div className="flex items-center space-x-4">
            <h2 className="text-base font-semibold leading-7 text-white">Information</h2>
            {user.avatar && (
              <img
                className="inline-block h-14 w-14 rounded-full"
                src={user.avatar}
                alt=""
              />
            )}
          </div>

          <div className="mt-4 grid grid-cols-6 gap-x-6 gap-y-8">
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Email
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={user?.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  autoComplete="email"
                  className="block w-full rounded-md border-0 bg-white/5 px-3 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Prénom
              </label>
              <div className="mt-2">
                <input
                  name="firstName"
                  id="firstName"
                  value={user?.first_name}
                  onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Nom
              </label>
              <div className="mt-2">
                <input
                  name="lastName"
                  id="lastName"
                  value={user?.last_name}
                  onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="birthdate" className="block text-sm font-medium leading-6 text-white">
                Username
              </label>
              <div className="mt-2">
                <input
                  name="birthdate"
                  id="birthdate"
                  value={user?.username}
                  onChange={(e) => setUser({ ...user, username: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="birthdate" className="block text-sm font-medium leading-6 text-white">
                Date de naissance
              </label>
              <div className="mt-2">
                <DatePicker
                  disableFuture
                  value={user.birthdate ? dayjs(user.birthdate) : null}
                  onChange={(date) => setUser({ ...user, birthdate: date?.format('YYYY-MM-DD') })}
                  slotProps={{
                    field: {
                      className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    },
                  }}
                  sx={{
                    root: {
                      width: '100%',
                    },
                    svg: {
                      color: 'white',
                    },
                    input: {
                      color: 'white',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.5rem',
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                      background: 'transparent',
                      width: '100%',
                    }
                  }}
                  className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Date d'inscription
              </label>
              <div className="mt-2">
                <input
                  name="created_at"
                  id="created_at"
                  disabled
                  value={dayjs(user?.created_at).format('DD/MM/YYYY HH:mm')}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-start-1 col-span-1">
              <div className="relative flex gap-x-3 items-center  h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="isVerified"
                    name="isVerified"
                    type="checkbox"
                    disabled
                    defaultChecked={user?.is_verified}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isVerified" className="font-medium text-white">
                    Email verifié
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative flex gap-x-3 items-center  h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="isVip"
                    name="isVip"
                    type="checkbox"
                    disabled
                    checked={user?.is_vip}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isVip" className="font-medium text-white">
                    VIP
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="relative flex gap-x-3 items-center h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="alertNotificationsEnabled"
                    name="alertNotificationsEnabled"
                    type="checkbox"
                    disabled
                    defaultChecked={user?.alert_notifications_enabled}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isActive" className="font-medium text-white">
                    Alerte notifications activées
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative flex gap-x-3 items-center h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="isActive"
                    name="isActive"
                    type="checkbox"
                    defaultChecked={user?.is_active}
                    onChange={(e) => setUser({ ...user, is_active: e.target.checked })}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isActive" className="font-medium text-white">
                    Est actif
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium leading-6 text-white">
                Account Manager
              </label>
              <div className="mt-2">
                <AccountManagerSelector value={user.account_manager ? `${user.account_manager.first_name} ${user.account_manager.last_name}` : ''} onChange={(v) => setUser({ ...user, account_manager: v })} />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
                Note
              </label>
              <div className="mt-2">
                <textarea
                  id="note"
                  name="note"
                  rows={3}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  value={user?.note || ''}
                  onChange={(e) => setUser({ ...user, note: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Téléphone</h2>
          <UserPhone value={user?.phone} onChange={(d) => setUser({ ...user, phone: d })} />
        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Brokers</h2>
          <BrokerAccounts value={user.profile?.broker_accounts} onChange={(d) => setUser({ ...user, profile: { ...user.profile, broker_accounts: d } })} />
        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Abonnements</h2>
          <UserSubscriptions value={user.subscriptions} onChange={(values) => setUser({ ...user, subscriptions: values })} />
        </div>
      </div>
    </form >
  )
};
