import React from 'react'
import { getCountries } from '../services/country';
import { useQuery } from 'react-query';

interface Props {
  onChange: (data: any) => void;
  value: string;
}

export const CountrySelector: React.FC<Props> = (props) => {
  const { onChange, value } = props;
  const countriesQuery = useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries({limit: 200}),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return (
    <select
      id="country"
      name="country"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="country-name"
      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
    >
      <option value={''}>-----</option>
      {countriesQuery.data?.items?.map((country: any) => (
        <option key={country.id} value={country.id}>{country.name} (+{country.phoneCode})</option>
        )
      )}
    </select>
  )
};
