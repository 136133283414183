import React from 'react'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';


interface Props {
  onFiltersChange: (filters: any) => void;
}

export const AllUsersFilters: React.FC<Props> = (props) => {
  const { onFiltersChange } = props;
  const [filters, setFilters] = React.useState<any>({
    q: '',
    vip: undefined,
    createdAtMin: null,
    createdAtMax: null,
  });

  React.useEffect(() => {
    onFiltersChange(filters)
  }, [onFiltersChange, filters])

  return (
    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-2">
        <label htmlFor="q" className="block text-sm font-medium leading-6 text-white">
          Recherche
        </label>
        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <input
            type="text"
            name="q"
            id="q"
            value={filters.q}
            onChange={(e) => setFilters({ ...filters, q: e.target.value })}
            className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-1">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          VIP
        </label>
        <select
          id="country"
          name="country"
          autoComplete="country-name"
          value={filters.vip}
          onChange={(e) => setFilters({ ...filters, vip: e.target.value })}
          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        >
          <option value={''}>-----</option>
          <option value={'true'}>Oui</option>
          <option value={'false'}>Non</option>
        </select>
      </div>
      <div className="col-span-2">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Date d'inscription
        </label>
        <DateRangePicker
          disableFuture
          value={[filters.createdAtMin, filters.createdAtMax]}
          onChange={(newValue) => {
            let createdAtMin = null;
            if (newValue[0] && !newValue[0].invalid) {
              createdAtMin = newValue[0].toISOString();
            }

            let createdAtMax = null;
            if (newValue[1] && !newValue[1].invalid) {
              createdAtMax = newValue[1].toISOString()
            }

            setFilters({
              ...filters,
              createdAtMin,
              createdAtMax,
            })
          }}
          localeText={{ start: 'Inscis apres', end: 'Inscris avant' }}
          slots={{ field: SingleInputDateRangeField }}
          slotProps={{
            field: {
              className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
            },
          }}
          sx={{
            root: {
              width: '100%',
            },
            input: {
              color: 'white',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              lineHeight: '1.5rem',
              paddingLeft: '0.5rem',
              fontSize: '0.875rem',
              background: 'transparent',
              width: '100%',
            }
          }}
          className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
        />
      </div>
    </div>
  )
};
