import classnames from 'classnames';
import React from 'react'
import { RotatingLines } from 'react-loader-spinner';

interface Props {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export const LoadingButton: React.FC<Props> = (props) => {
  const { children, className, loading, disabled, ...overrides } = props;

  let isDisabled = disabled || loading;

  return (
    <button
      disabled={isDisabled}
      className={classnames(className, 'flex items-center gap-2 cursor-pointer')}
      {...overrides}
    >
      {children}
      {loading && (
        <RotatingLines
          strokeColor="white"
          strokeWidth="5"
          animationDuration="0.8"
          width="20"
          visible={true}
        />
      )}
    </button>
  )
};
