import React from 'react'

interface Props {
  onChange: (data: any) => void;
  value: string;
  allowNull?: boolean;
}

export const MessageTypes = [
  { value: '', label: '------' },
  { value: 'campus', label: 'Campus' },
  { value: 'alert', label: 'Alertes' },
]

export const MessageTypeSelector: React.FC<Props> = (props) => {
  const { onChange, value, allowNull = false } = props;

  let finalMessageTypes = MessageTypes;
  if (!allowNull) {
    finalMessageTypes = MessageTypes.filter(v => v.value !== '');
  }

  return (
    <select
      id="message-type"
      name="message-type"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
    >
      {finalMessageTypes.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
};
