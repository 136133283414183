import React from 'react'
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createAlert, deleteAlert, getAlert, updateAlert } from '../../../services/alerts';
import { ProductTypeSelector } from '../../../components/ProductTypeSelector';
import { AlertTypeSelector } from '../../../components/AlertTypeSelector';
import { AlertActionSelector } from '../../../components/AlertActionSelector';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { ProductSelector } from '../../../components/ProductSelector';
import { AlertStatusSelector } from '../../../components/AlertStatusSelector';
import { TakeProfitInputs } from '../../../components/TakeProfitInputs';
import { useSnackbar } from '../../../hooks/useSnackbar';
import dayjs from 'dayjs';
import { CustomDialog } from '../../../components/CustomDialog';
import { TradingViewTickerInput } from '../../../components/TradingViewTickerInput';
import _ from 'lodash';
import { CustomReactQuill } from '../../../components/CustomReactQuill';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { NotificationInput } from '../../Users/Notifications/NotificationInput/NotificationInput';
import { AlertLogo } from './AlertLogo';

export const AlertDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [alert, setAlert] = React.useState<any>(null);
  const [deleteAlertModal, setDeleteAlertModal] = React.useState(false);
  const displaySnackbar = useSnackbar();
  const navigate = useNavigate();
  const alertDetailsQuery = useQuery({
    queryKey: ['alert', id],
    queryFn: () => getAlert(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id && id !== 'new',
    onSuccess: (data) => {
      setAlert(data);
    },
    onError: (error) => {
      navigate('/admin/alertes/trading')
    }
  });

  React.useEffect(() => {
    if (id === 'new') {
      setAlert({
        id: 'new',
        is_active: true,
        result: '',
        status: 'IN_PROGRESS',
        type: 'trading',
        action: 'BUY',
        product_type: '',
        product: '',
        date: '',
        entry_price: 0,
        is_vip: true,
        take_profits: [],
        stop_losses: [],
        details: '',
        button_link: null,
        button_title: null,
        button_text: null,
        notification_enabled: true,
        notification_payload: null,
        logo: null,
      });
    }
  }, [id])

  const handleDuplicateAlert = React.useCallback(() => {
    const newAlert = { ...alert, id: 'new' };
    if (newAlert.logo) {
      newAlert.existing_logo = newAlert.logo;
    }
    setAlert(newAlert);
  }, [alert]);

  const updateAlertMutation = useMutation<any>({
    mutationKey: ['updateAlert'],
    mutationFn: () => alert.id === 'new' ? createAlert(alert) : updateAlert(alert.id, alert),
    onSuccess: (data) => {
      alertDetailsQuery.refetch();
      if (alert.id === 'new') {
        displaySnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          text: 'Alerte créée avec succès',
        })
        navigate(`/admin/alertes/modifier/${data.id}`);
      } else {
        displaySnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          text: 'Alerte mise à jours',
        })
      }

    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteAlertMutation = useMutation<any>({
    mutationKey: ['deleteUser'],
    mutationFn: () => deleteAlert(alert.id),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Alerte supprimée',
      });
      navigate('/admin/alertes/trading');
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });


  if (!alert) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateAlertMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {alert.id !== 'new' ? 'Save' : 'Créer alerte'}
          </button>
          {alert.id !== 'new' && (<button
            type="button"
            onClick={() => setDeleteAlertModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>)}
          {alert.id !== 'new' && (
            <button
              onClick={(e) => { e.preventDefault(); handleDuplicateAlert() }}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lime-500"
            >
              Dupliquer
            </button>
          )}
        </div>
      </div>
      <CustomDialog
        title="Etes vous sur de supprimer cette alerte ?"
        open={deleteAlertModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteAlertMutation.mutate(alert.id);
            navigate('/admin/utilisateurs')
          }
          setDeleteAlertModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Information</h2>
          <div className="mt-4 grid grid-cols-3 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Type
              </label>
              <div className="mt-2">
                <AlertTypeSelector value={alert?.type} onChange={(value) => setAlert({ ...alert, type: value })} />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Action
              </label>
              <div className="mt-2">
                <AlertActionSelector value={alert?.action} onChange={(value) => setAlert({ ...alert, action: value })} />
              </div>
            </div>
            <div className="col-span-1 col-start-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Date & Heure de l'alerte
              </label>
              <div className="mt-2">
                <DateTimePicker
                  value={alert.date ? dayjs(alert.date) : null}
                  onChange={(newValue) => setAlert({ ...alert, date: newValue?.toISOString() })}
                  slotProps={{
                    field: {
                      className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    },
                  }}
                  sx={{
                    root: {
                      width: '100%',
                    },
                    svg: {
                      color: 'white',
                    },
                    input: {
                      color: 'white',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.5rem',
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                      background: 'transparent',
                      width: '100%',
                    }
                  }}
                  className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Date & Heure derniere modification
              </label>
              <div className="mt-2">
                <DateTimePicker
                  value={alert.updated_at_override ? dayjs(alert.updated_at_override) : null}
                  onChange={(newValue) => setAlert({ ...alert, updated_at_override: newValue?.toISOString() })}
                  slotProps={{
                    field: {
                      className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    },
                  }}
                  sx={{
                    root: {
                      width: '100%',
                    },
                    svg: {
                      color: 'white',
                    },
                    input: {
                      color: 'white',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.5rem',
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                      background: 'transparent',
                      width: '100%',
                    }
                  }}
                  className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                />
              </div>
            </div>
            <div className="col-span-1 col-start-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Produit concerné
              </label>
              <ProductTypeSelector canClear={false} value={alert?.product_type} onChange={(value) => setAlert({ ...alert, product_type: value })} />
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Produit
              </label>
              <ProductSelector canClear={false} value={alert?.product} onChange={(value) => setAlert((oldValue: any) => ({ ...oldValue, product: value }))} />
            </div>
            <div className="col-span-1">
              <div className="relative flex gap-x-3 items-end h-full pb-1">
                <div className="flex h-6 items-center">
                  <input
                    id="isVip"
                    name="isVip"
                    type="checkbox"
                    onChange={(e) => setAlert({ ...alert, is_vip: e.target.checked })}
                    defaultChecked={alert?.is_vip}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isVip" className="font-medium text-white">
                    VIP
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <AlertLogo
                value={alert?.logo}
                product={alert?.product}
                onChange={(value) => {
                  console.log(value);
                  setAlert({ ...alert, ...value })
                }}
              />
            </div>
            <div className="col-span-full">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Trading View Ticker
              </label>
              <p className="text-white mt-2 text-sm">
                Ouvrer la{' '}
                <a
                  href="https://www.tradingview.com/widget-docs/widgets/tickers/ticker/"
                  target='_blank'
                  rel="noreferrer"
                  className='text-indigo-500 hover:text-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 cursor-pointer'
                >
                  page ticker de TradingView
                </a>
                {' '}pour trouver les valeurs
              </p>
              <div className='mt-2'>
                {_.isNil(alert?.ticker) ? (
                  <button
                    onClick={(e) => { setAlert({ ...alert, ticker: { symbol: '', name: '' } }) }}
                    className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
                  >
                    Ajouter ticker
                  </button>
                ) : (
                  <TradingViewTickerInput
                    onDelete={() => setAlert({ ...alert, ticker: null })}
                    value={(alert?.ticker) as { symbol: string, name: string }}
                    onChange={(value) => setAlert({ ...alert, ticker: value })}
                  />
                )}
              </div>


            </div>
          </div>
        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Détails</h2>
          <div className="mt-2 grid grid-cols-2 gap-x-6 gap-y-8">
            <div className="col-span-full">
              <div className="mt-2">
                <CustomReactQuill value={alert?.details || ''} onChange={(v) => setAlert({ ...alert, details: v })} />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre du boutton
              </label>
              <div className="mt-2">
                <input
                  name="button_text"
                  id="button_text"
                  value={alert?.button_text || ''}
                  onChange={(e) => setAlert({ ...alert, button_text: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Lien du boutton
              </label>
              <div className="mt-2">
                <input
                  name="button_link"
                  id="button_link"
                  value={alert?.button_link || ''}
                  onChange={(e) => setAlert({ ...alert, button_link: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Résultats</h2>
          <div className="mt-2 grid grid-cols-6 gap-x-6 gap-y-8">
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Entry price
              </label>
              <div className="mt-2">
                <input
                  name="entryPrice"
                  id="entryPrice"
                  value={alert?.entry_price}
                  onChange={(e) => setAlert({ ...alert, entry_price: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2 col-start-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Statut
              </label>
              <div className="mt-2">
                <AlertStatusSelector value={alert?.status} onChange={(value) => setAlert({ ...alert, status: value })} />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Résultat
              </label>
              <div className="mt-2">
                <input
                  name="result"
                  id="result"
                  value={alert?.result}
                  onChange={(e) => setAlert({ ...alert, result: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="takeProfits" className="block text-sm font-medium leading-6 text-white">
                Take profits
              </label>
              <div className='mt-2'>
                <TakeProfitInputs value={alert?.take_profits} onChange={(value) => setAlert({ ...alert, take_profits: value })} />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="takeProfits" className="block text-sm font-medium leading-6 text-white">
                Stop Loss
              </label>
              <div className='mt-2'>
                <input
                  name="stopLoss"
                  id="stopLoss"
                  type='number'
                  value={alert?.stop_losses ? alert?.stop_losses[0] : 0}
                  onChange={(e) => setAlert({ ...alert, stop_losses: [e.target.value] })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-white/10 pb-12">
          <div className='flex justify-between'>
            <h2 className="text-base font-semibold leading-7 text-white">Notifications</h2>
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={alert.notification_enabled}
                onChange={(enabled) => {
                  if (!enabled) {
                    setAlert((oldValue: any) => ({ ...oldValue, notification_payload: null, notification_enabled: enabled }));
                  } else {
                    setAlert((oldValue: any) => ({ ...oldValue, notification_enabled: enabled }));
                  }
                }}
                className={classNames(
                  alert.notification_enabled ? 'bg-indigo-600' : 'bg-gray-700',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    alert.notification_enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-white">Notification activée</span>
              </Switch.Label>
            </Switch.Group>
          </div>
          <div className="mt-2 grid grid-cols-2 gap-x-6 gap-y-8">
            {alert.notification_enabled && (
              <div className="col-span-full">
                <NotificationInput
                  displayOnlyVip
                  notification={alert.notification_payload}
                  onChange={(value) => setAlert((oldValue: any) => ({ ...oldValue, notification_payload: value }))}
                />
              </div>
            )}
          </div>

        </div>
      </div>
    </form>
  )
};
