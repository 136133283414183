import React from 'react'
import { NewsArticlesTabs } from './NewsArticlesTabs';
import { createNewsArticlesPagination, deleteNewsArticlesPagination, getNewsArticlesPagination, getNewsArticlesPaginationTabs, updateNewsArticlesPagination } from '../../../services/articles';
import { useQuery } from 'react-query';
import { LoadingButton } from '../../../components/LoadingButton';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { Section } from './Section';

interface Props {

}

export const NewsArticlesPagination: React.FC<Props> = (props) => {
  const [articlesPaginationTabs, setArticlesPaginationTabs] = React.useState<any>({ original: [], current: [] });
  const [articlesPagination, setArticlesPagination] = React.useState<any>({});
  const [currentTab, setCurrentTab] = React.useState<string>('');
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const displaySnackbar = useSnackbar();

  const handleSave = () => {
    const promises: any[] = [];
    setIsSaving(true);
    articlesPaginationTabs.current.forEach((tab: string, idx: number) => {
      if (articlesPaginationTabs.original.includes(tab)) {
        promises.push(updateNewsArticlesPagination(tab, { name: tab, index: idx, sections: articlesPagination[tab]?.sections || [] }));
      } else {
        promises.push(createNewsArticlesPagination({ name: tab, index: idx, sections: articlesPagination[tab]?.sections || [] }));
      }
    });
    articlesPaginationTabs.original.forEach((tab: string) => {
      if (!articlesPaginationTabs.current.includes(tab)) {
        promises.push(deleteNewsArticlesPagination(tab));
      }
    });
    Promise.all(promises).then(() => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Pagination mise à jour avec succès',
      })
      setIsSaving(false);
    }).catch(() => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Erreur lors de la mise à jour de la pagination',
        severity: 'error',
      })
      setIsSaving(false);
    });
  }

  useQuery({
    queryKey: ['articles', 'news', 'pagination'],
    queryFn: () => {
      return getNewsArticlesPaginationTabs()
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setArticlesPaginationTabs({ original: data, current: data });
      const pagination: any = data.reduce((acc: any, tab: string, idx: number) => {
        acc[tab] = null;
        return acc;
      }, {});
      setArticlesPagination(pagination);
      if (data.length > 0) {
        setCurrentTab(data[0]);
      }
    }
  });

  const paginationTabQuery = useQuery({
    queryKey: ['articles', 'news', 'sections', currentTab],
    queryFn: () => {
      return getNewsArticlesPagination(currentTab)
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!currentTab && articlesPaginationTabs.original.includes(currentTab) && !articlesPagination[currentTab],
    onSuccess: (data: any) => {
      setArticlesPagination((oldValue: any) => {
        return { ...oldValue, [currentTab]: data }
      });
    }
  });

  return (
    <div className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <LoadingButton
          loading={isSaving}
          onClick={handleSave}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
        >
          Save
        </LoadingButton>
      </div>
      <div className='mt-2 border-b border-white/10 pb-4'>
        <NewsArticlesTabs
          tabs={articlesPaginationTabs.current}
          onChange={(tabs) => {
            setArticlesPaginationTabs((oldValue: any) => {
              return { original: oldValue.original, current: tabs }
            });
            if (!articlesPaginationTabs.current.includes(currentTab)) {
              if (tabs.length > 0) {
                setCurrentTab(tabs[0]);
              } else {
                setCurrentTab('');
              }
            }
          }} currentTab={currentTab} onClick={(v) => setCurrentTab(v)} />
      </div>
      <div className='mt-4'>
        <div className="">
          <div className="flex items-center space-x-4">
            <h2 className="text-base font-semibold leading-7 text-white">{currentTab}</h2>
          </div>
        </div>
        {paginationTabQuery.isLoading && (
          <div className="flex items-center justify-center h-40">
            <div className="w-8 h-8 border-2 border-t-2 border-indigo-500 rounded-full animate-spin"></div>
          </div>
        )}
        <div className='mt-2 space-y-4'>
          {articlesPagination[currentTab]?.sections.map((section: any, index: number) => (
            <Section
              key={section.index}
              section={section}
              onDelete={() => {
                setArticlesPagination((oldValue: any) => {
                  return {
                    ...oldValue,
                    [currentTab]: {
                      ...oldValue[currentTab],
                      pagination: oldValue[currentTab].sections.filter((_: any, i: number) => i !== index)
                    }
                  }
                });
              }}
              onChange={(v) => {
                const newSections = [...articlesPagination[currentTab]?.sections];
                newSections[index] = v;
                setArticlesPagination((oldValue: any) => {
                  return {
                    ...oldValue,
                    [currentTab]: {
                      ...oldValue[currentTab],
                      sections: newSections
                    }
                  }
                });
              }}
            />
          ))}
        </div>
        <LoadingButton
          onClick={() => {
            setArticlesPagination((oldValue: any) => {
              return {
                ...oldValue,
                [currentTab]: {
                  sections: [...oldValue[currentTab]?.sections || [], {
                    title: '',
                    orientation: 'horizontal',
                    display: 'thumbnail',
                    color: '',
                    mode: 'filter',
                    filters: {},
                    article_ids: []
                  }]
                }
              }
            })
          }}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-sky-500 mt-4"
        >
          Ajouter une section
        </LoadingButton>
      </div>
    </div>
  )
};
