import { axiosWithToken } from "./utils";


export const getCountries = async (filters: any) => {
  const response = await axiosWithToken.get('/a/countries', { params: filters});
  return response.data;
}

export const getCountry = async (id: string) => {
  const response = await axiosWithToken.get(`/a/countries/${id}/`);
  return response.data;
}

export const updateCountry = async (id: string, data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (key === 'flag_file' && data[key] === null) {
      continue;
    }
    formData.append(key, data[key]);
  }
  const response = await axiosWithToken.put(`/a/countries/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}