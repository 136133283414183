import React, { Fragment } from 'react'
import { useQuery } from 'react-query';
import { Listbox } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { getAccountManagers } from '../services/accountManagers';

interface Props {
  onChange: (data: any) => void;
  value: string;
}

export const AccountManagerSelector: React.FC<Props> = (props) => {
  const { onChange, value } = props;
  const [search, setSearch] = React.useState('')
  const categoriesQuery = useQuery({
    queryKey: ['accountManagers', search],
    queryFn: ({ signal }) => getAccountManagers({ q: search }, signal),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })

  return (
    <div className="w-full relative">
      <Listbox value={value} onChange={onChange} >
        <Listbox.Button className="relative w-full">
          <div className="mt-2 flex items-center justify-center">
            <input
              id="selectedValue"
              value={value || ''}
              readOnly
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
            {value && (
              <div className="h-[36px] flex items-center">
                <XMarkIcon className="h-5 w-5 text-white ml-[-25px]" aria-hidden="true"
                  onClick={(e) => {
                    e.preventDefault();
                    onChange('');
                  }}
                />
              </div>
            )}
          </div>
        </Listbox.Button>
        <Listbox.Options
          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 pb-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50"
        >
          <input
            id="search"
            value={search}
            placeholder='Rechercher'
            onChange={(e) => setSearch(e.target.value)}
            className="block mt-2 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
          {categoriesQuery.data?.items?.map((am: any) => (
            /* Use the `active` state to conditionally style the active option. */
            /* Use the `selected` state to conditionally style the selected option. */
            <Listbox.Option key={am.id} value={am} as={Fragment}>
              <li className="py-2 px-2 text-white cursor-pointer hover:text-indigo-500 flex justify-between items-center max-w-full">
                {am.first_name} {am.last_name}
              </li>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
};
