import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react'

interface Props {
  tabs: string[]
  onChange: (tabs: string[]) => void,
  currentTab: string,
  onClick: (tab: string) => void
}

export const NewsArticlesTabs: React.FC<Props> = (props) => {
  const { tabs, onChange, currentTab, onClick } = props;
  return (
    <div className='grid grid-cols-12 gap-x-2 gap-y-2'>
      {tabs.map((tab, index) => (
        <div className='col-span-2 flex items-center' key={index}>
          <input
            className={classNames(
              tab === currentTab ? 'ring-indigo-500 border border-indigo-500 ' : 'hover:text-indigo-600',
              'rounded-md px-3 py-2 text-sm font-medium w-full bg-white/5 text-white focus:ring-inset focus:ring-indigo-500 cursor-pointer'
            )}
            onChange={(e) => onChange(tabs.map((t, i) => i === index ? e.target.value : t))}
            value={tab}
            onClick={() => onClick(tab)}
          />
          <XMarkIcon
            className="h-5 w-5 text-red-500 cursor-pointer"
            onClick={() => {
              const newTabs = [...tabs];
              newTabs.splice(index, 1);
              onChange(newTabs);
            }}
          />
        </div>
      ))}
      <div className='col-span-2 flex items-center'>
        <button
          onClick={() => onChange([...tabs, 'Nouvelle categorie'])}
          className="inline-flex items-center justify-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-lime-500"
        >
          +
        </button>
      </div>

    </div>
  )
};
