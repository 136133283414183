import classNames from 'classnames';
import _ from 'lodash';
import React from 'react'
import dayjs from 'dayjs';

interface Props {
  data: any;
  headers: { title: string, key: string, type?: undefined | string, transform?: undefined | any, customComponent?: any }[];
  addButtonText?: string
  addButtonOnClick?: () => void;
  loading?: boolean;
  filters?: React.ReactNode;
  onEdit?: (data: any) => void;
  onRowClick?: (data: any) => void;
  editButtonText?: React.ReactNode;
  classNameTableContainer?: string;
  classNameRow?: string | ((data: any) => string | undefined);
  checkbox?: boolean;
  selections?: any[];
  onToggleAll?: (checked: boolean) => void;
  checkedCompareFunc?: (data: any) => boolean;
  onSelect?: (data: any, checked: boolean) => void;
}

const getYoutubeThumbnail = (url: string) => {
  var videoId, result;
  if (!url) {
    return '';
  }
  // eslint-disable-next-line no-cond-assign
  if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
    videoId = result.pop();
  }
  // eslint-disable-next-line no-cond-assign
  else if (result = url.match(/youtu.be\/(.{11})/)) {
    videoId = result.pop();
  }

  if (videoId) {
    return "http://img.youtube.com/vi/" + videoId + "/mqdefault.jpg";
  }
}

const Cell: React.FC<{ value: any, header: any, rawValue: any }> = ({ value, header, rawValue }) => {
  if (header.transform) {
    value = header.transform(value)
  }

  if (header.customComponent) {
    return <header.customComponent value={value} rawValue={rawValue} key={value} />
  }

  if (header.type === 'boolean') {
    return <input type="checkbox" defaultChecked={value} disabled className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900" />
  }
  if (header.type === 'video') {
    const thumbnalUrl = getYoutubeThumbnail(value);
    if (thumbnalUrl) {
      return <img src={thumbnalUrl} className="w-16 h-10" />
    }
  }
  if (header.type === 'image') {
    const width = header.width || 16;
    const height = header.height || 10;
    return <img src={value} alt="" className={`w-${width} h-${height}`} />
  }

  if (header.type === 'date') {
    const dateFormat = header.dateFormat || 'DD/MM/YYYY HH:mm';
    if (!value) {
      return <span></span>
    }
    return <span>{dayjs(value).format(dateFormat)}</span>
  }

  if (header.type === 'colorpicker') {
    return <div className="w-6 h-6 rounded-full" style={{ backgroundColor: value }}></div>
  }

  return <span>{value}</span>
}

export const Table: React.FC<Props> = (props) => {
  const {
    checkbox,
    selections = [],
    checkedCompareFunc,
    onToggleAll,
    onSelect,
    data,
    onEdit,
    onRowClick,
    headers,
    addButtonText,
    filters,
    addButtonOnClick,
    loading,
    editButtonText = 'Editer',
    classNameTableContainer = 'sm:px-6 lg:px-8',
    classNameRow,
  } = props;

  const [checked, setChecked] = React.useState(false);
  const checkboxRef = React.useRef<HTMLInputElement>(null);
  const [indeterminate, setIndeterminate] = React.useState(false)

  React.useLayoutEffect(() => {
    const isIndeterminate = selections.length > 0 && selections.length < data.length
    setChecked(selections.length === data.length)
    setIndeterminate(isIndeterminate)
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = isIndeterminate
    }
  }, [selections, data])

  const toggleAll = (e: any) => {
    setChecked(!checked && !indeterminate)
    if (onToggleAll) {
      onToggleAll(e.target.checked);
    }
  }


  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl">
        <div className="bg-gray-900 pt-2 pb-10">
          <div className="px-4 sm:px-6 lg:px-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                {filters}
              </div>
              {addButtonOnClick && <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  type="button"
                  onClick={addButtonOnClick}
                  className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  {addButtonText}
                </button>
              </div>}

            </div>
            <div className={classNames(
              "mt-6 flow-root",
              loading ? 'blur-sm' : ''
            )}
            >
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className={classNames("inline-block min-w-full py-2 align-middle", classNameTableContainer)}>
                  <table className="min-w-full divide-y divide-gray-700">
                    <thead>
                      <tr>
                        {checkbox && (
                          <th>
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={toggleAll}
                              ref={checkboxRef}
                              className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                            />
                          </th>
                        )}
                        {headers.map((header) => (
                          <th
                            key={header.key}
                            scope="col"
                            className="pr-3 py-2.5 text-left text-sm font-bold text-white"
                          >
                            {header.title}
                          </th>
                        ))}
                        {onEdit && (
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Edit</span>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-800">
                      {data.map((d: any) => {
                        return (
                          <tr key={d.id} className={classNames(_.isFunction(classNameRow) ? classNameRow(d) : classNameRow)} onClick={onRowClick ? () => onRowClick(d) : undefined}>
                            {checkbox && checkedCompareFunc && (
                              <td className="relative px-7 sm:w-12 sm:px-6">
                                {checkedCompareFunc(d) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                )}
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                                  checked={checkedCompareFunc(d)}
                                  onChange={(e) =>
                                    onSelect && onSelect(d, e.target.checked)
                                  }
                                />
                              </td>
                            )}
                            {headers.map((header) => (
                              <td
                                key={`${d.id}_${header.key}`}
                                className="whitespace-nowrap py-4 pl-4 px-3 text-sm font-medium text-white sm:pl-0"
                              >
                                <Cell value={_.get(d, header.key)} header={header} rawValue={d} />
                              </td>))}
                            {onEdit && (
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <span onClick={() => onEdit && onEdit(d)} className="text-indigo-400 hover:text-indigo-300 cursor-pointer">
                                  {editButtonText}
                                </span>
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
};
