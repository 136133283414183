import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createNewsArticle, deleteNewsArticle, getNewsArticle, updateNewsArticle } from '../../../../services/articles';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUpload } from '../../../../components/ImageUpload';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { CustomDialog } from '../../../../components/CustomDialog';
import { NewsArticleCategorySelector } from '../../../../components/NewsArticleCategorySelector';
import { WordpressImportModal } from './WordpressArticles/WordpressImportModal';
import { CustomReactQuill } from '../../../../components/CustomReactQuill';


export const NewsArticleDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = React.useState<any>(() => {
    return {
      id: 'new',
      title: '',
      subtitle: '',
      content: '',
      image: null,
      video: '',
      url: '',
      is_active: true,
    }
  });
  const navigate = useNavigate();
  const [wordpressImportModal, setWordpressImportModal] = React.useState(false);
  const [deleteArticleModal, setDeleteArticleModal] = React.useState(false);
  const displaySnackbar = useSnackbar();
  const queryClient = useQueryClient();
  const newsArticleDetailsQuery = useQuery({
    queryKey: ['newsArticle', id],
    queryFn: () => getNewsArticle(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id && id !== 'new',
    onSuccess: (data) => {
      setArticle(data);
    }
  });

  const updateNewsArticleMutation = useMutation<any>({
    mutationKey: ['updateNewsArticle'],
    mutationFn: () => id !== 'new' ? updateNewsArticle(article.id, article) : createNewsArticle(article),
    onSuccess: (data) => {
      newsArticleDetailsQuery.refetch();
      if (id === 'new') {
        navigate(`/admin/articles/news/${data.id}`)
      }
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: id === 'new' ? 'Article créer' : 'Article mis à jours',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteNewsArticleMutation = useMutation<any>({
    mutationKey: ['deleteNewsArticle'],
    mutationFn: () => deleteNewsArticle(article.id),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Article supprimé',
      })
      queryClient.invalidateQueries('newsArticles');
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  if (!article) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          {id === 'new' && (
            <button
              onClick={(e) => { e.preventDefault(); setWordpressImportModal(true); }}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-violet-500"
            >
              Wordpress Import
            </button>
          )}
          <button
            onClick={(e) => { e.preventDefault(); updateNewsArticleMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {id !== 'new' ? 'Save' : 'Créer article'}
          </button>
          {id !== 'new' && (<button
            type="button"
            onClick={() => setDeleteArticleModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>)}
        </div>
      </div>
      {wordpressImportModal && (
        <WordpressImportModal
          onClose={async (selectedArticle) => {
            if (selectedArticle) {
              const subtitle = selectedArticle.excerpt.rendered.replace(/(<([^>]+)>)/gi, '');
              const respImgae = await fetch(`data:image/jpeg;base64,${selectedArticle.imageRaw}`);
              const blob = await respImgae.blob();
              const file = new File([blob], `${selectedArticle.slug}.jpg`, { type: 'image/jpeg' });
              setArticle((currentState: any) => ({
                ...currentState,
                title: selectedArticle.title.rendered,
                subtitle: subtitle,
                content: selectedArticle.content.rendered,
                image: selectedArticle.embedded.wpFeaturedmedia[0].sourceUrl,
                image_file: file,
                video: null,
                url: selectedArticle.link,
                wordpress_id: selectedArticle.id,
              }));
            }
            setWordpressImportModal(false);
          }}
        />
      )}
      <CustomDialog
        title="Etes vous sur de supprimer cet article ?"
        open={deleteArticleModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteNewsArticleMutation.mutate();
            navigate('/admin/articles/news');
          }
          setDeleteArticleModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Géneral</h2>
          <div className="mt-4 grid grid-cols-6 gap-x-6 gap-y-8">
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Catégorie
              </label>
              <div className="mt-2">
                <NewsArticleCategorySelector value={article?.category?.title} onChange={(value) => setArticle((currentState: any) => ({ ...currentState, category: value }))} />
              </div>
            </div>
            <div className='col-span-2'>
              <label htmlFor="tags" className="block text-sm font-medium leading-6 text-white">
                Sous Catégorie
              </label>
              <div className="mt-2">
                <NewsArticleCategorySelector value={article?.subcategory?.title} onChange={(value) => setArticle((currentState: any) => ({ ...currentState, subcategory: value }))} />
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative flex gap-x-3 items-end pb-2 h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="isActive"
                    name="isActive"
                    type="checkbox"
                    checked={article?.is_active}
                    onChange={(e) => setArticle((currentState: any) => ({ ...currentState, is_active: e.target.checked }))}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isActive" className="font-medium text-white">
                    Est Visible
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre de l'article
              </label>
              <div className="mt-2">
                <input
                  name="title"
                  id="title"
                  value={article?.title || ''}
                  onChange={(e) => setArticle((currentState: any) => ({ ...currentState, title: e.target.value }))}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Sous titre de l'article
              </label>
              <div className="mt-2">
                <textarea
                  name="subtitle"
                  id="subtitle"
                  value={article?.subtitle || ''}
                  onChange={(e) => setArticle((currentState: any) => ({ ...currentState, subtitle: e.target.value }))}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <CustomReactQuill value={article?.content || ''} onChange={(v) => setArticle((currentState: any) => ({ ...currentState, content: v }))} />
            </div>
            <div className='col-span-full'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL
              </label>
              <div className="mt-2">
                <input
                  name="url_article"
                  id="url_article"
                  value={article?.url || ''}
                  onChange={(e) => setArticle((currentState: any) => ({ ...currentState, url: e.target.value }))}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className='col-span-3'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Image
              </label>
              <div className="mt-2">
                <ImageUpload value={article?.image} onChange={(v) => setArticle((currentState: any) => ({ ...currentState, image_file: v }))} />
              </div>
            </div>
            <div className='col-span-3'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL Vidéo
              </label>
              <div className="mt-2">
                <input
                  name="video"
                  id="video"
                  value={article?.video || ''}
                  onChange={(e) => setArticle((currentState: any) => ({ ...currentState, video: e.target.value }))}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
