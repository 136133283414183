import React from 'react'
import { ModeRadio } from './ModeRadio';
import { PaperClipIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { deleteChannelMessage, pinChannelMessage, updateChannelMessage } from '../../../services/canal';
import { useSnackbar } from '../../../hooks/useSnackbar';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Tooltip } from '@mui/material';
import ModalImage from 'react-modal-image';

interface Props {
  value: any;
  onDelete: () => void;
  onPin: () => void;
  onEdit: (message: any) => void;
}

export const ChannelMessage: React.FC<Props> = (props) => {
  const { value, onDelete, onPin, onEdit } = props;
  const [message, setMessage] = React.useState<any>(value);
  const displaySnackbar = useSnackbar();
  const updateChannelMessageMutation = useMutation({
    mutationFn: (newMessage: any) => {
      if (newMessage) {
        return updateChannelMessage(message.id, newMessage);
      } else {
        return updateChannelMessage(message.id, message);
      }
    },
    onSuccess: (data) => {
      setMessage(data);
      displaySnackbar({
        text: 'Message mis à jour',
      })
    },
    onError: (error) => {
      displaySnackbar({
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteMessageMutation = useMutation({
    mutationFn: () => deleteChannelMessage(message.id),
    onSuccess: (data) => {
      setMessage(data);
      displaySnackbar({
        text: 'Message supprimé',
      })
      onDelete();
    },
    onError: (error) => {
      displaySnackbar({
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const pinMessageMutation = useMutation({
    mutationFn: () => pinChannelMessage(message.id),
    onSuccess: (data: any) => {
      setMessage(data);
      displaySnackbar({
        text: `Message ${data.pinned ? 'pinned' : 'un-pinned'}`,
      })
      onPin();
    },
    onError: (error) => {
      displaySnackbar({
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  React.useEffect(() => {
    setMessage(value);
  }, [value]);


  if (!message) {
    return null;
  }

  const isScheduled = dayjs(message.date) > dayjs();

  return (
    <div>
      <div className='flex w-full items-center justify-center text-white mb-2 font-bold'>
        <p>{dayjs(message.date).format('DD/MM/YYYY HH:mm')} {isScheduled ? ' - Planifié' : ''}</p>
      </div>
      <div className={classNames('bg-gray-800 rounded-md px-4 py-2', message.pinned ? 'border border-yellow-500' : '')}>
        <div className='flex justify-between'>
          <ModeRadio isVip={message.is_vip} multi={message.multi} onChange={(isVip, multi) => {
            setMessage({ ...message, is_vip: isVip, multi });
            updateChannelMessageMutation.mutate({ ...message, is_vip: isVip, multi });
          }}
          />
          <div className='flex space-x-2'>
            <div className="flex h-6 items-center">
              <input
                id="isActive"
                name="isActive"
                type="checkbox"
                checked={message.is_active}
                onChange={(e) => {
                  setMessage({ ...message, is_active: e.target.checked });
                  updateChannelMessageMutation.mutate({ ...message, is_active: e.target.checked });
                }}
                className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="isActive" className="font-medium text-white">
                Visible
              </label>
            </div>
          </div>
          <div className='flex space-x-2 justify-center items-center'>
            <PaperClipIcon className='h-4 w-4 text-cyan-500 cursor-pointer' onClick={() => pinMessageMutation.mutate()} />
            <PencilIcon className='h-4 w-4 text-emerald-500 cursor-pointer' onClick={() => onEdit(message)} />
            <TrashIcon className='h-4 w-4 text-red-500 cursor-pointer' onClick={() => deleteMessageMutation.mutate()} />
          </div>
        </div>
        <div className='text-white my-2'>
          <span className="rawHtml text-sm" dangerouslySetInnerHTML={{ __html: message.content }}></span>
          {message.poll && (
            <span className="text-sm text-white">{message.poll.title}</span>
          )}
        </div>
        <div className='flex flex-col w-full space-y-3'>
          {message.video && (
            <div className="text-sm text-white">
              <span className="font-bold">
                Video URL:
                <a href={message.video} target='_blank' rel="noreferrer" className="ml-2 text-indigo-600 font-normal">{message.video}</a>
              </span>
            </div>
          )}
          {message.images && message.images.length > 0 && (
            <div className='flex flex-wrap gap-2'>
              {message.images.map((image: any) => (
                // <img key={image.img} src={image.img} alt="" className='h-32 w-32 object-cover' />
                <ModalImage
                  className="h-32 w-32 object-cover"
                  small={image.img}
                  large={image.img}
                />
              ))}
            </div>
          )}
          {message.button_link && (
            <div className='w-full flex items-center justify-center space-x-4'>
              <button
                onClick={() => window.open(message.button_link, '_blank')}
                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {message.button_text}
              </button>
              <div className='flex space-x-2'>
                <div className="flex h-6 items-center">
                  <input
                    id="isActive"
                    name="isActive"
                    type="checkbox"
                    disabled
                    checked={message?.button_open_in_app}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isActive" className="font-medium text-white">
                    Ouvrir dans l'application
                  </label>
                </div>
              </div>
            </div>
          )}
          {message.vocal && (
            <div className='w-full flex items-center justify-center'>
              <AudioPlayer
                src={message.vocal}
                showJumpControls={false}
                layout='horizontal'
                onPlay={e => console.log("onPlay")}
              />
            </div>
          )}
          {message.poll && (
            <>
              <div className='grid grid-cols-3 gap-x-6'>
                {message.poll.options.map((option: any) => (
                  <Tooltip title={`${option.nb_votes} votes`}>
                    <div key={option.id} className='col-span-1 px-2 py-2 rounded-md text-sm flex items-center justify-between border text-white border-white'>
                      <div className="font-bold">{option.name}</div>
                      <div className="italic">{(option.percentage * 100).toFixed(2)}%</div>
                    </div>
                  </Tooltip>
                ))}
              </div>
              <div>
                <p className='text-white text-sm'>{message.poll.options.reduce((acc: any, option: any) => acc + option.nb_votes, 0)} votes</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
};
