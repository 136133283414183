import { flattenObject } from "../helpers/utils";
import { axiosWithToken } from "./utils";


export const getChannelMessages = async (filters: any) => {
  const response = await axiosWithToken.get('/a/channel', { params: filters });
  return response.data;
}

export const updateChannelMessage = async (id: string, data: any) => {
  const formData = new FormData();
  const flattenData = flattenObject(data);
  for (const key in flattenData) {
    formData.append(key, flattenData[key]);
  }
  const response = await axiosWithToken.put(`/a/channel/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return response.data;
}

export const createChannelMessage = async (data: any) => {
  const formData = new FormData();
  const flattenData = flattenObject(data);
  for (const key in flattenData) {
    formData.append(key, flattenData[key]);
  }
  const response = await axiosWithToken.post('/a/channel/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return response.data;
}

export const deleteChannelMessage = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/channel/${id}/`);
  return response.data;
}

export const pinChannelMessage = async (id: string) => {
  const response = await axiosWithToken.get(`/a/channel/${id}/pin`);
  return response.data;
}