import React from 'react'
import { useQuery } from 'react-query';
import { AllUsersFilters } from './AllUsersFilters';
import { Table } from '../../../components/Table';
import { Pagination } from '../../../components/Pagination';
import { getUsers } from '../../../services/users';
import { useNavigate } from 'react-router-dom';

const headers = [
  { title: '#', key: 'id' },
  { title: 'Date d\'inscription', key: 'created_at', type: 'date', dateFormat: 'DD/MM/YYYY' },
  { title: 'Prénom', key: 'first_name' },
  { title: 'Nom', key: 'last_name' },
  { title: 'Email', key: 'email' },
  { title: 'Username', key: 'username' },
  { title: 'VIP', key: 'is_vip', type: 'boolean' },
]

export const AllUsers: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState({
    page: 1,
    pageSize: 10,
  })
  const usersQuery = useQuery({
    queryKey: ['users', filters],
    queryFn: ({ signal }) => getUsers(filters, signal),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  return (
    <div>
      <Table
        headers={headers}
        data={usersQuery.data?.items || []}
        loading={usersQuery.isLoading}
        filters={
          <AllUsersFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item) => navigate(`/admin/utilisateurs/${item.id}`)}
      />
      <Pagination
        count={usersQuery.data?.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={usersQuery.data?.total_pages}
      />
    </div>
  )
};
