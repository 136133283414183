import dayjs from "dayjs";
import _ from "lodash";


export const flattenObject = (o: any, prefix: string = '', result: any = {}) => {
  if (_.isString(o) || _.isNumber(o) || _.isBoolean(o) || _.isNull(o) || _.isUndefined(o) || o instanceof File || dayjs.isDayjs(o)) {
    let finalValue = o;
    if (dayjs.isDayjs(o)) {
      finalValue = o.format('YYYY-MM-DD HH:mm:ssZZ');
    }
    result[prefix] = finalValue;
    return result;
  }

  if (_.isArray(o) || _.isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (_.isArray(o)) {
        pref = pref + `[${i}]`;
      } else {
        if (_.isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + '__' + i;
        }
      }

      flattenObject(o[i], pref, result);
    }
    return result;
  }

  return result;
}
