import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Table';
import { useQuery } from 'react-query';
import { getNewsArticleCategories } from '../../../services/articles';
import { Pagination } from '../../../components/Pagination';
import { NewsArticleCategoryFilters } from './NewsArticleCategoryFilters';


const headers = [
  { title: '#', key: 'id' },
  { title: 'Titre', key: 'title' },
  { title: 'Couleur', key: 'color', type: 'colorpicker' },
]

export const NewsArticleCategories: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<{ page: number, pageSize: number }>({
    page: 1,
    pageSize: 10,
  })
  const newsArticleCategoriesQuery = useQuery({
    queryKey: ['newsArticleCategories', filters],
    queryFn: () => getNewsArticleCategories(filters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  if (newsArticleCategoriesQuery.isLoading) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <div>
      <Table
        headers={headers}
        data={newsArticleCategoriesQuery.data.items || []}
        loading={newsArticleCategoriesQuery.isLoading}
        filters={
          <NewsArticleCategoryFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item) => navigate(`/admin/articles/categories/${item.id}`)}
      />
      <Pagination
        count={newsArticleCategoriesQuery.data.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={newsArticleCategoriesQuery.data.totalPages}
      />
    </div>
  )
};
