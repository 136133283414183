import React from 'react'
import { AlertStatusSelector } from '../../../components/AlertStatusSelector';
import { ProductTypeSelector } from '../../../components/ProductTypeSelector';
import { ProductSelector } from '../../../components/ProductSelector';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  onFiltersChange: (filters: any) => void;
}

export const ListAlertsFilters: React.FC<Props> = (props) => {
  const { alertType } = useParams();
  const { onFiltersChange } = props;
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<any>({
    q: '',
    vip: undefined,
    status: null,
    productType: null,
    product: null,
  });

  React.useEffect(() => {
    onFiltersChange(filters)
  }, [onFiltersChange, filters])

  return (
    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-1">
        <label htmlFor="q" className="block text-sm font-medium leading-6 text-white">
          Recherche
        </label>
        <div className="flex rounded-md bg-white/5 ring-1 mt-2 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <input
            type="text"
            name="q"
            id="q"
            value={filters.q}
            onChange={(e) => setFilters({ ...filters, q: e.target.value })}
            className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      {alertType === 'historique' && (
        <div className="col-span-1">
          <label htmlFor="q" className="block text-sm font-medium leading-6 text-white">
            Type
          </label>
          <select
            id="type"
            name="type"
            value={filters.type}
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            className="block w-full mt-2 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
          >
            <option value={undefined}>-----</option>
            <option value={'trading'}>Trading</option>
            <option value={'crypto'}>Crypto</option>
          </select>
        </div>
      )}
      <div className="col-span-1">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          VIP
        </label>
        <select
          id="country"
          name="country"
          autoComplete="country-name"
          value={filters.vip}
          onChange={(e) => setFilters({ ...filters, vip: e.target.value })}
          className="block w-full rounded-md mt-2 border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        >
          <option value={''}>-----</option>
          <option value={'true'}>Oui</option>
          <option value={'false'}>Non</option>
        </select>
      </div>
      <div className="col-span-1">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Statut
        </label>
        <div className="mt-2">
          <AlertStatusSelector value={filters.status} onChange={(value) => setFilters({ ...filters, status: value })} />
        </div>
      </div>
      <div className="col-span-1">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Produit concerné
        </label>
        <ProductTypeSelector value={filters.productType} onChange={(e) => setFilters({ ...filters, productType: e })} />
      </div>
      <div className="col-span-1">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Produit
        </label>
        <ProductSelector value={filters.product} onChange={(e) => setFilters({ ...filters, product: e })} />
      </div>
      <div className="col-span-1 flex items-end">
        <button
          onClick={(e) => { navigate('/admin/alertes/modifier/new') }}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-emerald-500"
        >
          Créer une alerte
        </button>
      </div>
    </div>
  )
};
