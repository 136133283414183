import React from 'react'
import { BrokerAccount } from './BrokerAccount';


interface Props {
  value: any;
  disableAdd?: boolean;
  onChange: (data: any) => void;
}

export const BrokerAccounts: React.FC<Props> = (props) => {
  const { value, disableAdd = false, onChange } = props;
  const [brokerAccounts, setBrokerAccounts] = React.useState<any>(value || []);


  const onChangeBrokerAccount = React.useCallback((data: any, index: number) => {
    brokerAccounts[index] = data;
    setBrokerAccounts([...brokerAccounts]);
  }, [brokerAccounts, setBrokerAccounts]);

  React.useEffect(() => {
    onChange(brokerAccounts);
  }, [brokerAccounts]);

  return (
    <div className='flex flex-col space-y-4'>
      {brokerAccounts.map((brokerAccount: any, index: number) => (
        <BrokerAccount
          key={index}
          value={brokerAccount}
          onDelete={() => {
            brokerAccounts.splice(index, 1);
            setBrokerAccounts([...brokerAccounts]);
          }}
          onChange={(data) => onChangeBrokerAccount(data, index)}
        />
      ))}
      {!disableAdd && (<button
        onClick={(e) => {
          brokerAccounts.push({ broker: { id: '' }, account_id: '', status: 'NEW' });
          setBrokerAccounts([...brokerAccounts]);
          e.preventDefault();
        }}
        className="rounded-md self-start bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      >
        Ajouter
      </button>)}
    </div>
  )
};
