import React from 'react'
import { NavLink, matchPath, useLocation, useNavigate } from 'react-router-dom';

interface Props {
  tabs: { route: string, name: string }[];
}

export const Tabs: React.FC<Props> = (props) => {
  const { tabs } = props;
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            onChange={(e) => navigate(e.target.value)}
            name="tabs"
            className="block w-full rounded-md border-none bg-white/5 py-2 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => matchPath(location.pathname, tab.route))?.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.route} className={matchPath(location.pathname, tab.route) ? 'text-indigo-400' : ''}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex border-b border-white/10 py-4">
            <ul
              className="flex min-w-full flex-none gap-x-6 px-2 text-sm font-semibold leading-6 text-gray-400"
            >
              {tabs.map((tab) => (
                <li key={tab.name}>
                  <NavLink to={tab.route} className={matchPath(location.pathname, tab.route) ? 'text-indigo-400' : ''}>
                    {tab.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div >
  )
};
