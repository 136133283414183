import axios from "axios";
import { Cookies } from "react-cookie";
import { axiosWithToken } from "./utils";


export const signIn = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login/admin`, {
      email,
      password
    });
    return response.data;

  } catch (error: any) {
    return { error: error.message }
  }
}

export const fetchUser = async () => {
  try {
    const response = await axiosWithToken.get('/users/me');
    return response.data;
  } catch (error: any) {
    return { error: error.message }
  }
}


export const setCookiesTokens = (accessToken: string, refreshToken: string) => {
  const cookies = new Cookies()
  cookies.set('access-token', accessToken, { path: '/' });
  cookies.set('refresh-token', refreshToken, { path: '/' });
}

export const removeCookiesTokens = () => {
  const cookies = new Cookies()
  cookies.remove('access-token');
  cookies.remove('refresh-token');
}

export const getAccessToken = () => {
  const cookies = new Cookies()
  return cookies.get('access-token');
}

export const getRefreshToken = () => {
  const cookies = new Cookies()
  return cookies.get('refresh-token');
}