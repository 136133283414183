

import React from 'react'
import { Outlet } from 'react-router-dom';

interface Props { }

export const Brokers: React.FC<Props> = (props) => {
  return (
    <Outlet />
  )
};
