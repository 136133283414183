import React from 'react'
import { NewsArticleFilters } from '../NewsArticles/NewsArticleFilters';
import _ from 'lodash';

interface Props {
  filters: any;
  onChange: (value: any) => void;
}

export const ArticleFilterSelector: React.FC<Props> = (props) => {
  const { filters, onChange } = props;

  return (
    <div className="grid grid-cols-12 gap-x-4">
      <div className="col-span-4">
        <label htmlFor="limit" className="block text-sm font-medium leading-6 text-white">
          Nombre maximum d'articles
        </label>
        <div className="mt-2">
          <input
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            type="number"
            name="limit"
            id="limit"
            value={filters.limit}
            onChange={(e) => onChange({ ...filters, limit: parseInt(e.target.value) })}
          />
        </div>
      </div>
      <div className="col-span-3">
        <label htmlFor="offset" className="block text-sm font-medium leading-6 text-white">
          Offset
        </label>
        <div className="mt-2">
          <input
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            type="number"
            name="offset"
            id="offset"
            value={filters.offset}
            onChange={(e) => onChange({ ...filters, offset: parseInt(e.target.value) })}
          />
        </div>
      </div>
      <div className="col-span-full mt-2">
        <NewsArticleFilters
          defaultValue={{ category: filters.category, q: filters.q }}
          onFiltersChange={(values: any) => {
            const { category, ...rest } = values;
            if (category) {
              rest.category__id = category.id;
            }
            const defaultValue = { category: filters.category, q: filters.q };
            if (_.isEqual(rest, defaultValue) || _.isEqual(values, defaultValue)) {
              return;
            }
            onChange({ ...filters, ...rest })
          }}
          create={false}
        />
      </div>

    </div>
  )
};
