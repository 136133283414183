import axios from "axios";
import { getAccessToken, getRefreshToken, setCookiesTokens } from "./auth";

export const axiosWithToken = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    'Content-Type': 'application/json',
    headers: {
      // eslint-disable-next-line
      // @ts-ignore
      Authorization: {
        toString() {
          const token = getAccessToken();
          if (token) return `Bearer ${token}`;
          return undefined;
        },
      },
    },
  });

  axiosWithToken.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      const refreshToken = getRefreshToken();
      if (
        error.response &&
        error.response.status === 401 &&
        error.config &&
        // eslint-disable-next-line
        !error.config.__isRetryRequest &&
        refreshToken
      ) {
        // eslint-disable-next-line
        originalRequest._retry = true;

        return fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setCookiesTokens(res.accessToken, res.refreshToken);
            originalRequest.headers.Authorization = `Bearer ${res.access}`;
            return axios(originalRequest);
          });
      }
      return Promise.reject(error);
    }
  );