import React from 'react'
import { Tabs } from '../../components/Tabs';
import { Outlet } from 'react-router-dom';

const tabs = [{
  name: 'Campus',
  route: '/admin/articles/campus',
}, {
  name: 'News pagination',
  route: '/admin/articles/news-pagination',
}, {
  name: 'News',
  route: '/admin/articles/news',
}, {
  name: 'News Categories',
  route: '/admin/articles/categories',
}];

export const Articles: React.FC = () => {
  return (
    <>
      <Tabs tabs={tabs} />
      <Outlet />
    </>
  )
};
