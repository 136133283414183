import React, { Fragment } from 'react'
import investXLogo from '../../assets/investx-logo.png';
import { useUserContext } from '../../contexts/UserContext';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ColorRing } from 'react-loader-spinner';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { removeCookiesTokens } from '../../services/auth';


const navigation = [
  // { name: 'Dashboard', route: '/admin/dashboard' },
  { name: 'Paramètres', route: '/admin/parametres' },
  { name: 'Utilisateurs', route: '/admin/utilisateurs/tous-les-utilisateurs', baseRoute: '/admin/utilisateurs' },
  { name: 'Alertes', route: '/admin/alertes' },
  { name: 'Articles', route: '/admin/articles/campus', baseRoute: '/admin/articles' },
  { name: 'Canal', route: '/admin/canal' },
  { name: 'Brokers', route: '/admin/brokers' },
  { name: 'Messages', route: '/admin/messages' },
  { name: 'Pays', route: '/admin/pays' },
]



export const Admin: React.FC = (props) => {
  const { user, isLoading, setUser } = useUserContext();
  const location = useLocation()
  const navigate = useNavigate();

  const matchUrl = React.useCallback((pathname: string, route: any) => {
    const compareTo = route.baseRoute ? route.baseRoute : route.route;
    return pathname.startsWith(compareTo);
  }, [])

  const userNavigation = [
    {
      name: 'Sign out',
      onClick: () => {
        removeCookiesTokens();
        setUser(null);
        navigate('/signin');
      }
    },
  ]

  if (!isLoading && !user) {
    return <Navigate to={'/signin'} replace />
  }
  if (isLoading) {
    return (
      <div className="flex h-screen items-center content-center min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-gray-900">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={['#6365f1', '#6365f1', '#6365f1', '#6365f1', '#6365f1']}
        />
      </div>
    )
  }


  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <NavLink to="/admin/dashboard">
                      <img
                        className="h-8"
                        src={investXLogo}
                        alt="Your Company"
                      />
                    </NavLink>

                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.route}
                          className={classNames(
                            matchUrl(location.pathname, item)
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img className="h-8 w-8 rounded-full" src={user.avatar} alt="" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <div
                                  onClick={item.onClick}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              {({ close }) => (
                <>
                  <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        className={classNames(
                          matchUrl(location.pathname, item) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block rounded-md px-3 py-2 text-base font-medium'
                        )}
                        onClick={() => close()}
                        aria-current={matchUrl(location.pathname, item) ? 'page' : undefined}
                      >
                        <NavLink
                          onClick={() => close()}
                          key={item.name}
                          to={item.route}
                          className={classNames(
                            matchUrl(location.pathname, item)
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                        >
                          {item.name}
                        </NavLink>
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-gray-700 pb-3 pt-4">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">{user.firstName} {user.lastName}</div>
                        <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                      </div>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          onClick={item.onClick}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <header className="bg-gray-900 shadow">
        <div className="mx-auto max-w-7xl px-4 pt-6 pb-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-300">{navigation.find(item => matchUrl(location.pathname, item))?.name}</h1>
        </div>
      </header>
      <main className='bg-gray-900 min-h-full' style={{ minHeight: 'calc(100vh - 76px - 64px)' }}>
        <div className="mx-auto max-w-7xl pb-6 sm:px-6 lg:px-8 min-h-full">
          <Outlet />
        </div>
      </main>
    </div>
  )
};
