import React from 'react'
import { ImageUpload } from '../../../components/ImageUpload';
import { ExistingLogoSelector } from './ExistingLogoSelector';

interface Props {
  value: string;
  product: any;
  onChange: (value: any) => void;
}

export const AlertLogo: React.FC<Props> = (props) => {
  const { value, onChange, product } = props;

  return (
    <div className="col-span-full">
      <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
        Logo
      </label>
      <div className="grid grid-cols-12 space-x-4">
        <div className='col-span-6 h-[150px]'>
          <ImageUpload value={value} onChange={(value) => { onChange({ logo_file: value }) }} />
        </div>
        <div className='col-span-6 h-[150px]'>
          <ExistingLogoSelector onChange={(v) => onChange({ logo: v, existing_logo: v })} product={product} />
        </div>
      </div>

    </div>
  )
};
