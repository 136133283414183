import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Table';
import { useQuery } from 'react-query';
import { getNewsArticles } from '../../../services/articles';
import { Pagination } from '../../../components/Pagination';
import { NewsArticleFilters } from './NewsArticleFilters';


const headers = [
  { title: '#', key: 'id' },
  { title: 'Titre', key: 'title' },
  { title: 'Catégorie', key: 'category', transform: (v: any) => v.title },
  { title: 'Date de publication', key: 'created_at', type: 'date' },
  { title: 'Video', key: 'video', type: 'video' },
  { title: 'Image', key: 'image', type: 'image' },
]

export const NewsArticles: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<{ page: number, pageSize: number }>({
    page: 1,
    pageSize: 10,
  })
  const newsArticlesQuery = useQuery({
    queryKey: ['newsArticles', filters],
    queryFn: () => getNewsArticles(filters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    const {category, ...rest} = filters
    if (category) {
      rest.category__id = filters.category.id
    }
    setFilters((v) => ({ ...rest, page: 1 }))
  }, [setFilters]);

  if (newsArticlesQuery.isLoading) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <div>
      <Table
        headers={headers}
        data={newsArticlesQuery.data.items || []}
        loading={newsArticlesQuery.isLoading}
        filters={
          <NewsArticleFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item) => navigate(`/admin/articles/news/${item.id}`)}
      />
      <Pagination
        count={newsArticlesQuery.data.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={newsArticlesQuery.data.totalPages}
      />
    </div>
  )
};
