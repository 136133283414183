import React from 'react'
import { TradingViewTickerInput } from '../../components/TradingViewTickerInput';

interface Props {
  tickers: { symbol: string, name: string }[];
  onChange: (data: { symbol: string, name: string }[]) => void;
}

export const TradingViewTickerCarousel: React.FC<Props> = (props) => {
  const { tickers, onChange } = props;
  return (
    <>
      <div className='flex flex-col space-y-3'>
        {tickers.map((ticker, index) => (
          <TradingViewTickerInput
            value={ticker}
            onChange={(data) => {
              const newValue = [...tickers];
              newValue[index] = data;
              onChange(newValue);
            }}
            onDelete={() => {
              const newValue = [...tickers];
              newValue.splice(index, 1);
              onChange(newValue);
            }}
          />
        ))}
      </div>
      <div className='col-span-3 flex items-center justify-start mt-3'>
        <button
          type="button"
          onClick={() => onChange([...tickers, { symbol: '', name: '' }])}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter
        </button>
      </div>
    </>
  )
};
