import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useMutation, useQuery } from 'react-query';
import { getBroker, updateBroker, createBroker, deleteBroker } from '../../../services/brokers';
import { BrokerLevelSelector } from '../../../components/BrokerLevelSelector';
import { ImageUpload } from '../../../components/ImageUpload';
import { InstructionInputs } from './InstructionInputs';
import { BrokerInstructions } from './BrokerInstructions';
import { CustomDialog } from '../../../components/CustomDialog';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/20/solid';
import { CustomReactQuill } from '../../../components/CustomReactQuill';

export const BrokerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [deleteBrokerModal, setDeleteAlertModal] = React.useState(false);
  const [broker, setBroker] = React.useState<any>(null);
  const displaySnackbar = useSnackbar();
  useQuery({
    queryKey: ['broker', id],
    queryFn: () => getBroker(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id && id !== 'new',
    onSuccess: (data) => {
      setBroker(data);
    }
  });

  React.useEffect(() => {
    if (id === 'new') {
      setBroker({
        broker_tutorial: {
          title: '',
          description: '',
          instructions: [
            {
              url: '',
              type: false,
              image: null,
              title: '',
              subtitle: '',
              advantages: [],
              description: '',
              disadvantages: []
            },
          ]
        },
        is_active: true,
        name: '',
        url: '',
        level: 'Débutant',
        stars: 5.0,
        logo: '',
        details_title: '',
        details_description: '',
        details_image: '',
        details_video: '',
        details_instructions: [],
        title: '',
        rank: null
      })
    }
  }, [id]);

  const updateBrokerMutation = useMutation({
    mutationFn: () => id === 'new' ? createBroker(broker) : updateBroker(broker.id, broker),
    onSuccess: (d) => {
      if (id === 'new') {
        navigate(`/admin/brokers/${d.id}`)
      }
      setBroker(d);
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Broker mis à jour'
      })
    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteBrokerMutation = useMutation({
    mutationFn: () => deleteBroker(broker.id),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Broker supprimé'
      })
    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  if (!broker) {
    return <span className='text-white'>Loading...</span>;
  }


  return (
    <form>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateBrokerMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {id !== 'new' ? 'Save' : 'Créer broker'}
          </button>
          {id !== 'new' && (<button
            type="button"
            onClick={() => setDeleteAlertModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>)}
        </div>
      </div>
      <CustomDialog
        title="Etes vous sur de supprimer ce broker ?"
        open={deleteBrokerModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteBrokerMutation.mutate();
            navigate('/admin/brokers')
          }
          setDeleteAlertModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Géneral</h2>
          <div className="mt-4 grid grid-cols-4 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Nom
              </label>
              <div className="mt-2">
                <input
                  name="broker_name"
                  id="broker_name"
                  value={broker?.name || ''}
                  onChange={(e) => setBroker({ ...broker, name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL
              </label>
              <div className="mt-2">
                <input
                  id="broker_url"
                  value={broker?.url || ''}
                  onChange={(e) => setBroker({ ...broker, url: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Niveau
              </label>
              <div className="mt-2">
                <BrokerLevelSelector onChange={(v) => setBroker({ ...broker, level: v })} value={broker.level} />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Stars
              </label>
              <div className="mt-2">
                <input
                  id="broker_stars"
                  min={0}
                  max={5}
                  value={broker?.stars || ''}
                  type={'number'}
                  onChange={(e) => setBroker({ ...broker, stars: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1 col-start-1">
              <div className="relative flex gap-x-3 items-center  h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="isActive"
                    name="isActive"
                    type="checkbox"
                    defaultChecked={broker?.is_active}
                    onChange={(e) => setBroker({ ...broker, is_active: e.target.checked })}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isActive" className="font-medium text-white">
                    Est Visible
                  </label>
                </div>
              </div>
            </div>
            <div className='col-span-2 col-start-1'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Logo
              </label>
              <div className="mt-2">
                <ImageUpload value={broker?.logo} onChange={(v) => setBroker({ ...broker, logo_file: v })} />
              </div>
            </div>
            <div className='col-span-2'>
              <label htmlFor="batchTable" className="block text-sm font-medium leading-6 text-white">
                Tableau des lots
              </label>
              <div className='mt-2'>
                <CustomReactQuill value={broker?.batch_table} onChange={(v) => setBroker({ ...broker, batch_table: v })} className='max-h-[300px] overflow-y-auto' />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Détails Broker</h2>
          <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre
              </label>
              <div className="mt-2">
                <input
                  name="broker_title"
                  id="broker_title"
                  value={broker?.title || ''}
                  onChange={(e) => setBroker({ ...broker, title: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL vidéo explicative
              </label>
              <div className="mt-2">
                <input
                  name="broker_details_video"
                  id="broker_details_video"
                  value={broker?.details_video || ''}
                  onChange={(e) => setBroker({ ...broker, details_video: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Instructions
              </label>
              <div className="mt-2">
                <InstructionInputs value={broker.details_instructions} onChange={(v) => setBroker({ ...broker, details_instructions: v })} />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Espace tutoriel - Comprendre ma platforme de trading</h2>
          <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre
              </label>
              <div className="mt-2">
                <input
                  name="broker_tutotial_title"
                  id="broker_tutotial_title"
                  value={broker?.details_title || ''}
                  onChange={(e) => setBroker({ ...broker, details_title: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Description
              </label>
              <div className="mt-2">
                <input
                  name="broker_details_video"
                  id="broker_details_video"
                  value={broker?.details_description || ''}
                  onChange={(e) => setBroker({ ...broker, details_description: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className='col-span-1 col-start-1'>
              <div className="mt-2">
                <ImageUpload value={broker?.details_image} onChange={(v) => setBroker({ ...broker, details_image_file: v })} />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-white/10 pb-12">
          <Disclosure as="div" className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                    <h2 className="text-base font-semibold leading-7 text-white">Espace tutoriel - Détails broker</h2>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <MinusSmallIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-8">
                    <div className="col-span-1">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                        Titre
                      </label>
                      <div className="mt-2">
                        <input
                          name="broker_tutorial_title"
                          id="broker_tutorial_title"
                          value={broker?.broker_tutorial?.title || ''}
                          onChange={(e) => setBroker({ ...broker, broker_tutorial: { ...broker.broker_tutorial, title: e.target.value } })}
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                        Description
                      </label>
                      <div className="mt-2">
                        <input
                          name="broker_details_video"
                          id="broker_details_video"
                          value={broker?.broker_tutorial?.description || ''}
                          onChange={(e) => setBroker({ ...broker, broker_tutorial: { ...broker.broker_tutorial, description: e.target.value } })}
                          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className='col-span-full'>
                      <BrokerInstructions
                        value={broker?.broker_tutorial?.instructions}
                        onChange={(v) => setBroker({ ...broker, broker_tutorial: { ...broker.broker_tutorial, instructions: v } })}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </form>
  )
};
