import React from 'react'
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames';


interface Props {
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  onClose?: () => void;
}


export const Alert: React.FC<Props> = (props) => {
  const { message, type, onClose } = props;

  return (
    <div className={classNames({
      "rounded-md p-4": true,
      'bg-green-50': type === 'success',
      'bg-red-50': type === 'error',
      'bg-yellow-50': type === 'warning',
      'bg-blue-50': type === 'info',
    })}>
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
          {type === 'error' && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
          {type === 'warning' && <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />}
          {type === 'info' && <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <p className={classNames({
            'text-sm font-medium': true,
            'text-green-800': type === 'success',
            'text-red-800': type === 'error',
            'text-yellow-700': type === 'warning',
            'text-blue-700': type === 'info',
          })}
          >{message}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onClose}
              className={classNames({
                "inline-flex rounded-md  p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2": true,
                'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50': type === 'success',
                'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50': type === 'error',
                'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50': type === 'warning',
                'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50': type === 'info',
              })}
            >
              <span className="sr-only">Fermer</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};
