import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { BrokerAccounts } from '../UserDetails/BrokerAccounts/BrokerAccounts';
import { updateUser } from '../../../services/users';
import { useMutation } from 'react-query';
import { useSnackbar } from '../../../hooks/useSnackbar';
import React from 'react';
import { AccountManagerSelector } from '../../../components/AccountManagerSelector';

interface Props {
  onClose: () => void;
  value: any[];
}

export const ConfirmationDialog: React.FC<Props> = (props) => {
  const { onClose, value } = props;
  const displaySnackbar = useSnackbar();
  const [users, setUsers] = useState<any[]>([]);

  React.useEffect(() => {
    setUsers(value);
  }, [value])

  console.log(users)


  const updateUserMutation = useMutation({
    mutationKey: ['updateUsers'],
    mutationFn: () => {
      return Promise.all(users.map((user: any) => updateUser(user.id, user)));
    },
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Utilisateur mis à jours',
      })
      onClose();
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  return (
    <Transition appear show={value.length > 0} as={Fragment}>
      <Dialog as="div" className="relative z-10 w-[90vw]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[950px] max-w-[950px] min-h-[300px] transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  Validation des utilisateurs
                </Dialog.Title>
                <div className='flex flex-col gap-y-2'>
                  {users.map((u: any, idx: number) => (
                    <div>
                      <label className="block text-sm font-medium leading-6 text-white mt-2">
                        {u.email}
                      </label>
                      <div className="grid grid-cols-12">
                        <div className="mt-2 col-span-9">
                          <BrokerAccounts
                            value={u?.profile?.broker_accounts}
                            disableAdd
                            onChange={(d) => {
                              const user = users[idx];
                              users[idx] = { ...user, profile: { ...user.profile, broker_accounts: d } }
                              setUsers([...users]);
                            }}
                          />
                        </div>
                        <div className="mt-6 col-span-3 ">
                          <label className="block text-sm font-medium leading-6 text-white">
                            Account Manager
                          </label>
                          <AccountManagerSelector
                            value={u.account_manager ? `${u.account_manager.first_name} ${u.account_manager.last_name}` : ''}
                            onChange={(d) => {
                              const user = users[idx];
                              users[idx] = { ...user, account_manager: d }
                              setUsers([...users]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-6 flex space-x-4 justify-end">
                  <button type="button" className="text-sm font-semibold leading-6 text-white" onClick={onClose}>
                    Annuler
                  </button>
                  <button
                    onClick={(e) => { e.preventDefault(); updateUserMutation.mutate() }}
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Sauvegarder
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};
