import React from 'react'
import { BrokerSelector } from '../../../../components/BrokerSelector';
import { DepositStatusSelector } from '../../../../components/DepositStatusSelector';
import { TrashIcon } from '@heroicons/react/24/outline';

interface Props {
  value: any;
  onDelete: (data: any) => void;
  onChange: (data: any) => void;
}

export const BrokerAccount: React.FC<Props> = (props) => {
  const { value, onDelete, onChange } = props;
  const [brokerAccount, setBrokerAccount] = React.useState<any>(value || { broker: { id: '' }, account_id: '', status: 'NEW' });


  React.useEffect(() => {
    onChange(brokerAccount);
  }, [brokerAccount]);

  return (
    <div className="mt-4 grid grid-cols-7 gap-x-6 gap-y-8">
      <div className="col-span-2">
        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
          Broker
        </label>
        <div className="mt-2">
          <BrokerSelector
            value={brokerAccount?.broker?.id}
            onChange={(brokerId) => setBrokerAccount({ ...brokerAccount, broker: { id: brokerId } })}
          />
        </div>
      </div>

      <div className="col-span-2">
        <label htmlFor="number" className="block text-sm font-medium leading-6 text-white">
          Numéro de compte
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="number"
            id="number"
            value={brokerAccount.account_id}
            onChange={(e) => setBrokerAccount({ ...brokerAccount, account_id: e.target.value })}
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-2">
        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
          Statut
        </label>
        <div className="mt-2">
          <DepositStatusSelector
            value={brokerAccount?.status}
            onChange={(status) => setBrokerAccount({ ...brokerAccount, status })}
          />
        </div>
      </div>
      <div className="col-span-1 items-end flex pb-2">
        <TrashIcon className="h-6 w-6 text-red-500 cursor-pointer" onClick={onDelete}/>
      </div>
    </div>
  )
};
