import { TrashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react'

interface Props {
  questions: any;
  onChange: (value: any) => void;
}

export const CampusArticleMultipleChoicesTest: React.FC<Props> = (props) => {
  const { questions, onChange } = props;

  return (
    <>
      {
        questions?.map((question: any, index: number) => (
          <div className='w-full grid col-span-full grid-cols-12 border border-white/10 p-4 space-x-2' key={index}>
            <div className="col-span-full">
              <label htmlFor="username" className="text-sm font-medium leading-6 text-white flex justify-between">
                <h2>Titre de la question {index + 1}</h2>
                <XMarkIcon className="h-5 w-5 text-white cursor-pointer" aria-hidden="true" onClick={() => {
                  const newValue = [...questions];
                  newValue.splice(index, 1);
                  onChange(newValue);
                }} />
              </label>
              <div className="mt-2">
                <input
                  name="broker_tutorial_title"
                  id="broker_tutorial_title"
                  value={question.title || ''}
                  onChange={(e) => {
                    question.title = e.target.value;
                    onChange(questions);
                  }}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {
              question.answers?.map((answer: any, idx: number) => (
                <div className="col-span-3 mt-2" key={idx}>
                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                    Réponse {idx + 1}
                  </label>
                  <div className="mt-2">
                    <input
                      name="broker_tutorial_title"
                      id="broker_tutorial_title"
                      value={answer.value || ''}
                      onChange={(e) => {
                        answer.value = e.target.value;
                        onChange(questions);
                      }}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                    <div className="flex h-6 items-center mt-2">
                      <div className='flex items-center'>
                        <input
                          type="checkbox"
                          checked={answer.is_valid}
                          onChange={(e) => {
                            questions[index].answers.forEach((a: any) => a.is_valid = false);
                            answer.is_valid = e.target.checked;
                            onChange(questions);
                          }}
                          className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                        />
                        <div className="text-sm leading-6 ml-4">
                          <label htmlFor="isVip" className="font-medium text-white">
                            Bonne réponse
                          </label>
                        </div>
                      </div>

                      {(question.answers.length > 1) && (<TrashIcon className="h-5 w-5 text-red-500 cursor-pointer ml-4" onClick={() => {
                        const newValue = [...question.answers];
                        newValue.splice(idx, 1);
                        questions[index].answers = newValue;
                        onChange(questions);
                      }} />)}
                    </div>


                  </div>

                </div>
              ))
            }
            <div className='col-span-full flex items-center justify-start mt-4'>
              <button
                type="button"
                onClick={() => {
                  questions[index].answers.push({ value: '', is_valid: false });
                  onChange(questions);
                }}
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Ajouter une réponse
              </button>
            </div >
          </div>
        ))
      }
      <div className='col-span-full flex items-center justify-start mt-4'>
        <button
          type="button"
          onClick={() => onChange([...questions, { title: '', answers: [{ value: '', is_valid: true }] }])}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter une question
        </button>
      </div >
    </>
  )
};
