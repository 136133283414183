import React from 'react'
import { EntitySelector } from './EntitySelector';
import { AlertStatuses } from '../../../../components/AlertStatusSelector';

interface Props {
  onChange: (notifications: any) => void;
  redirectionPayload: any;
}

export const RedirectionInAppDefinition = [{
  action: 'messages',
  actionLabel: 'Messages',
  typeOptions: [{
    value: 'campus',
    label: 'Campus'
  }, {
    value: 'alert',
    label: 'Alertes'
  }],
  idSelector: true,
  entityLabelResolver: (data: any) => `${data.id} - ${data.title}`,
  entityValueResolver: (data: any) => data.id,
}, {
  action: 'tutorial',
  actionLabel: 'Tutoriels'
}, {
  action: 'channel',
  actionLabel: 'Canal'
}, {
  action: 'alerts',
  actionLabel: 'Alertes',
  idSelector: true,
  entityLabelResolver: (data: any) => `${data.id} - ${data.productType}: ${data.product} (${AlertStatuses.find((s) => s.value === data.status)?.label})`,
  entityValueResolver: (data: any) => data.id,
}, {
  action: 'campus',
  actionLabel: 'Campus',
  idSelector: true,
  entityLabelResolver: (data: any) => `${data.id} - ${data.title}`,
  entityValueResolver: (data: any) => data.id,
}, {
  action: 'news',
  actionLabel: 'Actualités',
  typeOptionsOptional: true,
  typeOptions: [{
    value: 'article',
    label: 'Article'
  }, {
    value: 'category',
    label: 'Catégorie',
    entityLabelResolver: (data: any) => `${data.id} - ${data.title}`,
    entityValueResolver: (data: any) => data.id,
  }, {
    value: 'bookmark',
    label: 'Bookmark'
  }],
  idSelector: true,
  entityLabelResolver: (data: any) => `${data.id} - ${data.title} (${data.category.name})`,
  entityValueResolver: (data: any) => data.id,
}, {
  action: 'markets',
  actionLabel: 'Marchés',
  typeOptions: [{
    value: 'chart',
    label: 'Graphique'
  }, {
    value: 'calendar',
    label: 'Calendrier'
  }]
}, {
  action: 'brokers',
  actionLabel: 'Comptes de trading',
  idSelector: true,
  entityLabelResolver: (data: any) => `${data.id} - ${data.name}`,
  entityValueResolver: (data: any) => data.id,
}, {
  action: 'profile',
  actionLabel: 'Profil'
}, {
  action: 'user',
  actionLabel: 'Informations personelles'
}, {
  action: 'settings',
  actionLabel: 'Paramètres'
}]


export const NotificationRedirections: React.FC<Props> = (props) => {
  const { onChange, redirectionPayload } = props;

  const actionDefinition = RedirectionInAppDefinition.find((redirection) => redirection.action === redirectionPayload.action);
  const typeOptionDefinition: any = actionDefinition?.typeOptions?.find((typeOption) => typeOption.value === redirectionPayload.type);

  return (
    <div className="grid grid-cols-12 space-x-2">
      <div className="col-span-3">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Vers
        </label>
        <select
          id="redirectionType"
          name="redirectionType"
          autoComplete="redirectionType"
          value={redirectionPayload.action}
          onChange={(e) => onChange({ ...redirectionPayload, action: e.target.value, type: actionDefinition?.typeOptions?.[0]?.value, id: null })}
          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black mt-2"
        >
          {RedirectionInAppDefinition.map((redirection) => (
            <option key={redirection.action} value={redirection.action}>{redirection.actionLabel}</option>
          ))}
        </select>
      </div>
      {actionDefinition?.typeOptions && (
        <div className="col-span-3">
          <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
            Type
          </label>
          <select
            id="redirectionType"
            name="redirectionType"
            autoComplete="redirectionType"
            value={redirectionPayload.type}
            onChange={(e) => onChange({ ...redirectionPayload, type: e.target.value })}
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black mt-2"
          >
            {actionDefinition.typeOptionsOptional && (
              <option value={''}>-----</option>
            )}
            {actionDefinition.typeOptions.map((typeOption) => (
              <option key={typeOption.value} value={typeOption.value}>{typeOption.label}</option>
            ))}
          </select>
        </div>
      )}
      {actionDefinition?.idSelector && actionDefinition.entityValueResolver && actionDefinition.entityLabelResolver && (
        <div className="col-span-6">
          <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
            ID
          </label>
          <EntitySelector
            actionType={redirectionPayload.action}
            typeOption={redirectionPayload.type}
            onChange={(entityId) => onChange({ ...redirectionPayload, id: entityId })}
            value={redirectionPayload.id}
            entityLabelResolver={typeOptionDefinition?.entityLabelResolver || actionDefinition.entityLabelResolver}
            entityValueResolver={typeOptionDefinition?.entityValueResolver || actionDefinition.entityValueResolver}
          />
        </div>
      )}

    </div>
  )
};
