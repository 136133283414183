import _ from "lodash";
import { axiosWithToken } from "./utils";
import { flattenObject } from "../helpers/utils";



export const getParameters = async () => {
  const response = await axiosWithToken.get('/parameters');
  return response.data;
}

export const updateParameters = async (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['image_file'].includes(key)) {
      if (data[key] === null) {
        continue
      }
      formData.append(key, data[key]);
      delete data[key];
      continue;
    }
    if (_.isObject(data[key])) {
      const ret = flattenObject(data[key]);
      for (const k in ret) {
        formData.append(`${key}__${k}`, ret[k]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  const response = await axiosWithToken.post('/parameters', formData, { headers: { 'Content-Type': 'multipart/form-data' } });

  return response.data;
}