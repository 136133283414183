import React from 'react'
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createAccountManager, deleteAccountManager, getAccountManager, updateAccountManager } from '../../../../services/accountManagers';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDialog } from '../../../../components/CustomDialog';

interface Props { }

export const AccountManagerDetails: React.FC<Props> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [accountManager, setAccountManager] = React.useState<any>(null);
  const displaySnackbar = useSnackbar();
  const [deleteAmModal, setDeleteAmModal] = React.useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (id === 'new') {
      setAccountManager({
        id: 'new',
        first_name: '',
        last_name: '',
      });
    }
  }, [id])

  const amDetailsQuery = useQuery({
    queryKey: ['accountManager', id],
    queryFn: () => getAccountManager(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id,
    onSuccess: (data) => {
      setAccountManager(data);
    }
  });

  const updateAccountManagerMutation = useMutation({
    mutationFn: () => id === 'new' ? createAccountManager(accountManager) : updateAccountManager(accountManager.id, accountManager),
    onSuccess: (data) => {
      amDetailsQuery.refetch();
      if (accountManager.id === 'new') {
        displaySnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          text: 'Account manager créé avec succès'
        })
        navigate(`/admin/utilisateurs/account-managers/${data.id}`);
      } else {
        displaySnackbar({
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          text: 'Account manager mis à jour'
        })
      }

    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteAccountManagerMutation: any = useMutation({
    mutationFn: (id: string) => deleteAccountManager(id),
    onSuccess: () => {
      queryClient.invalidateQueries('accountManagers');
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Account manager supprimé'
      })
    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  if (!accountManager) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateAccountManagerMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {accountManager.id !== 'new' ? 'Save' : 'Créer Account Manager'}
          </button>
          {accountManager.id !== 'new' && (
            <button
              type="button"
              onClick={() => setDeleteAmModal(true)}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
            >
              Supprimer
            </button>
          )}
        </div>
      </div>
      <CustomDialog
        title="Etes vous sur de supprimer cet Account Manager ?"
        open={deleteAmModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteAccountManagerMutation.mutate(accountManager.id);
            navigate('/admin/utilisateurs/account-managers')
          }
          setDeleteAmModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Information</h2>
          <div className="mt-4 grid grid-cols-4 gap-x-6 gap-y-8">
            <div className="col-span-2">
              <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-white">
                Prénom
              </label>
              <div className="mt-2">
                <input
                  name="first_name"
                  id="first_name"
                  value={accountManager?.first_name || ''}
                  onChange={(e) => setAccountManager({ ...accountManager, first_name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-white">
                Nom
              </label>
              <div className="mt-2">
                <input
                  name="last_name"
                  id="last_name"
                  value={accountManager?.last_name || ''}
                  onChange={(e) => setAccountManager({ ...accountManager, last_name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-3">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Lien
              </label>
              <div className="mt-2">
                <input
                  name="link"
                  id="link"
                  value={accountManager?.link || ''}
                  onChange={(e) => setAccountManager({ ...accountManager, link: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
