import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { ChannelMessageForm } from './ChannelMessageForm';
import { updateChannelMessage } from '../../../../services/canal';
import { useMutation } from 'react-query';
import { ChannelPollForm } from './ChannelPollForm';

interface Props {
  value: any;
  onClose: (needsRefres?: boolean) => void;
}

export const ChannelMessageModal: React.FC<Props> = (props) => {
  const { value, onClose } = props;
  const [message, setMessage] = React.useState<any>(value);

  const displaySnackbar = useSnackbar();

  const updateChannelMessageMutation = useMutation({
    mutationFn: () => {
      const { images, ...newMessage } = message;
      newMessage.images_to_update = images;
      return updateChannelMessage(message.id, newMessage)
    },
    onSuccess: (data) => {
      setMessage(data);
      displaySnackbar({
        text: 'Message mis à jour',
      })
      onClose(true);
    },
    onError: () => {
      displaySnackbar({
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const onSave = React.useCallback((e: any) => {
    e.preventDefault();
    updateChannelMessageMutation.mutate();
  }, [updateChannelMessageMutation]);

  React.useEffect(() => {
    setMessage(value);
  }, [value])

  return (
    <Transition appear show={!!message} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  Modification du message
                </Dialog.Title>
                {!message?.poll && (
                  <ChannelMessageForm message={message} onChange={(m) => setMessage(m)} />
                )}
                {message?.poll && (
                  <ChannelPollForm
                    message={message}
                    onChange={(m) => {
                      setMessage(m)
                    }}
                  />)}
                <div className="mt-6 flex space-x-4 justify-end">
                  <button type="button" className="text-sm font-semibold leading-6 text-white" onClick={() => onClose(false)}>
                    Annuler
                  </button>
                  <button
                    onClick={onSave}
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Sauvegarder
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};
