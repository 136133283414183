import _ from "lodash";
import { axiosWithToken } from "./utils";
import { flattenObject } from "../helpers/utils";

export const getCampusArticles = async (filters: any) => {
  const response = await axiosWithToken.get('/a/campus-articles', { params: filters });
  return response.data;
}

export const getCampusArticle = async (id: string) => {
  const response = await axiosWithToken.get(`/a/campus-articles/${id}`);
  return response.data;
}

export const updateCampusArticle = async (id: string, data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['image_file', 'old_id'].includes(key) && data[key] === null) {
      continue;
    }
    if (['multiple_choices_test'].includes(key)) {
      formData.append(key, JSON.stringify(data[key]));
      continue;
    }
    formData.append(key, data[key]);
  }
  const response = await axiosWithToken.put(`/a/campus-articles/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const createCampusArticle = async (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['image_file', 'old_id'].includes(key) && data[key] === null) {
      continue;
    }
    if (['multiple_choices_test'].includes(key)) {
      formData.append(key, JSON.stringify(data[key]));
      continue;
    }
    formData.append(key, data[key]);
  }
  const response = await axiosWithToken.post('/a/campus-articles/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const deleteCampusArticle = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/campus-articles/${id}`);
  return response.data;
}

// News Articles

export const getNewsArticles = async (filters: any) => {
  const response = await axiosWithToken.get('/a/news-articles', { params: filters });
  return response.data;
}

export const getNewsArticle = async (id: string) => {
  const response = await axiosWithToken.get(`/a/news-articles/${id}`);
  return response.data;
}

export const updateNewsArticle = async (id: string, data: any) => {
  const formData = new FormData();

  for (const key in data) {
    if (['image_file'].includes(key)) {
      if (data[key] === null) {
        continue
      }
      formData.append(key, data[key]);
      delete data[key];
      continue;
    }
    if (_.isObject(data[key])) {
      const ret = flattenObject(data[key]);
      for (const k in ret) {
        formData.append(`${key}__${k}`, ret[k]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  const response = await axiosWithToken.put(`/a/news-articles/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const createNewsArticle = async (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['image_file'].includes(key)) {
      if (data[key] === null) {
        continue
      }
      formData.append(key, data[key]);
      delete data[key];
      continue;
    }
    if (_.isObject(data[key])) {
      const ret = flattenObject(data[key]);
      for (const k in ret) {
        formData.append(`${key}__${k}`, ret[k]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  const response = await axiosWithToken.post('/a/news-articles/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const deleteNewsArticle = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/news-articles/${id}`);
  return response.data;
}

// News Articles Pagination

export const getNewsArticlesPaginationTabs = async () => {
  const response = await axiosWithToken.get('/a/news-articles-pagination/tabs');
  return response.data;
}

export const getNewsArticlesPagination = async (tab: string) => {
  const response = await axiosWithToken.get(`/a/news-articles-pagination/${tab}`);
  return response.data;
}

export const updateNewsArticlesPagination = async (tab: string, data: any) => {
  const response = await axiosWithToken.put(`/a/news-articles-pagination/${tab}/`, data);
  return response.data;
}

export const createNewsArticlesPagination = async (data: any) => {
  const response = await axiosWithToken.post('/a/news-articles-pagination/', data);
  return response.data;
}

export const deleteNewsArticlesPagination = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/news-articles-pagination/${id}`);
  return response.data;
}


// Categories

export const getNewsArticleCategories = async (filters: any) => {
  const response = await axiosWithToken.get('/a/news-categories', { params: filters });
  return response.data;
}

export const getNewsArticleCategory = async (id: string) => {
  const response = await axiosWithToken.get(`/a/news-categories/${id}`);
  return response.data;
}

export const updateNewsArticleCategory = async (id: string, data: any) => {
  const response = await axiosWithToken.put(`/a/news-categories/${id}/`, data);
  return response.data;
}

export const createNewsArticleCategory = async (data: any) => {
  const response = await axiosWithToken.post('/a/news-categories/', data);
  return response.data;
}

export const deleteNewsArticleCategory = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/news-categories/${id}`);
  return response.data;
}


// Wordpress

export const getWordpressArticles = async (url: string) => {
  const response = await axiosWithToken.get('/news/wordpress-articles', { params: { url } });
  return response.data;
}