import { DateTimePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React from 'react'
import { ModeRadio } from '../ModeRadio';
import { ChannelImages } from './ChannelImages';
import { CustomReactQuill } from '../../../../components/CustomReactQuill';
import { ChannelVocal } from './ChannelVocal';

interface Props {
  message: any;
  onChange: (value: any) => void;
}

export const ChannelMessageForm: React.FC<Props> = (props) => {
  const { message, onChange } = props;

  if (!message) {
    return null;
  }


  return (
    <form className='mt-4'>
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <div className="mt-4 grid grid-cols-3 gap-x-6 gap-y-4">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Date de publication
              </label>
              <div className="mt-2">
                <DateTimePicker
                  value={message.date ? dayjs(message.date) : null}
                  onChange={(newValue) => onChange({ ...message, date: newValue })}
                  slotProps={{
                    field: {
                      className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    },
                  }}
                  sx={{
                    root: {
                      width: '100%',
                    },
                    svg: {
                      color: 'white',
                    },
                    input: {
                      color: 'white',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.5rem',
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                      background: 'transparent',
                      width: '100%',
                    }
                  }}
                  className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                />
              </div>
            </div>
            <div className='col-span-2'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Visibilité
              </label>
              <div className="mt-2 flex justify-between items-center">
                <ModeRadio isVip={message.is_vip} multi={message.multi} onChange={(isVip, multi) => {
                  onChange({ ...message, is_vip: isVip, multi });
                }} />
              </div>
            </div>
            <div className="col-span-1 col-start">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Texte du boutton
              </label>
              <div className="mt-2">
                <input
                  value={message?.button_text || ''}
                  onChange={(e) => onChange({ ...message, button_text: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Liens du boutton
              </label>
              <div className="mt-2">
                <input
                  value={message?.button_link || ''}
                  onChange={(e) => onChange({ ...message, button_link: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className='col-span-1 relative flex gap-x-3 items-end h-full pb-2'>
              <div className="flex h-6 items-center">
                <input
                  id="isActive"
                  name="isActive"
                  type="checkbox"
                  defaultChecked={message?.button_open_in_app}
                  onChange={(e) => onChange({ ...message, button_open_in_app: e.target.checked })}
                  className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                />
              </div>
              <div className="text-sm leading-6">
                <label htmlFor="isActive" className="font-medium text-white">
                  Ouvrir dans l'application
                </label>
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Vidéo URL
              </label>
              <div className="mt-2">
                <input
                  value={message?.video || ''}
                  onChange={(e) => onChange({ ...message, video: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <CustomReactQuill value={message?.content || ''} onChange={(v) => onChange({ ...message, content: v })} />
            </div>
            <div className='col-span-full'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Vocal
              </label>
              <div className='mt-2'>
                <ChannelVocal defaultValue={message?.vocal} onChange={(v) => onChange({ ...message, vocal_file: v })} />
              </div>
            </div>
            <div className='col-span-full'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Images
              </label>
              <div className='mt-2'>
                <ChannelImages
                  defaultValue={message?.images}
                  onChange={(v) => {
                    onChange({ ...message, images: v })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
