import _ from "lodash";
import { axiosWithToken } from "./utils";
import { flattenObject } from "../helpers/utils";


export const getBrokers = async (filters: any) => {
  const response = await axiosWithToken.get('/a/brokers', { params: filters });
  return response.data;
}

export const getBroker = async (id: string) => {
  const response = await axiosWithToken.get(`/a/brokers/${id}/`);
  return response.data;
}

export const updateBroker = async (id: string, data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['details_image_file', 'logo_file'].includes(key)) {
      if (data[key] === null) {
        continue
      }
      formData.append(key, data[key]);
      delete data[key];
      continue;
    }
    if (_.isObject(data[key])) {
      const ret = flattenObject(data[key]);
      for (const k in ret) {
        formData.append(`${key}__${k}`, ret[k]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  const response = await axiosWithToken.put(`/a/brokers/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const createBroker = async (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (['details_image_file', 'logo_file'].includes(key)) {
      if (data[key] === null) {
        continue
      }
      formData.append(key, data[key]);
      delete data[key];
      continue;
    }
    if (_.isObject(data[key])) {
      const ret = flattenObject(data[key]);
      for (const k in ret) {
        formData.append(`${key}__${k}`, ret[k]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  const response = await axiosWithToken.post('/a/brokers/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const deleteBroker = async (id: string) => {
  const response = await axiosWithToken.delete(`/a/brokers/${id}`);
  return response.data;
}

export const updateRank = async (id: string, rank: number) => {
  const response = await axiosWithToken.put(`/a/brokers/${id}/rank/`, { rank });
  return response.data;
}