import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import React from 'react';
import { LoadingButton } from './LoadingButton';

interface Props {
  title: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  confirmButtonIsLoading?: boolean;
  cancelButtonText?: string;
  onClose: (value: any) => void;
  content?: React.ReactNode;
  open: boolean;
}

export const CustomDialog: React.FC<Props> = (props) => {
  const { onClose, title, content, confirmButtonText = 'Confirmer', confirmButtonDisabled = false, confirmButtonIsLoading = false, cancelButtonText = 'Annuler', open } = props;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-900 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-white"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  {content}
                </div>

                <div className="mt-6 flex space-x-4 justify-end">
                  <button type="button" className="text-sm font-semibold leading-6 text-white" onClick={onClose}>
                    {cancelButtonText}
                  </button>
                  <LoadingButton
                    loading={confirmButtonIsLoading}
                    onClick={(e) => { onClose('confirm') }}
                    disabled={confirmButtonDisabled}
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:bg-gray-500 disabled:cursor-not-allowed disabled:hover:bg-gray-500 disabled:text-gray-300 disabled:shadow-none"
                  >
                    {confirmButtonText}
                  </LoadingButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};
