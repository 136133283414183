import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { createMessage, deleteMessage, getMessage, updateMessage } from '../../../services/messages';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CustomDialog } from '../../../components/CustomDialog';
import { ImageUpload } from '../../../components/ImageUpload';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { BrokerSelector } from '../../../components/BrokerSelector';
import _ from 'lodash';
import { CustomReactQuill } from '../../../components/CustomReactQuill';
import { MessageTypeSelector } from '../../../components/MessageTypeSelector';

interface Props { }

export const MessageDetails: React.FC<Props> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [message, setMessage] = React.useState<any>(null);
  const [deleteMessageModal, setDeleteMessageModal] = React.useState(false);
  const displaySnackbar = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const messageDetailsQuery = useQuery({
    queryKey: ['message', id],
    queryFn: () => getMessage(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id && id !== 'new',
    onSuccess: (data) => {
      setMessage(data);
    }
  });

  const updateMessageMutation = useMutation<any>({
    mutationKey: ['updateMessage'],
    mutationFn: () => id !== 'new' ? updateMessage(message.id, message) : createMessage(message),
    onSuccess: (data) => {
      messageDetailsQuery.refetch();
      queryClient.invalidateQueries('messages')
      if (id === 'new') {
        navigate('/admin/messages')
      }
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Message mis à jours',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteMessageMutation = useMutation<any>({
    mutationKey: ['deleteMessage'],
    mutationFn: () => deleteMessage(message.id),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Message supprimé',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  React.useEffect(() => {
    if (id === 'new') {
      setMessage({
        id: 'new',
        image: null,
        title: '',
        video: '',
        content: '',
        date: null,
        broker: null,
        is_active: true,
        is_vip: false,
      })
    }
  }, [id]);

  if (!message) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateMessageMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {id !== 'new' ? 'Save' : 'Créer message'}
          </button>
          {id !== 'new' && (<button
            type="button"
            onClick={() => setDeleteMessageModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>)}
        </div>
      </div>
      <CustomDialog
        title="Etes vous sur de supprimer cet message ?"
        open={deleteMessageModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteMessageMutation.mutate();
            navigate('/admin/messages');
            queryClient.invalidateQueries('messages');
          }
          setDeleteMessageModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Géneral</h2>
          <div className="mt-4 grid grid-cols-6 gap-x-6 gap-y-8">
            <div className="col-span-4">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre de l'article
              </label>
              <div className="mt-2">
                <input
                  name="button_title"
                  id="button_title"
                  value={message?.title || ''}
                  onChange={(e) => setMessage({ ...message, title: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Type du message
              </label>
              <div className="mt-2">
                <MessageTypeSelector value={message?.type} onChange={(v) => setMessage({ ...message, type: v })} />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Date & Heure
              </label>
              <div className="mt-2">
                <DateTimePicker
                  value={message.date ? dayjs(message.date) : null}
                  onChange={(newValue) => setMessage({ ...message, date: newValue })}
                  slotProps={{
                    field: {
                      className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    },
                  }}
                  sx={{
                    root: {
                      width: '100%',
                    },
                    svg: {
                      color: 'white',
                    },
                    input: {
                      color: 'white',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.5rem',
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                      background: 'transparent',
                      width: '100%',
                    }
                  }}
                  className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
                Broker
              </label>
              <div className="mt-2">
                <BrokerSelector
                  value={message?.broker?.id}
                  onChange={(brokerId) => {
                    if (_.isNil(brokerId) || !brokerId) {
                      setMessage({ ...message, broker: null })
                      return;
                    }
                    setMessage({ ...message, broker: { id: brokerId } })
                  }}
                />
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative flex gap-x-3 items-end h-full pb-1">
                <div className="flex h-6 items-center">
                  <input
                    id="isVisible"
                    name="isVisible"
                    type="checkbox"
                    onChange={(e) => setMessage({ ...message, is_active: e.target.checked })}
                    defaultChecked={message?.is_active}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isVisible" className="font-medium text-white">
                    Visible
                  </label>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative flex gap-x-3 items-end h-full pb-1">
                <div className="flex h-6 items-center">
                  <input
                    id="isVip"
                    name="isVip"
                    type="checkbox"
                    onChange={(e) => setMessage({ ...message, is_vip: e.target.checked })}
                    defaultChecked={message?.is_vip}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isVip" className="font-medium text-white">
                    VIP
                  </label>
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <CustomReactQuill value={message?.content || ''} onChange={(v) => setMessage({ ...message, content: v })} />
            </div>
            <div className='col-span-3'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Image
              </label>
              <div className="mt-2">
                <ImageUpload value={message?.image} onChange={(v) => setMessage({ ...message, image_file: v })} />
              </div>
            </div>
            <div className='col-span-3'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL Vidéo
              </label>
              <div className="mt-2">
                <input
                  name="video"
                  id="video"
                  value={message?.video || ''}
                  onChange={(e) => setMessage({ ...message, video: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
