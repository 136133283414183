import React from 'react'
import { ModeRadio } from '../ModeRadio';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ChannelOptionInputs } from './ChannelOptionInputs';

interface Props {
  message: any;
  onChange: (message: any) => void;
}

export const ChannelPollForm: React.FC<Props> = (props) => {
  const { message, onChange } = props;

  return (
    <form className='mt-4'>
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <div className="mt-4 grid grid-cols-3 gap-x-6 gap-y-4">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Date de publication
              </label>
              <div className="mt-2">
                <DateTimePicker
                  value={message.date ? dayjs(message.date) : null}
                  onChange={(newValue) => onChange({ ...message, date: newValue })}
                  slotProps={{
                    field: {
                      className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                    },
                  }}
                  sx={{
                    root: {
                      width: '100%',
                    },
                    svg: {
                      color: 'white',
                    },
                    input: {
                      color: 'white',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      lineHeight: '1.5rem',
                      paddingLeft: '0.5rem',
                      fontSize: '0.875rem',
                      background: 'transparent',
                      width: '100%',
                    }
                  }}
                  className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                />
              </div>
            </div>
            <div className='col-span-2'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Visibilité
              </label>
              <div className="mt-2 flex justify-between items-center">
                <ModeRadio isVip={message.is_vip} multi={message.multi} onChange={(isVip, multi) => {
                  onChange({ ...message, is_vip: isVip, multi });
                }} />
              </div>
            </div>
            <div className="col-span-3 col-start">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre du sondage
              </label>
              <div className="mt-2">
                <input
                  value={message?.poll?.title || ''}
                  onChange={(e) => onChange({ ...message, poll: { ...message.poll, title: e.target.value } })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="takeProfits" className="block text-sm font-medium leading-6 text-white">
                Réponses
              </label>
              <div className='mt-2'>
                <ChannelOptionInputs value={message?.poll?.options} onChange={(value) => onChange({ ...message, poll: { ...message.poll, options: value } })} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
