import React from 'react'
import { ImageUpload } from '../../../../components/ImageUpload';

interface Props {
  defaultValue: any;
  onChange: (value: any) => void;
}

export const ChannelImages: React.FC<Props> = (props) => {
  const { defaultValue, onChange } = props;
  const [images, setImages] = React.useState<any>([]);

  React.useEffect(() => {
    setImages(defaultValue);
  }, [])

  React.useEffect(() => {
    onChange(images)
  }, [images])

  const imagesHtml = React.useMemo(() => {
    return images.map((image: any, idx: number) => (
      <div className='col-span-1' key={JSON.stringify({ ...image, id: idx })}>
        <ImageUpload
          value={image.img}
          uploadedFile={image.image_file}
          onChange={(v) => {
            if (!v) {
              return;
            }
            const newImages = [...images];
            newImages[idx].image_file = v;
            setImages(newImages);
          }}
          onDelete={() => {
            const newImages = [...images];
            newImages.splice(idx, 1);
            setImages(newImages);
          }}
        />
      </div>
    ))
  }, [images]);


  return (
    <div className="grid grid-cols-3 gap-x-4 gap-y-4">
      {imagesHtml}
      <div className="col-span-1 flex items-center">
        <button
          type="button"
          onClick={() => setImages((prevValue: any) => [...prevValue, { img: null }])}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter
        </button>
      </div>
    </div>
  )
};
