import React from 'react'
import { useParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useMutation, useQuery } from 'react-query';
import { getCountry, updateCountry } from '../../../services/country';
import { ImageUpload } from '../../../components/ImageUpload';

export const CountryDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [country, setCountry] = React.useState<any>(null);
  const displaySnackbar = useSnackbar();
  useQuery({
    queryKey: ['country', id],
    queryFn: () => getCountry(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id,
    onSuccess: (data) => {
      setCountry(data);
    }
  });

  const updateCountryMutation = useMutation({
    mutationFn: () => updateCountry(country.id, country),
    onSuccess: (d) => {
      setCountry(d);
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Pays mis à jour'
      })
    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue'
      })
    }
  })

  if (!country) {
    return <span className='text-white'>Loading...</span>;
  }

  return (
    <form>
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Géneral</h2>
          <div className="mt-4 grid grid-cols-3 gap-x-6 gap-y-8">
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Nom
              </label>
              <div className="mt-2">
                <input
                  name="button_title"
                  id="button_title"
                  value={country?.name || ''}
                  onChange={(e) => setCountry({ ...country, name: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Préfix # de téléphone
              </label>
              <div className="mt-2">
                <input
                  name="button_title"
                  id="button_title"
                  value={country?.phone_code || ''}
                  onChange={(e) => setCountry({ ...country, phone_code: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-1 col-start-1">
              <div className="relative flex gap-x-3 items-center h-full">
                <div className="flex h-6 items-center">
                  <input
                    id="isActive"
                    name="isActive"
                    type="checkbox"
                    checked={country?.is_active}
                    onChange={(e) => setCountry({ ...country, is_active: e.target.checked })}
                    className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="isActive" className="font-medium text-white">
                    Est Visible
                  </label>
                </div>
              </div>
            </div>
            <div className='col-span-1 col-start-1'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Image
              </label>
              <div className="mt-2">
                <ImageUpload value={country?.flag} onChange={(v) => setCountry({ ...country, flag_file: v })} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          onClick={(e) => { e.preventDefault(); updateCountryMutation.mutate() }}
          className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Save
        </button>
      </div>
    </form>
  )
};
