import { DevicePhoneMobileIcon, StarIcon, UsersIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { useQuery } from 'react-query';
import { getStats } from '../../services/users';
import { Brush, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { CountrySelector } from '../../components/CountrySelector';
import { BrokerSelector } from '../../components/BrokerSelector';

interface Props { }

export const Dashboard: React.FC<Props> = (props) => {
  const stats = [
    { id: 'nbTotal', name: 'Total Utilisateurs', stat: null, icon: UsersIcon, route: '/admin/utilisateurs' },
    { id: 'nbVip', name: 'Vip', stat: null, icon: StarIcon, route: '/admin/utilisateurs' },
    { id: 'nbAndroid', name: 'Android', stat: null, icon: DevicePhoneMobileIcon, route: '/admin/utilisateurs' },
    { id: 'nbIos', name: 'iOs', stat: null, icon: DevicePhoneMobileIcon, route: '/admin/utilisateurs' },
  ]
  const [filters, setFilters] = React.useState<any>({
    createdAtMin: null,
    createdAtMax: null,
    platform: null,
    country_id: null,
  })

  const statsQuery = useQuery({
    queryKey: ['stats', filters],
    queryFn: () => getStats(filters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return (
    <div className='space-y-4'>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-gray-800 px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-400">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <p className="text-2xl font-semibold text-white">{statsQuery.data ? statsQuery.data[item.id] : 0}</p>
            </dd>
          </div>
        ))}
      </dl>
      <dl className="w-full h-[300px] space-y-8">
        <div className='grid grid-cols-12 gap-x-6 gap-y-8 sm:grid-cols-12'>
          <div className="col-span-3">
            <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
              Date d'inscription
            </label>
            <DateRangePicker
              disableFuture
              value={[filters.createdAtMin, filters.createdAtMax]}
              onChange={(newValue) => {
                let createdAtMin = null;
                if (newValue[0] && !newValue[0].invalid) {
                  createdAtMin = newValue[0].format('YYYY-MM-DD');
                }

                let createdAtMax = null;
                if (newValue[1] && !newValue[1].invalid) {
                  createdAtMax = newValue[1].format('YYYY-MM-DD');
                }

                setFilters({
                  ...filters,
                  createdAtMin,
                  createdAtMax,
                })
              }}
              localeText={{ start: 'Inscis apres', end: 'Inscris avant' }}
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                field: {
                  className: 'flex w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                },
              }}
              sx={{
                root: {
                  width: '100%',
                },
                input: {
                  color: 'white',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  lineHeight: '1.5rem',
                  paddingLeft: '0.5rem',
                  fontSize: '0.875rem',
                  background: 'transparent',
                  width: '100%',
                }
              }}
              className='flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            />
          </div>
          <div className="col-span-2">
            <label htmlFor="platform" className="block text-sm font-medium leading-6 text-white">
              OS
            </label>
            <select
              id="platform"
              name="platform"
              value={filters.os}
              onChange={(e) => setFilters({ ...filters, platform: e.target.value })}
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
            >
              <option value={''}>-----</option>
              <option value={'android'}>Android</option>
              <option value={'ios'}>iOs</option>
            </select>
          </div>
          <div className="col-span-3">
            <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
              Pays
            </label>
            <CountrySelector
              value={filters.country_id}
              onChange={(v) => setFilters({ ...filters, country_id: v })}
            />
          </div>
          <div className="col-span-3">
            <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
              Broker
            </label>
            <BrokerSelector
              value={filters.broker_id}
              onChange={(v) => setFilters({ ...filters, broker_id: v })}
            />
          </div>
        </div>
        <ResponsiveContainer className="w-full">
          <LineChart data={statsQuery.data?.usersOverTime} height={500} margin={{ top: 0, right: 25, bottom: 0, left: -20 }}>
            <Line type="monotone" dataKey="count" stroke="#6366f1" />
            <XAxis dataKey="day" stroke='#fff' angle={-65} height={100} tickMargin={30} tick={{ fontSize: '0.9em' }} />
            <YAxis stroke='#fff' tick={{ fontSize: '0.9em' }} />
            <Tooltip formatter={(value) => [value, '# d\'inscription']} />
            <Brush data={statsQuery.data?.usersOverTime} dataKey="day" startIndex={0} endIndex={statsQuery.data?.usersOverTime.length > 15 ? 15 : 7} stroke="#fff" fill="#111827">
              <LineChart data={statsQuery.data?.usersOverTime}>
                <Line type="monotone" dataKey="count" stroke="#6366f1" dot={false} />
              </LineChart>
            </Brush>
          </LineChart>
        </ResponsiveContainer>
      </dl>
    </div>
  )
};
