import React from 'react'
import { PhotoIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames';
import _ from 'lodash';

interface Props {
  value: any;
  onChange: (data: any) => void;
  canDelete?: boolean;
  onDelete?: () => void;
  uploadedFile?: any;
}

export const ImageUpload: React.FC<Props> = (props) => {
  const { value, onChange, onDelete, uploadedFile } = props;
  const [image, setImage] = React.useState<any>(value || null);
  const [imageToUpload, setImageToUpload] = React.useState<any>(null);

  const uniqueId = React.useMemo(() => _.uniqueId("input-"), []);

  const handleOnChange = React.useCallback((e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageToUpload(e.target.files[0]);
  }, []);

  React.useEffect(() => {
    if (uploadedFile) {
      setImage(URL.createObjectURL(uploadedFile));
    }
  }, [uploadedFile])

  React.useEffect(() => {
    onChange(imageToUpload);
  }, [imageToUpload]);

  React.useEffect(() => {
    if (value) {
      setImage(value);
    }
  }, [value]);


  return (
    <div className="flex justify-center items-center relative rounded-lg border border-dashed border-white/25 px-6 py-10 group h-full">
      {image && (
        <div className="absolute top-0 w-full h-full hidden group-hover:flex items-center justify-center backdrop-blur-md flex-col space-y-3">
          <label
            className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
          >
            <span className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Upload une nouvelle image</span>
            <input id={uniqueId + '-1'} name={uniqueId + '-1'} type="file" className="sr-only" alt="articleimage" onChange={handleOnChange} accept="image/png, image/jpeg" />
          </label>
          {onDelete && (<span onClick={onDelete} className="rounded-md cursor-pointer bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">Supprimer</span>)}
        </div>
      )}
      {!image && (
        <div className={classNames("text-center flex flex-col space-y-2", image ? 'group-hover:blur-lg' : '')}>
          <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
          <div className="mt-4 flex flex-col text-sm leading-6 text-gray-400">
            <label
              className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
            >
              <span>Upload une image</span>
              <input id={uniqueId + '-2'} name={uniqueId + '-2'} type="file" className="sr-only" alt="articleimage" onChange={handleOnChange} accept="image/png, image/jpeg" />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-400">PNG, JPG up to 10MB</p>
          {onDelete && (
            <span onClick={onDelete} className="rounded-md cursor-pointer bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
              Supprimer
            </span>
          )}
        </div>
      )}
      {image && (<img src={image} className="h-full" alt="articleimage" />)}
    </div>
  )
};
