import classNames from 'classnames';
import React from 'react'
import ReactQuill from 'react-quill';
import '../pages/Parameters/quill.css';


interface Props {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export const CustomReactQuill: React.FC<Props> = (props) => {
  const { value, onChange, className } = props;

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'align', 'color', 'background'
  ];

  return (
    <ReactQuill theme="snow" value={value || ''} onChange={onChange} modules={modules} formats={formats} className={classNames(className)} />
  )
};
