import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Table';
import { useQuery } from 'react-query';
import { getCampusArticles } from '../../../services/articles';
import { Pagination } from '../../../components/Pagination';
import { CampusArticleFilters } from './CampusArticleFilters';


const headers = [
  { title: '#', key: 'id' },
  { title: 'Titre', key: 'title' },
  { title: 'Video', key: 'video', type: 'video' },
  { title: 'Image', key: 'image', type: 'image' },
]

export const CampusArticles: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<{ page: number, pageSize: number }>({
    page: 1,
    pageSize: 10,
  })
  const campusArticlesQuery = useQuery({
    queryKey: ['campusArticles', filters],
    queryFn: () => getCampusArticles(filters),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  if (campusArticlesQuery.isLoading) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <div>
      <Table
        headers={headers}
        data={campusArticlesQuery.data.items || []}
        loading={campusArticlesQuery.isLoading}
        filters={
          <CampusArticleFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item) => navigate(`/admin/articles/campus/${item.id}`)}
      />
      <Pagination
        count={campusArticlesQuery.data.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={campusArticlesQuery.data.totalPages}
      />
    </div>
  )
};
