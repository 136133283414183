import _ from "lodash";
import { axiosWithToken } from "./utils";



export const getStats = async (filters: any) => {
  const response = await axiosWithToken.get('/users/stats', { params: filters });
  return response.data;
}

export const getUsers = async (filters: any, signal: any) => {
  const response = await axiosWithToken.get('/users', { params: filters, signal });
  return response.data;
}

export const getUser = async (id: string) => {
  const response = await axiosWithToken.get(`/users/${id}`);
  return response.data;
}

export const updateUser = async (id: string, data: any) => {
  const profile = data.profile;
  if (profile && profile.broker_accounts && profile.broker_accounts.length > 0) {
    profile.broker_accounts = profile.broker_accounts.map((broker_account: any) => {
      console.log(broker_account)
      if (!_.isNil(broker_account.account_id) && broker_account.account_id.trim() === '') {
        broker_account.account_id = null;
      }
      return broker_account;
    });
  }
  const response = await axiosWithToken.put(`/users/${id}/`, data);
  return response.data;
}

export const deleteUser = async (id: string) => {
  const response = await axiosWithToken.delete(`/users/${id}`);
  return response.data;
}

export const changeUserPassword = async (id: string, data: any) => {
  const response = await axiosWithToken.post(`/users/${id}/change-password/`, data);
  return response.data;
}

export const sendNotifications = async (data: any) => {
  const response = await axiosWithToken.post('/users/a/send-notifications', data);
  return response.data;
}