import React from 'react'
import { UserSubscription } from './UserSubscription';

interface Props {
  value: any;
  onChange: (data: any) => void;
}

export const UserSubscriptions: React.FC<Props> = (props) => {
  const { value, onChange } = props;

  return (
    <div className='flex flex-col space-y-4'>
      {value.map((subscription: any, index: number) => (
        <UserSubscription
          key={index}
          value={subscription}
          onDelete={() => {
            value.splice(index, 1);
            onChange([...value]);
          }}
          onChange={(data) => {
            value[index] = data;
            onChange([...value]);
          }}
        />
      ))}
      <button
        onClick={(e) => {
          value.push({ start: null, end: null });
          onChange([...value]);
          e.preventDefault();
        }}
        className="rounded-md self-start bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      >
        Ajouter
      </button>
    </div>
  )
};
