import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUser } from "../services/auth";

interface UserContextValues {
  user: any | null;
  isLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<any | null>>;
}

const UserContext = createContext<UserContextValues | undefined>(undefined);


const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      const user = await fetchUser();
      if (user.error) {
        setIsLoading(false);
        setUser(null);
        return;
      }
      setIsLoading(false);
      setUser(user);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const value = React.useMemo(() => ({ user, isLoading, setUser }), [user, isLoading, setUser]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserContext, UserContext };