import { axiosWithToken } from "./utils";


export const getAccountManagers = async (filters: any, signal: any) => {
  const response = await axiosWithToken.get('/users/a/account-managers', { params: filters, signal });
  return response.data;
}

export const getAccountManager = async (id: string) => {
  const response = await axiosWithToken.get(`/users/a/account-managers/${id}`);
  return response.data;
}

export const updateAccountManager = async (id: string, data: any) => {
  const response = await axiosWithToken.put(`/users/a/account-managers/${id}/`, data);
  return response.data;
}

export const deleteAccountManager = async (id: string) => {
  const response = await axiosWithToken.delete(`/users/a/account-managers/${id}`);
  return response.data;
}

export const createAccountManager = async (data: any) => {
  const { id, ...rest } = data;
  const response = await axiosWithToken.post('/users/a/account-managers/', rest);
  return response.data;
}

