import React from 'react'
import { CountrySelector } from '../../../components/CountrySelector';

interface Props {
  value: any;
  onChange: (data: any) => void;
}

export const UserPhone: React.FC<Props> = (props) => {
  const { value, onChange } = props;
  const [phone, setPhone] = React.useState<any>(value || { country: { id: '' }, number: '' });

  React.useEffect(() => {
    onChange(phone);
  }, [phone]);


  return (
    <div className="mt-4 grid grid-cols-6 gap-x-6 gap-y-8">
      <div className="col-span-2">
        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
          Pays
        </label>
        <div className="mt-2">
          <CountrySelector
            value={phone.country.id}
            onChange={(countryId) => setPhone({ ...phone, country: { id: countryId } })}
          />
        </div>
      </div>

      <div className="col-span-2">
        <label htmlFor="number" className="block text-sm font-medium leading-6 text-white">
          Numéro
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="number"
            id="number"
            value={phone?.number}
            onChange={(e) => setPhone({ ...phone, number: e.target.value })}
            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    </div>
  )
};
