import React from 'react'

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const DisplaySelector: React.FC<Props> = (props) => {
  const { value, onChange } = props;
  return (
    <select
      id="orientation"
      name="orientation"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
    >
      <option value={'preview'}>Preview</option>
      <option value={'thumbnail'}>Thumbnail</option>
    </select>
  )
};
