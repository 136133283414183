import { Navigate, createBrowserRouter } from "react-router-dom";
import { SignIn } from "./pages/SignIn/SignIn";
import App from "./App";
import { Admin } from "./pages/Admin/Admin";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Parameters } from "./pages/Parameters/Parameters";
import { Users } from "./pages/Users/Users";
import { Alerts } from "./pages/Alerts/Alerts";
import { Articles } from "./pages/Articles/Articles";
import { Canal } from "./pages/Canal/Canal";
import { Brokers } from "./pages/Brokers/Brokers";
import { Messages } from "./pages/Messages/Messages";
import { Countries } from "./pages/Countries/Countries";
import { AllUsers } from "./pages/Users/AllUsers/AllUsers";
import { WaitingForValidation } from "./pages/Users/WaitingForValidation/WaitingForValidation";
import { UserDetails } from "./pages/Users/UserDetails/UserDetails";
import { ListAlerts } from "./pages/Alerts/ListAlerts/ListAlerts";
import { CampusArticles } from "./pages/Articles/CampusArticles/CampusArticles";
import { NewsArticles } from "./pages/Articles/NewsArticles/NewsArticles";
import { AlertDetails } from "./pages/Alerts/AlertDetails/AlertDetails";
import { CampusArticleDetails } from "./pages/Articles/CampusArticles/CampusArticleDetails/CampusArticleDetails";
import { BrokerDetails } from "./pages/Brokers/BrokerDetails/BrokerDetails";
import { AllBrokers } from "./pages/Brokers/AllBrokers/AllBrokers";
import { AllCountries } from "./pages/Countries/AllCountries/AllCountries";
import { CountryDetails } from "./pages/Countries/CountryDetails/CountryDetails";
import { AllMessages } from "./pages/Messages/AllMessages/AllMessages";
import { MessageDetails } from "./pages/Messages/MessageDetails/MessageDetails";
import { NewsArticleCategories } from "./pages/Articles/ArticleCategories/NewsArticleCategories";
import { NewsArticleCategoryDetails } from "./pages/Articles/ArticleCategories/NewsArticleCategoryDetails/NewsArticleCategoryDetails";
import { NewsArticleDetails } from "./pages/Articles/NewsArticles/NewsArticleDetails/NewsArticleDetails";
import { Notifications } from "./pages/Users/Notifications/Notifications";
import { NewsArticlesPagination } from "./pages/Articles/NewsArticlesPagination/NewsArticlesPagination";
import { ListAccountManager } from "./pages/Users/AccountManagers/ListAccountManagers/ListAccountManager";
import { AccountManagerDetails } from "./pages/Users/AccountManagers/AccountManagerDetails/AccountManagerDetails";

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to={'/signin'} replace />
      }, {
        path: '/signin',
        element: <SignIn />
      }, {
        path: '/admin',
        element: <Admin />,
        children: [
          {
            path: 'dashboard',
            element: <Dashboard />
          },
          {
            path: 'parametres',
            element: <Parameters />
          },
          {
            path: 'utilisateurs',
            element: <Users />,
            children: [
              {
                path: '',
                element: <Navigate to={'/admin/utilisateurs/tous-les-utilisateurs'} replace />
              },
              {
                path: 'notifications',
                element: <Notifications />
              },
              {
                path: 'tous-les-utilisateurs',
                element: <AllUsers />
              },
              {
                path: 'en-attente-de-validation',
                element: <WaitingForValidation />
              },
              {
                path: 'account-managers',
                element: <ListAccountManager />
              },
              {
                path: 'account-managers/:id',
                element: <AccountManagerDetails />
              },
              {
                path: ':id',
                element: <UserDetails />
              },
            ]
          },
          {
            path: 'alertes',
            element: <Alerts />,
            children: [
              {
                path: '',
                element: <Navigate to={'/admin/alertes/trading'} replace />
              },
              {
                path: ':alertType',
                element: <ListAlerts />
              },
              {
                path: 'modifier/:id',
                element: <AlertDetails />
              },
            ]
          },
          {
            path: 'articles',
            element: <Articles />,
            children: [
              {
                path: '',
                element: <Navigate to={'/admin/articles/campus'} replace />
              },
              {
                path: 'campus',
                element: <CampusArticles />
              },
              {
                path: 'campus/:id',
                element: <CampusArticleDetails />
              },
              {
                path: 'news',
                element: <NewsArticles />
              },
              {
                path: 'news/:id',
                element: <NewsArticleDetails />
              },
              {
                path: 'news-pagination',
                element: <NewsArticlesPagination />
              },
              {
                path: 'categories',
                element: <NewsArticleCategories />
              },
              {
                path: 'categories/:id',
                element: <NewsArticleCategoryDetails />
              }
            ]
          },
          {
            path: 'canal',
            element: <Canal />
          },
          {
            path: 'brokers',
            element: <Brokers />,
            children: [
              {
                path: '',
                element: <AllBrokers />
              },
              {
                path: ':id',
                element: <BrokerDetails />
              },
            ]
          },
          {
            path: 'messages',
            element: <Messages />,
            children: [
              {
                path: '',
                element: <AllMessages />
              },
              {
                path: ':id',
                element: <MessageDetails />
              }
            ]
          },
          {
            path: 'pays',
            element: <Countries />,
            children: [
              {
                path: '',
                element: <AllCountries />
              },
              {
                path: ':id',
                element: <CountryDetails />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/signin',
    element: <SignIn />
  }
])