import React from 'react'

interface Props {
  isVip: boolean;
  multi: boolean
  onChange: (isVip: boolean, multi: boolean) => void;
}

export const ModeRadio: React.FC<Props> = (props) => {
  const { isVip, multi, onChange } = props;
  const [mode, setMode] = React.useState<string>('');

  React.useEffect(() => {
    if (multi)  {
      setMode('multi');
    } else if (isVip) {
      setMode('vip');
    } else if (!isVip) {
      setMode('non-vip');
    }
  }, [isVip, multi]);

  return (
    <div>
      <fieldset>
        <div className="space-y-2 sm:flex sm:items-center sm:space-x-6 sm:space-y-0">
          <div className="flex items-center">
            <input
              type="radio"
              checked={mode === 'multi'}
              onChange={() => onChange(false, true)}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label className="ml-2 block text-sm font-medium leading-6 text-white">
              Tout le monde
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              checked={mode === 'vip'}
              onChange={() => onChange(true, false)}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label className="ml-2 block text-sm font-medium leading-6 text-white">
              Vip
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              checked={mode === 'non-vip'}
              onChange={() => onChange(false, false)}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            <label className="ml-2 block text-sm font-medium leading-6 text-white">
              Non Vip
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  )
};
