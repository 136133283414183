import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createCampusArticle, deleteCampusArticle, getCampusArticle, updateCampusArticle } from '../../../../services/articles';
import { useNavigate, useParams } from 'react-router-dom';
import { ImageUpload } from '../../../../components/ImageUpload';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { CustomDialog } from '../../../../components/CustomDialog';
import { CustomReactQuill } from '../../../../components/CustomReactQuill';
import { CampusArticleMultipleChoicesTest } from './CampusArticleMultipleChoicesTest';


export const CampusArticleDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = React.useState<any>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [deleteArticleModal, setDeleteArticleModal] = React.useState(false);
  const displaySnackbar = useSnackbar();
  const articleDetailsQuery = useQuery({
    queryKey: ['campusArticle', id],
    queryFn: () => getCampusArticle(id || ''),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    enabled: !!id && id !== 'new',
    onSuccess: (data) => {
      setArticle(data);
    }
  });

  React.useEffect(() => {
    if (id === 'new') {
      setArticle({
        id: 'new',
        image: null,
        title: '',
        video: '',
        content: '',
        multiple_choices_test: [],
        type: ''
      })
    }
  }, [id]);

  const updateArticleMutation = useMutation<any>({
    mutationKey: ['updateCampusArticle'],
    mutationFn: () => id !== 'new' ? updateCampusArticle(article.id, article) : createCampusArticle(article),
    onSuccess: (data) => {
      articleDetailsQuery.refetch();
      if (id === 'new') {
        navigate(`/admin/articles/campus/${data.id}`)
      }
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Article mis à jours',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  const deleteArticleMutation = useMutation<any>({
    mutationKey: ['deleteCampusArticle'],
    mutationFn: () => deleteCampusArticle(article.id),
    onSuccess: () => {
      queryClient.invalidateQueries('campusArticles');
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Article supprimé',
      })
    },
    onError: (error) => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Une erreur est survenue',
        severity: 'error',
      })
    }
  });

  if (!article) {
    return <span className='text-white'>Loading...</span>
  }

  return (
    <form className='mt-4'>
      <div className='flex justify-end sticky top-10 pt-2 z-50'>
        <div className='bg-gray-900 ml-auto flex space-x-2 items-center justify-end'>
          <button
            onClick={(e) => { e.preventDefault(); updateArticleMutation.mutate() }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500"
          >
            {id !== 'new' ? 'Save' : 'Créer article'}
          </button>
          {id !== 'new' && (<button
            type="button"
            onClick={() => setDeleteArticleModal(true)}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-red-500"
          >
            Supprimer
          </button>)}
        </div>
      </div>
      <CustomDialog
        title="Etes vous sur de supprimer cet article ?"
        open={deleteArticleModal}
        onClose={(reason) => {
          if (reason === 'confirm') {
            deleteArticleMutation.mutate();
            navigate('/admin/articles/campus');
          }
          setDeleteArticleModal(false);
        }}
      />
      <div className="space-y-8">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Géneral</h2>
          <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-8">
            <div className="col-span-full">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Titre de l'article
              </label>
              <div className="mt-2">
                <input
                  name="button_title"
                  id="button_title"
                  value={article?.title || ''}
                  onChange={(e) => setArticle({ ...article, title: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <CustomReactQuill value={article?.content || ''} onChange={(v) => setArticle({ ...article, content: v })} />
            </div>
            <div className='col-span-1'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                Image
              </label>
              <div className="mt-2">
                <ImageUpload value={article?.image} onChange={(v) => setArticle({ ...article, image_file: v })} />
              </div>
            </div>
            <div className='col-span-1'>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                URL Vidéo
              </label>
              <div className="mt-2">
                <input
                  name="video"
                  id="video"
                  value={article?.video || ''}
                  onChange={(e) => setArticle({ ...article, video: e.target.value })}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className='col-span-full'>
              <label htmlFor="qcm" className="block text-sm font-medium leading-6 text-white">
                Question à choix multiples
              </label>
              <CampusArticleMultipleChoicesTest questions={article.multiple_choices_test} onChange={(v) => setArticle((oldValue: any) => ({ ...oldValue, multiple_choices_test: v }))} />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};