import React from 'react'
import { NewsArticlesSelector } from '../../../components/NewsArticlesSelector';
import { useQuery } from 'react-query';
import { getNewsArticles } from '../../../services/articles';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  selectedIds: any;
  onChange: (value: any) => void;
}

export const ArticleIdSelector: React.FC<Props> = (props) => {
  const { selectedIds, onChange } = props;

  const selectedArticlesQuery = useQuery({
    queryKey: ['news-articles', selectedIds],
    queryFn: () => {
      return getNewsArticles({ id__in: selectedIds.join(',') })
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: selectedIds.length > 0,
  })

  return (
    <div className="grid grid-cols-12 gap-x-4">
      <div className="col-span-6">
        <NewsArticlesSelector onChange={(v) => onChange([...selectedIds, v.id])} selectedIds={selectedIds} />
      </div>
      <div className="col-span-6 flex flex-col space-y-2">
        {selectedArticlesQuery.data?.items.map((article: any) => (
          <div key={article.id} className="flex justify-between items-center text-indigo-500">
            <a href={`/admin/articles/news/${article.id}`} target='_blank' rel="noreferrer">[{article.id}] - {article.title} - {article.category.title} </a>
            <button onClick={() => onChange(selectedIds.filter((id: any) => id !== article.id))}>
              <XMarkIcon
                className="h-4 w-4 text-red-500"
                onClick={() => onChange(selectedIds.filter((id: any) => id !== article.id))}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
};
