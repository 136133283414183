import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

interface Props {
  defaultValue: any;
  onChange: (data: any) => void;
}

export const ChannelVocal: React.FC<Props> = (props) => {
  const { defaultValue, onChange } = props;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<any>(defaultValue);
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center space-x-2">
        <button
          type="button"
          onClick={() => {
            fileInputRef?.current?.click();
          }}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter un vocal
        </button>
        <input
          className="hidden"
          ref={fileInputRef}
          id="vocal"
          type="file"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setValue(URL.createObjectURL(file))
              onChange(file);
            }
          }}
        />
        {value && (
          <XMarkIcon className="h-5 w-5 text-white cursor-pointer" aria-hidden="true"
            onClick={(e) => {
              e.preventDefault();
              setValue(null);
              onChange('delete');
            }}
          />
        )}
      </div>

      {value && <AudioPlayer
        src={value}
        showJumpControls={false}
        layout='horizontal'
        onPlay={e => console.log("onPlay")}
      />}
    </div>
  )
};
