import React from 'react'
import { getUsers } from '../../../services/users';
import { useQuery, useQueryClient } from 'react-query';
import { Table } from '../../../components/Table';
import { Pagination } from '../../../components/Pagination';
import { WaitingForValidationFilters } from './WaitingForValidationFilters';
import { DeposistStatuses } from '../../../components/DepositStatusSelector';
import { ConfirmationDialog } from './ConfirmationDialog';

const headers = [
  { title: '#', key: 'id' },
  { title: 'Prénom', key: 'first_name' },
  { title: 'Nom', key: 'last_name' },
  { title: 'Email', key: 'email' },
  { title: 'Broker', key: 'profile.most_advanced_broker_account.broker.name' },
  { title: 'Broker Account ID', key: 'profile.most_advanced_broker_account.account_id' },
  { title: 'Statut', key: 'profile.most_advanced_broker_account.status', transform: (value: string) => DeposistStatuses.find((v) => v.value === value)?.label },
]

export const WaitingForValidation: React.FC = () => {
  const [filters, setFilters] = React.useState({
    page: 1,
    pageSize: 10,
  })
  const [selections, setSelections] = React.useState<any>([]);
  const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
  const queryClient = useQueryClient();
  const usersQuery = useQuery({
    queryKey: ['users', filters],
    queryFn: ({ signal }) => getUsers(filters, signal),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: true,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);

  if (usersQuery.isLoading) {
    return <span className='text-white'>Loading...</span>
  }


  return (
    <div>
      <Table
        checkbox
        checkedCompareFunc={(a) => selections.some((s: any) => s.id === a.id)}
        selections={selections}
        onToggleAll={(checked) => {
          if (checked) {
            setSelections(usersQuery.data.items)
          } else {
            setSelections([])
          }
        }}
        onSelect={(u: any, checked: boolean) => {
          if (checked) {
            setSelections([...selections, u])
          } else {
            setSelections(selections.filter((s: any) => s.id !== u.id))
          }
        }}
        headers={headers}
        data={usersQuery.data.items || []}
        loading={usersQuery.isLoading}
        filters={
          <WaitingForValidationFilters
            onFiltersChange={handleOnFiltersChange}
            bulkEditLabel={selections.length > 0 ? `Valider ${selections.length} utilisateurs` : ''}
            onBulkEdit={() => setSelectedUsers(selections)}
          />
        }
        onEdit={(item) => setSelectedUsers([item])}
      />
      <Pagination
        count={usersQuery.data.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={usersQuery.data.totalPages}
      />
      <ConfirmationDialog
        onClose={() => {
          setSelectedUsers([]);
          setSelections([]);
          queryClient.invalidateQueries('users');
        }}
        value={selectedUsers}
      />
    </div>
  )
};
