import React from 'react';
import { DepositStatusSelector } from '../../../components/DepositStatusSelector';

interface Props {
  onFiltersChange: (filters: any) => void;
  bulkEditLabel: string;
  onBulkEdit?: () => void;
}

export const WaitingForValidationFilters: React.FC<Props> = (props) => {
  const { onFiltersChange, bulkEditLabel, onBulkEdit } = props;
  const [filters, setFilters] = React.useState<any>({
    q: '',
    depositStatus: 'DEPOSIT_DONE',
  })

  React.useEffect(() => {
    onFiltersChange(filters)
  }, [onFiltersChange, filters])

  return (
    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-2">
        <label htmlFor="q" className="block text-sm font-medium leading-6 text-white">
          Recherche
        </label>
        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <input
            type="text"
            name="q"
            id="q"
            value={filters.q}
            onChange={(e) => setFilters({ ...filters, q: e.target.value })}
            className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-2">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Status du dépôt
        </label>
        <DepositStatusSelector
          value={filters.depositStatus}
          onChange={(v) => setFilters({ ...filters, depositStatus: v })}
        />
      </div>
      {bulkEditLabel && onBulkEdit && (
        <div className='col-span-2 flex items-end justify-end'>
          <button
            onClick={onBulkEdit}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-emerald-500"
          >
            {bulkEditLabel}
          </button>
        </div>
      )}
    </div>
  )
};