import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarServiceProvider } from './hooks/useSnackbar';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY || '');

function App() {
  const queryClient = new QueryClient()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <SnackbarServiceProvider>
              <Outlet />
            </SnackbarServiceProvider>
          </UserProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </LocalizationProvider>
  );
}

export default App;
