import React from 'react'
import { InstructionInputs } from './InstructionInputs';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { ImageUpload } from '../../../components/ImageUpload';

interface Props {
  value: any;
  onChange: (value: any) => void;
}


export const BrokerInstructions: React.FC<Props> = (props) => {
  const { onChange, value } = props;
  const instructions = value || [];

  return (
    <>
      <div className="space-y-4">
        {instructions.map((instruction: any, idx: number) => (
          <div className="border border-white/10 p-4" key={idx}>
            <div className='flex justify-between items-center'>
              <h2 className="text-base font-semibold leading-4 text-white">Instruction {idx + 1}</h2>
              <XMarkIcon className="h-5 w-5 text-white cursor-pointer" aria-hidden="true" onClick={() => {
                const newValue = [...instructions];
                newValue.splice(idx, 1);
                onChange(newValue);
              }} />
            </div>
            <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-4">
              <div className="col-span-1">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                  Titre
                </label>
                <div className="mt-2">
                  <input
                    id="instruction_title"
                    value={instruction.title || ''}
                    onChange={(e) => {
                      instructions[idx].title = e.target.value;
                      onChange(instructions);
                    }}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                  Sous titre
                </label>
                <div className="mt-2">
                  <input
                    name="instructions_subtitle"
                    id="instructions_subtitle"
                    value={instruction.subtitle || ''}
                    onChange={(e) => {
                      instructions[idx].subtitle = e.target.value;
                      onChange(instructions);
                    }}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                  Description
                </label>
                <div className="mt-2">
                  <input
                    name="instructions_description"
                    id="instructions_description"
                    value={instruction.description || ''}
                    onChange={(e) => {
                      instructions[idx].description = e.target.value;
                      onChange(instructions);
                    }}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-full">
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={instruction.type}
                    onChange={(enabled) => {
                      instructions[idx].type = enabled;
                      if (enabled) {
                        instructions[idx].url = null;
                      } else {
                        instructions[idx].image = null;
                        instructions[idx].image_file = null;
                      }
                      onChange(instructions);
                    }}
                    className={classNames(
                      instruction.type ? 'bg-indigo-600' : 'bg-gray-700',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        instruction.type ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3 text-sm">
                    <span className="font-medium text-white">{instruction.type ? 'Image' : 'URL vidéo'} explicative</span>
                  </Switch.Label>
                </Switch.Group>
                <div className='mt-2'>
                  {instruction.type ? (
                    <ImageUpload value={instruction?.image} onChange={(v) => {
                      instructions[idx].image_file = v;
                      onChange(instructions);
                    }} />
                  ) : (
                    <input
                      name="instructions_video_url"
                      id="instructions_video_url"
                      value={instruction.url || ''}
                      onChange={(e) => {
                        instructions[idx].url = e.target.value;
                        onChange(instructions);
                      }}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                  Avantages
                </label>
                <div className="mt-2">
                  <InstructionInputs
                    value={instruction.advantages}
                    canDeleteAll
                    onChange={(v) => {
                      instructions[idx].advantages = v;
                      onChange(instructions);
                    }} />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">
                  Désavantages
                </label>
                <div className="mt-2">
                  <InstructionInputs
                    value={instruction.disadvantages}
                    canDeleteAll
                    onChange={(v) => {
                      instructions[idx].disadvantages = v;
                      onChange(instructions);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='col-span-3 flex items-center justify-start mt-4'>
        <button
          type="button"
          onClick={() => onChange([...instructions, { title: '', subtitle: '', description: '', advantages: [], disadvantages: [], url: '', image: null }])}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Ajouter
        </button>
      </div>
    </>
  )
};
