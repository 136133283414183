import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  value: { symbol: string, name: string };
  onChange: (data: { symbol: string, name: string }) => void
  onDelete?: () => void
}

export const TradingViewTickerInput: React.FC<Props> = (props) => {
  const { onChange, value, onDelete } = props;
  return (
    <div className='grid grid-cols-12 gap-x-2 gap-y-2'>
      <div className='col-span-3 grid grid-cols-5'>
        <input
          name="symbol"
          placeholder="Symbole. CRYPTOCAP:BTC"
          value={value.symbol}
          onChange={(e) => {
            value.symbol = e.target.value;
            onChange(value);
          }}
          className="block col-span-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        />
      </div>
      <div className='col-span-4 grid grid-cols-5'>
        <input
          name="name"
          placeholder='Description. Bitcoin'
          value={value.name}
          onChange={(e) => {
            value.name = e.target.value;
            onChange(value);
          }}
          className="block col-span-4 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        />
        <div className='col-span-1 flex items-center justify-center'>
          {onDelete && (
            <XMarkIcon
              className="h-5 w-5 text-white ml-[-25px] cursor-pointer"
              aria-hidden="true"
              onClick={onDelete}
            />
          )}
        </div>
      </div>
    </div >
  )
};
