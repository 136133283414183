import React from 'react'
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAccountManagers } from '../../../../services/accountManagers';
import { ListAccountManagerFilters } from './ListAccountManagerFilters';
import { Pagination } from '../../../../components/Pagination';
import { Table } from '../../../../components/Table';

const headers = [
  { title: '#', key: 'id' },
  { title: 'Prénom', key: 'first_name' },
  { title: 'Nom', key: 'last_name' },
  { title: 'Lien', key: 'link' },
]

export const ListAccountManager: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState({
    page: 1,
    pageSize: 10,
  })
  const accountManagersQuery = useQuery({
    queryKey: ['accountManagers', filters],
    queryFn: ({ signal }) => getAccountManagers(filters, signal),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })

  const handleOnFiltersChange = React.useCallback((filters: any) => {
    setFilters((v) => ({ ...v, ...filters, page: 1 }))
  }, [setFilters]);



  return (
    <div>
      <Table
        headers={headers}
        data={accountManagersQuery.data?.items || []}
        loading={accountManagersQuery.isLoading}
        filters={
          <ListAccountManagerFilters onFiltersChange={handleOnFiltersChange} />
        }
        onEdit={(item: any) => navigate(`/admin/utilisateurs/account-managers/${item.id}`)}
      />
      <Pagination
        count={accountManagersQuery.data?.count}
        page={filters.page}
        onNext={() => setFilters((v) => ({ ...v, page: v.page + 1 }))}
        onPrevious={() => setFilters((v) => ({ ...v, page: v.page - 1 }))}
        pageSize={filters.pageSize}
        onPageChange={(page: number) => setFilters((v) => ({ ...v, page }))}
        totalPages={accountManagersQuery.data?.total_pages}
      />
    </div>
  )
};
