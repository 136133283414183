import React from 'react'


interface Props {
  onFiltersChange: (filters: any) => void;
}

export const CountryFilters: React.FC<Props> = (props) => {
  const { onFiltersChange } = props;
  const [filters, setFilters] = React.useState<any>({
    q: '',
    isActive: ''
  });

  React.useEffect(() => {
    onFiltersChange(filters)
  }, [onFiltersChange, filters])

  return (
    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-3">
        <label htmlFor="q" className="block text-sm font-medium leading-6 text-white">
          Recherche
        </label>
        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
          <input
            type="text"
            name="q"
            id="q"
            value={filters.q}
            onChange={(e) => setFilters({ ...filters, q: e.target.value })}
            className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-white focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="col-span-1">
        <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
          Visible
        </label>
        <select
          id="country"
          name="country"
          autoComplete="country-name"
          value={filters.isActive}
          onChange={(e) => setFilters({ ...filters, isActive: e.target.value })}
          className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
        >
          <option value={''}>-----</option>
          <option value={'true'}>Oui</option>
          <option value={'false'}>Non</option>
        </select>
      </div>
    </div>
  )
};
