import React from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { updateRank } from '../../../services/brokers';
import { useSnackbar } from '../../../hooks/useSnackbar';

interface Props {
  rawValue: any;
  value: any;
}

export const RankInputComponent: React.FC<Props> = (props) => {
  const { rawValue: broker, value } = props;
  const displaySnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const [rank, setRank] = React.useState(value || '');


  const updateRankMutation = useMutation({
    mutationFn: ({ rank }: { rank: any }) => updateRank(broker.id, Number.parseInt(rank)),
    onSuccess: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Classement mis à jours',
      });
      queryClient.invalidateQueries('brokers');
    },
    onError: () => {
      displaySnackbar({
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        text: 'Erreur lors de la mise à jour du classement',
        severity: 'error'
      });
    }
  })

  const inputDisabled = !broker.isActive;

  return inputDisabled ? null :
    (<input
      id="broker_rank"
      min={1}
      disabled={inputDisabled}
      value={rank}
      type="number"
      onChange={(e) => {
        setRank(e.target.value);
        updateRankMutation.mutate({ rank: e.target.value })
      }}
      className="block w-[86px] rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
    />
    )
};
